import { useCallback, useEffect, useState } from "react";
import { useAuthStore } from "@/stores/authStore";
import { useResetStores } from "@/stores/useResetStores.ts";
import { trpc } from "@/utils/trpc";
import { useSearchParams } from "react-router-dom";
import { z } from "zod";

const useAuthCheck = () => {
	const [shouldRedirect, setShouldRedirect] = useState(false);
	const [isError, setIsError] = useState(false);
	const [isLoading, setIsLoading] = useState(true);

	const resetAllStores = useResetStores();
	const [searchParams] = useSearchParams();
	const {
		lastBrokerPartnershipId,
		setPartnershipId,
		setLastBrokerPartnershipId,
		setIsFirstVisit,
		isFirstVisit,
	} = useAuthStore(
		({
			lastBrokerPartnershipId,
			setPartnershipId,
			setLastBrokerPartnershipId,
			setIsFirstVisit,
			isFirstVisit,
		}) => ({
			lastBrokerPartnershipId,
			setPartnershipId,
			setLastBrokerPartnershipId,
			setIsFirstVisit,
			isFirstVisit,
		}),
	);

	const { mutateAsync, isSuccess } = trpc.courier.getCourier.useMutation({
		retry: 0,
	});

	const handleFirstVisit = useCallback(
		async (encrypted: string | null) => {
			const { success: isValidEncrypted } = z
				.string()
				.min(150)
				.safeParse(encrypted);
			if (encrypted && isValidEncrypted) {
				try {
					await mutateAsync({ encryptedData: encrypted });

					setPartnershipId(encrypted);
					if (encrypted !== lastBrokerPartnershipId) {
						resetAllStores();
					}
					setIsFirstVisit(false);
					setLastBrokerPartnershipId(encrypted);
				} catch (e) {
					setIsError(true);
					setShouldRedirect(true);
				}
			} else {
				setIsError(true);
				setShouldRedirect(true);
			}
			setIsLoading(false);
		},
		[
			lastBrokerPartnershipId,
			mutateAsync,
			resetAllStores,
			setIsFirstVisit,
			setLastBrokerPartnershipId,
			setPartnershipId,
		],
	);

	useEffect(() => {
		const searchParams = new URLSearchParams(window.location.search);
		const encrypted = searchParams.get("encrypt");
		const { success: isValidEncrypted } = z
			.string()
			.min(150)
			.safeParse(encrypted);

		if (!isValidEncrypted) {
			setShouldRedirect(true);
			setIsError(true);
			return;
		}

		if (isFirstVisit) {
			handleFirstVisit(encrypted);
		} else {
			setIsLoading(false);
		}
	}, [searchParams]);

	useEffect(() => {
		if (shouldRedirect && isError) {
			window.location.href = import.meta.env.VITE_PUBLIC_CATALOG_URL;
		}
	}, [isSuccess, shouldRedirect, isError]);

	return isLoading || !isSuccess;
};

export default useAuthCheck;
