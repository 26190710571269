export const GuaranteeTypeNameFromPremium = (premiumKey: string): string => {
	const premiumNameMap: { [key: string]: string } = {
		// Basic Guarantees
		incendiePremium: "Incendie",
		catastrophePremium: "Catastrophes naturelles",
		tgnaPremium: "Tempêtes, Grêle, Neige, Accidents",
		assistancePremium: "Assistance",
		deffencePenalePremium: "Défense Pénale et Recours",

		// Property Damage Guarantees
		ddePremium: "Dégâts des eaux",
		volPremium: "Vol",
		bdgPremium: "Bris de glace",
		mifPremium: "Marchandises en installation frigorifique",
		trasnportPremium: "Matériel et Marchandise transportées",
		installationExterieurePremium: "Extensions Extérieures",
		sfrPremium: "Salons, Foires et Manifestations",
		effondrementPremium: "Effondrement",
		dommageElectriquePremium: "Dommages électriques",
		brisMachinePremium: "Bris de Machine",

		// Losses Guarantees
		perteIncendiePremium:
			"Perte d'exploitation incendie et évènements Climatiques",
		perteVolPremium: "Perte d'exploitation en cas de vol",
		perteValeurPremium: "Perte de valeur vénale du Fonds de Commerce",

		// Liability and Legal Defense
		rcePremium: "Responsabilité Civile Exploitation",
		rcpPremium: "Responsabilité Civile Professionnelle",
		pjPremium: "Protection Juridique",
	};

	return premiumNameMap[premiumKey] || premiumKey;
};
