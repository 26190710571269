import { useCallback } from "react";
import { formStore } from "@/pages/form/stores/formStore";
import { useAuthStore } from "@/stores/authStore";

export const useResetStores = () => {
	// IMPORT STORES here
	// const resetStore = usePaymentStore((state) => state.reset);
	const { reset } = formStore((state) => ({
		reset: state.reset,
	}));
	const { resetAuthStore } = useAuthStore((state) => ({
		resetAuthStore: state.reset,
	}));
	return useCallback(() => {
		reset();
		resetAuthStore();
	}, [reset]);
};
