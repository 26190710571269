import { z } from "zod";

export const courierResponseSchema = z
	.object({
		id: z.string(),
		n_siren: z.coerce.number(),
		email: z.string(),

		n_orias: z.coerce.number().optional(),
		raison_sociale: z.string().optional(),
		nom: z.string().optional(),
		prénom: z.string().optional(),
	})
	.transform(
		({ email, prénom, id, n_siren, n_orias, raison_sociale, nom }) => ({
			id,
			siren: n_siren,
			orias: n_orias,
			businessName: raison_sociale,
			firstName: prénom,
			lastName: nom,
			email,
		}),
	);
export type CourierResponseDto = z.infer<typeof courierResponseSchema>;

export const getCourierSchema = z.object({
	encryptedData: z.string(),
	shouldUpsert: z.boolean().optional().default(true),
});

export type GetCourierDto = z.infer<typeof getCourierSchema>;
