import AssistanceIcon from "@/assets/basicGuaranteesIcons/AssistanceIcon.svg";
import BatimentIcon from "@/assets/basicGuaranteesIcons/BatimentIcon.svg";
import CatastropheIcon from "@/assets/basicGuaranteesIcons/CatastropheIcon.svg";
import IncendieIcon from "@/assets/basicGuaranteesIcons/IncendieIcon.svg";
import MetresIcon from "@/assets/basicGuaranteesIcons/MetresIcon.svg";
import PriceInput from "@/components/inputs/PriceInput";
import { useCustomFormNavigate } from "@/hooks/customNavigate";
import { formStore } from "@/pages/form/stores/formStore";
import { trpc } from "@/utils";
import { FormProvider, useForm } from "react-hook-form";

export const BasicGuarantees = () => {
	const { establishments } = formStore((state) => ({
		establishments: state.establishments.insuredEstablishments,
	}));

	const navigate = useCustomFormNavigate();

	// We don't have a type here or zod schema it's for UI purposes
	const methods = useForm({
		defaultValues: establishments?.reduce(
			(acc, establishment) => ({
				...acc,
				[establishment.siret]: {
					loi: establishment.contractualIndemnityLimitPerSqm,
					batiment:
						establishment.contractualIndemnityLimitPerSqm *
						establishment.surfaceArea,
					incendie:
						establishment.operationalContentValue +
						establishment.stockValueExclVatPurchase,
				},
			}),
			{},
		),
	});

	const { data: documents } = trpc.documents.relevantDocuments.useQuery({
		isPJchosen: false,
	});

	const conditionsGeneralesDoc = documents?.find(
		(doc) => doc.label === "Conditions Générales",
	);
	const noticeAssistanceDoc = documents?.find(
		(doc) => doc.label === "Notice d'Assistance",
	);

	const onSubmit = () => {
		navigate("OptionalGuarantees");
	};

	const renderEstablishmentInputs = (
		valueType: "loi" | "batiment" | "incendie",
	) => {
		if (!establishments) return null;

		return establishments.map((establishment, index) => {
			let value = "";
			switch (valueType) {
				case "loi":
					value = `${establishment.contractualIndemnityLimitPerSqm}`;
					break;
				case "batiment":
					value = `${establishment.contractualIndemnityLimitPerSqm * establishment.surfaceArea}`;
					break;
				case "incendie":
					value = `${establishment.operationalContentValue + establishment.stockValueExclVatPurchase}`;
					break;
			}

			const establishmentLabel = `Établissement ${index + 1} | ${establishment.siret}`;

			return (
				<div key={establishment.siret} className="mt-4">
					<div className="">
						<PriceInput
							label={establishmentLabel}
							// @ts-expect-error is string
							name={`${establishment.siret}.${valueType}`}
							disabled
							control={methods.control}
							endorment="€"
							defaultValue={value}
						/>
					</div>
				</div>
			);
		});
	};

	return (
		<FormProvider {...methods}>
			<form id="form" onSubmit={methods.handleSubmit(onSubmit)}>
				<div className="flex items-center justify-between">
					<h1>Les garanties de base</h1>
				</div>
				<div className="my-4 space-y-6">
					<div>
						<div className="flex items-center gap-2">
							<img src={MetresIcon} alt="" />
							<p className="text-lg font-semibold text-[#333]">
								Limite Contractuelle d&apos;indemnité au mètres²
							</p>
						</div>
						<div className="grid grid-cols-1 gap-4 md:grid-cols-3">
							{renderEstablishmentInputs("loi")}
						</div>
					</div>

					<div>
						<div className="flex items-center gap-2">
							<img src={BatimentIcon} alt="" />
							<p className="text-lg font-semibold text-[#333]">
								Limite Contractuelle d&apos;indemnité pour le bâtiment,
								embellissement et agencement
							</p>
						</div>
						<div className="grid grid-cols-1 gap-4 md:grid-cols-3">
							{renderEstablishmentInputs("batiment")}
						</div>
					</div>

					<div>
						<div className="flex items-center gap-2">
							<img src={IncendieIcon} alt="" />
							<p className="text-lg font-semibold text-[#333]">
								Incendie - évènements Climatiques
							</p>
						</div>
						<div className="grid grid-cols-1 gap-4 md:grid-cols-3">
							{renderEstablishmentInputs("incendie")}
						</div>
					</div>

					<div>
						<div className="flex items-center gap-2">
							<img src={CatastropheIcon} alt="" />
							<p className="text-lg font-semibold text-[#333]">
								Catastrophe Naturelle et Technologique
							</p>
							{conditionsGeneralesDoc ? (
								<a
									href={conditionsGeneralesDoc.url}
									target="_blank"
									rel="noopener noreferrer"
									className="text-[#598EAD] underline"
								>
									Voir les Conditions Générales
								</a>
							) : null}
						</div>
					</div>

					<div>
						<div className="flex items-center gap-2">
							<img src={AssistanceIcon} alt="" />
							<p className="text-lg font-semibold text-[#333]">Assistance</p>
							{noticeAssistanceDoc ? (
								<a
									href={noticeAssistanceDoc.url}
									target="_blank"
									rel="noopener noreferrer"
									className="text-[#598EAD] underline"
								>
									Voir Notice d&apos;Information Assistance
								</a>
							) : null}
						</div>
					</div>
				</div>
			</form>
		</FormProvider>
	);
};
