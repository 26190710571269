import type { FieldValues, UseControllerProps } from "react-hook-form";
import {
	FormField,
	FormItem,
	FormLabel,
	FormMessage,
} from "@/components/ui/form";
import DatePicker from "react-date-picker";
import { useFormContext } from "react-hook-form";

import "react-calendar/dist/Calendar.css";
import "./styles.css";

import { cn } from "@/utils";

interface DateInputProps<T extends FieldValues> extends UseControllerProps<T> {
	label: string;
	placeholder?: string;
	required?: boolean;
	minDate?: Date;
	maxDate?: Date;
	onCustomChange?: (value: Date | undefined) => void;
	format?: string;
	disabled?: boolean;
	showOnlyDayMonth?: boolean;
	defaultActiveStartDate?: Date;
}
function isValidDate(dateStr: any): dateStr is Date {
	const date = new Date(dateStr);
	return !isNaN(date.getTime());
}

export function DateInput<T extends FieldValues>({
	label,
	name,
	required,
	minDate,
	maxDate,
	onCustomChange,
	format: formatProp = "dd/MM/yyyy",
	disabled,
	showOnlyDayMonth,
	defaultActiveStartDate,
}: DateInputProps<T>) {
	const { control } = useFormContext<T>();
	return (
		<FormField
			name={name}
			control={control}
			render={({ field }) => (
				<FormItem className="flex w-full flex-col">
					<FormLabel className="text-sm font-medium text-[#333333]">
						{label} {required && <span className="text-red-500"> *</span>}
					</FormLabel>
					<DatePicker
						className={cn(
							"w-full rounded-md bg-white p-2 font-normal text-black",
							disabled ? "text-gray-400" : "text-black",
						)}
						value={field.value}
						onChange={(v) => {
							const value = isValidDate(v) ? v : undefined;
							field.onChange(value);
							if (onCustomChange) {
								onCustomChange(value);
							}
						}}
						format={showOnlyDayMonth ? "dd/MM" : formatProp}
						minDate={minDate}
						maxDate={maxDate}
						calendarProps={{
							defaultActiveStartDate:
								field.value !== null && field.value !== undefined
									? new Date(field.value)
									: defaultActiveStartDate
										? defaultActiveStartDate
										: maxDate,
						}}
						disabled={disabled}
						calendarIcon={disabled ? null : undefined}
						clearIcon={disabled ? null : undefined}
					/>
					<FormMessage />
				</FormItem>
			)}
		/>
	);
}
