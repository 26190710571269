import { z } from "zod";

export const GetDocumentsSchema = z.object({
	isPJchosen: z.boolean(),
});

export type GetDocumentsDto = z.infer<typeof GetDocumentsSchema>;

export const DocumentResponseSchema = z.object({
	label: z.string(),
	url: z.string(),
});

export type DocumentResponse = z.infer<typeof DocumentResponseSchema>;
