import { useEffect } from "react";
import { useAuthStore } from "@/stores/authStore.tsx";
import { useLocation, useSearchParams } from "react-router-dom";
import { z } from "zod";

export const useAuthQueryParamsSetter = () => {
	const location = useLocation();
	const [searchParams] = useSearchParams();

	const { partnershipIdFromLocalstorage } = useAuthStore((state) => ({
		partnershipIdFromLocalstorage: state.partnershipId,
	}));

	useEffect(() => {
		const partnershipIdFromUrlPath = searchParams.get("encrypt");
		const { success: isValidEncrypted } = z
			.string()
			.min(150)
			.safeParse(partnershipIdFromUrlPath);

		if (partnershipIdFromLocalstorage && !isValidEncrypted) {
			const formattedPartnershipId = partnershipIdFromLocalstorage
				.split(" ")
				.join("+");

			window.history.replaceState(
				undefined,
				"",
				`?encrypt=${formattedPartnershipId}`,
			);
		}
	}, [location.pathname]);
};
