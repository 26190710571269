import type { FormRoutes } from "@/routes/formRoutes.tsx";
import { useCallback } from "react";
import { formRoutes } from "@/routes/formRoutes.tsx";
import { useNavigate } from "react-router-dom";

export type StateType = {
	[key: string]: string | number | boolean;
};
export const useCustomFormNavigate = () => {
	const navigate = useNavigate();
	return useCallback(
		(route: keyof typeof FormRoutes, state?: StateType) => {
			navigate(
				"/formulaire/" + formRoutes[route].path + window.location.search,
				{
					state: {
						isSafe: true,
						...state,
					},
				},
			);
			window.scroll(0, 0);
		},
		[navigate],
	);
};
