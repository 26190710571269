import type { ClassValue } from "clsx";
import { clsx } from "clsx";
import { twMerge } from "tailwind-merge";

import type { DetailedAddressDto } from "@repos/mrp-dtos";

export function cn(...inputs: ClassValue[]) {
	return twMerge(clsx(inputs));
}

export const addSpacingBetweenThousandsToNumber = (str: number) => {
	return str.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
};
export const addSpacingBetweenThousandsToString = (str: string) => {
	return str.replace(/\B(?=(\d{3})+(?!\d))/g, " ");
};
export const formatDetailedAddress = (address: DetailedAddressDto) => {
	return `${address.streetLabel}, ${address.postalCode} ${address.city}`;
};
export type WithNullableFields<T> = {
	[K in keyof T]: T[K] | undefined;
};

export const addSpacingBetweenThousandsToNumberWithDecimals = (
	str: number | null | undefined,
) => {
	if (str === null || str === undefined) return "";
	return addSpacingBetweenThousandsToString(str.toFixed(2));
};
