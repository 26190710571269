import type { SirenPageDto } from "@/pages/form/schemas";
import { useEffect } from "react";
import { AddressInput } from "@/components/inputs/AdressInput";
import { DateInput } from "@/components/inputs/DateInput/DateInput.tsx";
import { SelectInput } from "@/components/inputs/SelectInput.tsx";
import { TextInput } from "@/components/inputs/TextInput.tsx";
import { Button } from "@/components/ui/button.tsx";
import {
	DialogContent,
	DialogDescription,
	DialogFooter,
	DialogHeader,
	DialogTitle,
} from "@/components/ui/dialog.tsx";
import { trpc } from "@/utils";
import * as DialogPrimitive from "@radix-ui/react-dialog";
import { useFormContext, useWatch } from "react-hook-form";
import { IoClose } from "react-icons/io5";

interface ManualEntrepriseInputModalContentProps {
	sirenRequired: boolean;
	headerTitle: string;
}

export const ManualEntrepriseInputModalContent = ({
	sirenRequired,
	headerTitle,
}: ManualEntrepriseInputModalContentProps) => {
	const legalStatusOptionsQuery = trpc.config.listBusinessTypes.useQuery();
	const formattedLegalStatusOptions = legalStatusOptionsQuery.data?.map(
		(businessType) => ({
			value: businessType.code,
			label: businessType.label,
		}),
	);
	const { setValue, control, setError, clearErrors } = useFormContext();
	const creationDate = useWatch({
		control,
		name: "company.creationDate",
	});

	useEffect(() => {
		if (creationDate) {
			const year = new Date(creationDate).getFullYear();
			if (year < 1800) {
				setError("company.creationDate", {
					type: "manual",
					message: "La date de création doit être après 1800.",
				});
			} else {
				clearErrors("company.creationDate");
			}
		}
	}, [creationDate, setError, clearErrors]);

	return (
		<DialogContent className="mb-4 rounded-2xl border-none p-0 sm:max-w-[800px]">
			<DialogHeader className="flex flex-row items-center justify-between overflow-hidden rounded-lg rounded-b-none px-4 py-2">
				<div className="px-4">
					<DialogTitle className="text-primary">{headerTitle}</DialogTitle>
					<DialogDescription>
						Veuillez remplir ces champs pour progresser dans le parcours.
					</DialogDescription>
				</div>

				<DialogPrimitive.Close className="ring-offset-background transition-opacity hover:opacity-100 focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 disabled:pointer-events-none data-[state=open]:bg-accent data-[state=open]:text-muted-foreground">
					<IoClose className="size-10" />
				</DialogPrimitive.Close>
			</DialogHeader>

			<div className="grid px-8">
				<TextInput<SirenPageDto>
					label="Raison sociale"
					name="company.companyName"
					required
				/>
				<TextInput<SirenPageDto>
					label="Numéro SIREN"
					name="company.siren"
					required={sirenRequired}
					defaultValue={sirenRequired ? "999999999" : undefined}
					disabled={sirenRequired}
				/>
				<DateInput<SirenPageDto>
					label="Date de création"
					name="company.creationDate"
					required
				/>
				<TextInput<SirenPageDto>
					label="Code NAF"
					name="company.nafCode"
					required
				/>
				<SelectInput<SirenPageDto, { value: string; label: string }>
					label="Forme juridique"
					name="company.legalStatus.code"
					options={formattedLegalStatusOptions || []}
					required
					customOnChange={(value: string) => {
						const selectedOption = formattedLegalStatusOptions?.find(
							(option) => option.value === value,
						);
						if (selectedOption) {
							setValue("company.legalStatus", {
								code: selectedOption.value,
								label: selectedOption.label,
							});
						}
					}}
				/>
				<AddressInput<SirenPageDto>
					name="mainEstablishmentAddress.streetLabel"
					label="Adresse"
					cityProps={{ name: "mainEstablishmentAddress.city" }}
					postalCodeProps={{ name: "mainEstablishmentAddress.postalCode" }}
					countryProps={{ name: "mainEstablishmentAddress.country" }}
					required
				/>
			</div>
			<DialogFooter className="mt-4 px-8 pb-4">
				<Button type="submit" form="form">
					Confirmer
				</Button>
			</DialogFooter>
		</DialogContent>
	);
};
