import { useEffect, useState } from "react";
import { ContactModal } from "@/components/ContactModal.tsx";
import { Button } from "@/components/ui/button";
import { Checkbox } from "@/components/ui/checkbox";
import { Label } from "@/components/ui/label";
import { useCustomFormNavigate } from "@/hooks/customNavigate";
import { useClearForm } from "@/hooks/useClearForm";
import { formStore } from "@/pages/form/stores/formStore";
import { trpc } from "@/utils";
import { zodResolver } from "@hookform/resolvers/zod";
import { Controller, FormProvider, useForm } from "react-hook-form";

import type { DeclarationsDto } from "@repos/mrp-dtos";
import { declarationsConfirmationInputSchema } from "@repos/mrp-dtos";

export const DromComDeclarationsPage = () => {
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [pendingNavigation, setPendingNavigation] = useState(false);

	const {
		declarationsDromCom,
		setDeclarationsDromCom,
		insuredEstablishments,
		mainEstablishments,
	} = formStore((state) => ({
		declarationsDromCom: state.declarationsDromCom,
		setDeclarationsDromCom: state.setDeclarationsDromCom,
		insuredEstablishments: state.establishments.insuredEstablishments,
		mainEstablishments: state.establishments.mainEstablishment,
	}));

	const { clearForm } = useClearForm();
	const navigate = useCustomFormNavigate();

	const postalCodes = [
		...(mainEstablishments?.postalCode ? [mainEstablishments.postalCode] : []),
		...(insuredEstablishments?.map((est) => est.postalCode).filter(Boolean) ||
			[]),
	];

	const declarationsQuery = trpc.config.listDeclarations.useQuery({
		postalCodes,
	});

	const methods = useForm<DeclarationsDto>({
		resolver: zodResolver(declarationsConfirmationInputSchema),
		defaultValues: {
			declarationsArray: [],
			certify: false,
		},
	});

	// const formValues = useWatch({
	//     control: methods.control,
	// });

	// const allQuestionsAnswered = formValues?.declarationsArray?.every(
	//     (declaration) => declaration.answer !== undefined
	// );

	const handleModalConfirm = () => {
		setIsModalOpen(false);
		if (pendingNavigation) {
			clearForm();
			navigate("SirenInputPage");
			setPendingNavigation(false);
		}
	};

	const handleModalClose = () => {
		setIsModalOpen(false);
		setPendingNavigation(false);
	};

	const onSubmit = (data: DeclarationsDto) => {
		// Format the data to match the expected structure
		const formattedDeclarations = data.declarationsArray.map((declaration) => ({
			declarationId: declaration.declarationId,
			answer: declaration.answer,
		}));

		setDeclarationsDromCom({
			declarationsArray: formattedDeclarations,
			certify: data.certify,
		});

		if (formattedDeclarations.some((declaration) => declaration.answer)) {
			setIsModalOpen(true);
			setPendingNavigation(true);
			return;
		}

		navigate("OldDeclarationsPage");
	};

	useEffect(() => {
		if (declarationsQuery.data?.DromComLocation) {
			const formattedDeclarations = declarationsQuery.data.DromComLocation.map(
				(question) => ({
					declarationId: question.id,
					question: question.label,
					answer:
						declarationsDromCom.declarationsArray?.find(
							(d) => d.declarationId === question.id,
						)?.answer ?? false,
				}),
			);

			methods.reset({
				declarationsArray: formattedDeclarations,
				certify: false,
			});
		}
	}, [declarationsQuery.data, declarationsDromCom, methods]);

	if (!insuredEstablishments) {
		navigate("SirenInputPage");
		return null;
	}

	return (
		<>
			<FormProvider {...methods}>
				<form
					onSubmit={methods.handleSubmit(onSubmit)}
					className="flex w-full flex-col gap-12"
					id="form"
				>
					<div className="flex items-center justify-between">
						<h1>Les locaux sont-ils :</h1>
					</div>

					<div className="relative h-fit w-full gap-8">
						{methods
							.getValues("declarationsArray")
							.map((declaration, index) => (
								<div
									key={declaration.declarationId}
									className="mb-6 flex flex-row justify-between"
								>
									{/* @ts-expect-error is just for displaying */}
									<Label className="text-sm">{declaration.question}</Label>
									<Controller
										name={`declarationsArray.${index}.answer`}
										control={methods.control}
										render={({ field, fieldState }) => (
											<div className="flex flex-col">
												<div className="flex space-x-4">
													<Button
														type="button"
														onClick={() => field.onChange(true)}
														className={`w-20 ${
															field.value === true
																? "bg-primary hover:bg-primary"
																: "bg-gray-200 hover:bg-gray-300"
														}`}
													>
														Oui
													</Button>
													<Button
														type="button"
														onClick={() => field.onChange(false)}
														className={`w-20 ${
															field.value === false
																? "bg-primary hover:bg-primary"
																: "bg-gray-200 hover:bg-gray-300"
														}`}
													>
														Non
													</Button>
												</div>
												{fieldState.error && (
													<span className="mt-1 text-sm text-red-500">
														{fieldState.error.message}
													</span>
												)}
											</div>
										)}
									/>
								</div>
							))}

						<div className="mt-6 flex flex-col space-y-2">
							<div className="flex items-center space-x-2">
								<Controller
									name="certify"
									control={methods.control}
									render={({ field }) => (
										<>
											<Checkbox
												id="certify"
												checked={field.value}
												onCheckedChange={field.onChange}
												className="border-primary data-[state=checked]:bg-primary"
											/>
											<Label htmlFor="certify" className="text-sm font-medium">
												Je certifie l&apos;exactitude de mes réponses
											</Label>
										</>
									)}
								/>
							</div>
							{methods.formState.errors.certify && (
								<span className="text-sm text-red-500">
									{methods.formState.errors.certify.message}
								</span>
							)}
						</div>
					</div>
				</form>
			</FormProvider>
			<ContactModal
				isOpen={isModalOpen}
				onClose={handleModalClose}
				description="Nous ne sommes malheureusement pas en mesure de vous proposer une couverture adaptée aux besoins de votre client. Veuillez envoyer votre demande à notre adresse e-mail."
				onConfirm={handleModalConfirm}
			/>
		</>
	);
};
