// TODO: ADD DPR GUARANTEE
export const PROPERTY_DAMAGE_TYPES = [
	"INCENDIE",
	"TGNA",
	"ASSIST",
	"CATNAT",
	"DDE",
	"BDG",
	"VOL",
	"MEF",
	"BDM",
	"MMT",
	"DEL",
	"EEX",
	"SFM",
	"EFFONDREMENT",
];

export const PROPERTY_DAMAGE_AMOUNT_TYPES = [
	"INCENDIE",
	"TGNA",
	"ASSIST",
	"CATNAT",
	"DDE",
	"BDG",
	"VOL",
	"MEF",
	"BDM",
	"MMT",
	"DEL",
	"EEX",
	"SFM",
	"EFFONDREMENT",
	"PJ",
];

export const LIABILITY_TYPES = ["RCE", "RCPRO", "PJ", "DPR"];

export const LOSES_TYPES = ["PE_INCENDIE", "PVF", "PE_VOL"];

export const DAMAGE_TYPES = [
	"INCENDIE",
	"TGNA",
	"PJ",
	"ASSIST",
	"CATNAT",
	"DDE",
	"BDG",
	"VOL",
	"DPR",
	"MEF",
	"BDM",
	"MMT",
	"DEL",
	"EEX",
	"SFM",
	"EFFONDREMENT",
	"RCE",
	"RCPRO",
	"PE_INCENDIE",
	"PVF",
	"VANDALISME",
	"PE_VOL",
] as const;

export type GuaranteeOptionalType = (typeof DAMAGE_TYPES)[number];
