import { GuaranteeType, RiskClass } from "@prisma/client";
import { z } from "zod";

import {
	activityFamily,
	activityLabelInputSchema,
	sectorIdSchema,
	sectorLabelSchema,
	sectorSchema,
} from "../primitives";

export const activityDetailInfoSchema = z.object({
	id: z.string(),
	label: z.string(),
	nafCode: z.string(),
	isAccepted: z.boolean(),
	family: z.string().nullable(),
	sector: z.object({
		id: sectorIdSchema,
		label: sectorLabelSchema,
		iconUrl: z.string(),
		selectedIconUrl: z.string(),
	}),
	guaranteeRiskClass: z.array(
		z.object({
			guaranteeType: z.nativeEnum(GuaranteeType),
			riskClass: z.nativeEnum(RiskClass),
			Guarantee: z.object({
				isOptional: z.boolean(),
			}),
		}),
	),
});
export type ActivityDetailsInfoDto = z.infer<typeof activityDetailInfoSchema>;
export const getActivitiesDetailsResponseSchema = z.array(
	activityDetailInfoSchema,
);

export type GetActivitiesDetailsResponseDto = z.infer<
	typeof getActivitiesDetailsResponseSchema
>;

export const activityInfoSchema = z.object({
	naf: z.string(),
	family: activityFamily,
	sector: sectorSchema,
});
export type ActivityInfoDto = z.infer<typeof activityInfoSchema>;
export const getActivitiesResponseSchema = z.array(activityInfoSchema);

export type GetActivitiesResponseDto = z.infer<
	typeof getActivitiesResponseSchema
>;

export const getActivitiesInputSchema = z.object({
	label: activityLabelInputSchema.optional(),
	nafCode: z.string(),
});
