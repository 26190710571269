import { useCallback } from "react";
import { MAX_ESTABLISHMENTS } from "@/config/index.ts";
import { useCustomFormNavigate } from "@/hooks/customNavigate.ts";
import { MainEstablishmentDetailsCard } from "@/pages/form/1/components/3/MainEstablishmentDetailsCard.tsx";
import { EstablishmentMultiSelect } from "@/pages/form/1/components/EstablishmentMultiSelect.tsx";
import type {
	EstablishmentSelectPageDto} from "@/pages/form/schemas";
import {
	establishmentSelectPageSchema,
} from "@/pages/form/schemas";
import { formStore } from "@/pages/form/stores/formStore.ts";
import { zodResolver } from "@hookform/resolvers/zod";
import { FormProvider, useForm } from "react-hook-form";

export const EstablishmentSelectPage = () => {
	const navigate = useCustomFormNavigate();

	const {
		establishments,
		setInsuredEstablishmentsAddressAndSiret,
		siren,
		matchingEstablishmentsBySelectedEntreprise,
		resetChosenOptions,
	} = formStore((state) => ({
		establishments: state.establishments,
		setInsuredEstablishmentsAddressAndSiret:
			state.setInsuredEstablishmentsAddressAndSiret,
		siren: state.sirenPage.company?.siren,
		matchingEstablishmentsBySelectedEntreprise:
			state.matchingEstablishmentsBySelectedEntreprise,
		resetChosenOptions: state.resetChosenOptions,
	}));
	if (!siren) {
		navigate("SirenInputPage");
	}

	const methods = useForm<EstablishmentSelectPageDto>({
		defaultValues: {
			insuredEstablishments: establishments.insuredEstablishments,
		},
		resolver: zodResolver(establishmentSelectPageSchema),
	});
	const onSubmit = useCallback((data: EstablishmentSelectPageDto) => {
		//compare if the establishments Siret are the same
		if (
			establishments.insuredEstablishments &&
			JSON.stringify(
				data.insuredEstablishments.map((e) => ({ siret: e.siret })),
			) !==
				JSON.stringify(
					establishments.insuredEstablishments.map((e) => ({
						siret: e.siret,
					})),
				)
		) {
			resetChosenOptions();
		}
		setInsuredEstablishmentsAddressAndSiret(data.insuredEstablishments);

		navigate("EtablissementsDetailsPage");
		//Dont add establishments.insuredEstablishments to the dependencies array as we will compare with its initial value
	}, []);
	return (
		<FormProvider {...methods}>
			<form
				onSubmit={methods.handleSubmit(onSubmit)}
				id="form"
				className="flex w-full flex-col gap-4"
			>
				<div>
					<h1>Confirmer les détails de l&apos;entreprise </h1>
					<h3 className="text-[#595959]">
						Choisir jusqu&apos;à 3 établissements au total : un établissement
						principal et jusqu&apos;à 2 établissements secondaires
					</h3>
				</div>
				<MainEstablishmentDetailsCard />
				<EstablishmentMultiSelect
					required
					label="Sélectionnez le(s) établissement(s) à assurer "
					items={matchingEstablishmentsBySelectedEntreprise}
					placeholder="Recherchez et sélectionnez des établissements"
					maxSelections={MAX_ESTABLISHMENTS}
				/>
			</form>
		</FormProvider>
	);
};
