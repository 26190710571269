import type {
	ActivityPageInputDto,
	ActivitySectorDto,
	ClaimPageDto,
	EstablishmentDetailsPageDto,
	EstablishmentDto,
	EstablishmentSelectInputDTO,
	NumberOfClaimsPageDto,
	QuoteDetailsDto,
	SirenPageDto,
} from "@/pages/form/schemas";
import type { FormRoutes } from "@/routes/formRoutes.tsx";
import type { WithNullableFields } from "@/utils";
import { MAX_ESTABLISHMENTS } from "@/config/index.ts";
import { formRoutes } from "@/routes/formRoutes.tsx";
import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";

import type {
	DeclarationsDto,
	DetailedAddressDto,
	MainActivityDto,
	MatchingEtablissementDto,
	OldDeclarationsDto,
} from "@repos/mrp-dtos";

export type ChosenOptionsDto = {
	chosenOptions: Array<{
		activityId: string;
		establishmentId: string;
		chosenOptions: {
			//TODO: Urgent: change the type of guaranteeType to GuaranteeType and fix wrong useages
			guaranteeType: string;
			guaranteeValue: number;
		}[];
	}>;
};

export type FormStoreState = {
	sirenPage: WithNullableFields<SirenPageDto>;
	matchingEstablishmentsBySelectedEntreprise: MatchingEtablissementDto[];
	numberOfClaimsPage: WithNullableFields<NumberOfClaimsPageDto>;
	activityPage: WithNullableFields<ActivityPageInputDto>;
	establishments: WithNullableFields<EstablishmentDetailsPageDto>;
	claimPage: WithNullableFields<ClaimPageDto>;
	declarations: WithNullableFields<DeclarationsDto>;
	hasDromComQuestions: boolean;
	declarationsDromCom: WithNullableFields<DeclarationsDto>;
	oldDeclarations: WithNullableFields<OldDeclarationsDto>;
	chosenOptions: WithNullableFields<ChosenOptionsDto>;
	quote: WithNullableFields<QuoteDetailsDto>;
	currentStep: number;
	currentSubStep: number;
	currentStepKey: keyof typeof FormRoutes;
	isFirstStep: boolean;
	subscriptionId: string | undefined;
};
export const emptyEstablishment: WithNullableFields<EstablishmentDto> = {
	order: 1,
	streetLabel: undefined,
	postalCode: undefined,
	city: undefined,
	country: undefined,
	occupationStatus: undefined,
	numberOfEmployees: undefined,
	turnover: undefined,
	surfaceArea: undefined,
	siret: undefined,
	operationalContentValue: undefined,
	stockValueExclVatPurchase: undefined,
	contractualIndemnityLimitPerSqm: undefined,
	//@eslint-disable-next-line
	relatedActivityId: [],
	hightestActivityId: undefined,
};
const mainEstablishment: WithNullableFields<EstablishmentDto> = {
	order: undefined,
	streetLabel: undefined,
	postalCode: undefined,
	city: undefined,
	country: undefined,
	occupationStatus: undefined,
	numberOfEmployees: undefined,
	turnover: undefined,
	surfaceArea: undefined,
	siret: undefined,
	operationalContentValue: undefined,
	stockValueExclVatPurchase: undefined,
	contractualIndemnityLimitPerSqm: undefined,
	//@eslint-disable-next-line
	relatedActivityId: [],
	hightestActivityId: undefined,
};
export const initialQuote: WithNullableFields<QuoteDetailsDto> = {
	desiredEffectiveDate: undefined,
	splittingOption: undefined,
	paymentMethod: undefined,
	contractDueDate: undefined,
	valeurFranchise: undefined,
	fileFees: undefined,
	commissionCourtier: undefined,
	civiliteDirigeant: undefined,
	nomDirigeant: undefined,
	prenomDirigeant: undefined,
	emailDirigeant: undefined,
	dateNaissanceDirigeant: undefined,
	telPortableDirigeant: undefined,
	telFixeDirigeant: undefined,
	pricingData: undefined,
};

export const initialState: FormStoreState = {
	sirenPage: {
		company: undefined,
		companyUnderCreation: false,
	},
	matchingEstablishmentsBySelectedEntreprise: [],
	numberOfClaimsPage: {
		numberOfClaims: undefined,
	},
	activityPage: {
		principalActivity: undefined,
		sector: undefined,
		secondaryActivities: [],
	},
	establishments: {
		//@eslint-disable-next-line
		//@ts-expect-error update this later
		mainEstablishment: mainEstablishment,
		insuredEstablishments: [],
		totalInsuredEstablishmentsTurnover: 0,
	},
	claimPage: {
		claimsArray: [],
	},
	declarations: {
		declarationsArray: [],
		certify: false,
	},
	hasDromComQuestions: false,
	declarationsDromCom: {
		declarationsArray: [],
		certify: false,
	},
	oldDeclarations: {
		declarationsArray: [],
	},
	chosenOptions: {
		chosenOptions: [],
	},
	quote: initialQuote,
	currentStep: 1,
	currentSubStep: 1,
	currentStepKey: "SirenInputPage" as keyof typeof FormRoutes,
	isFirstStep: true,
	subscriptionId: undefined,
};

export const formStore = create<FormStoreState & FormActions>()(
	persist(
		devtools((set, get) => ({
			...initialState,
			setSirenPage: (sirenPage) => set((state) => ({ ...state, sirenPage })),
			setMainEstablishmentDetailedAddress: (address) =>
				// @ts-expect-error the reason why this is a ts error is because we defined establishments as WithNullableFields<EstablishmentPageDto> so that the values can be initialized as undefined
				set((state) => ({
					...state,
					establishments: {
						...state.establishments,
						mainEstablishment: {
							...state.establishments.mainEstablishment,
							postalCode: address?.postalCode,
							city: address?.city,
							country: address?.country,
							streetLabel: address?.streetLabel,
						},
					},
				})),
			setMainEstablishmentSiret: (siret) =>
				// @ts-expect-error the reason why this is a ts error is because we defined establishments as WithNullableFields<EstablishmentPageDto> so that the values can be initialized as undefined
				set((state) => ({
					...state,
					establishments: {
						...state.establishments,
						mainEstablishment: {
							...state.establishments.mainEstablishment,
							siret,
						},
					},
				})),
			setMatchingEstablishmentsBySelectedEntreprise: (matchingEstablishments) =>
				set((state) => ({
					...state,
					matchingEstablishmentsBySelectedEntreprise: matchingEstablishments,
				})),
			appendInsuredEstablishments: (data) => {
				//check if the establishment already exists
				if (
					get().establishments.insuredEstablishments?.some(
						(establishment) => establishment.siret === data.siret,
					)
				) {
					return;
				}
				if (
					get().establishments.insuredEstablishments &&
					MAX_ESTABLISHMENTS >
						get().establishments.insuredEstablishments!.length
				) {
					set((state) => ({
						...state,
						establishments: {
							...state.establishments,
							insuredEstablishments:
								// @ts-expect-error the reason why this is a ts error is because we defined establishments as WithNullableFields<EstablishmentPageDto> so that the values can be initialized as undefined
								state.establishments.insuredEstablishments!.concat({
									...emptyEstablishment,
									...data,
								}),
						},
					}));
				}
			},
			removeInsuredEstablishments: (siret) =>
				set((state) => ({
					...state,
					establishments: {
						...state.establishments,
						insuredEstablishments:
							state.establishments.insuredEstablishments!.filter(
								(establishment) => establishment.siret !== siret,
							),
					},
				})),
			setInsuredEstablishmentsAddressAndSiret: (data) =>
				set((state) => ({
					...state,
					establishments: {
						...state.establishments,
						insuredEstablishments:
							state.establishments.insuredEstablishments!.map(
								(establishment) => {
									const establishmentData = data.find(
										(data) => data.siret === establishment.siret,
									);
									return {
										...establishment,
										...establishmentData,
									};
								},
							),
					},
				})),
			setInsuredEstablishmentsDataWithoutSiretAndAddress: (data) =>
				set((state) => ({
					...state,
					establishments: {
						...state.establishments,
						insuredEstablishments:
							state.establishments.insuredEstablishments!.map(
								(establishment) => {
									const establishmentData = data.find(
										(data) => data.siret === establishment.siret,
									);
									return {
										...establishment,
										...establishmentData,
									};
								},
							),
					},
				})),
			setMainActivity: (mainActivity) =>
				set((state) => ({
					...state,
					activityPage: {
						...state.activityPage,
						principalActivity: mainActivity,
					},
				})),
			setMainSectorActivity: (mainSectorActivity) =>
				set((state) => ({
					...state,
					activityPage: {
						...state.activityPage,
						sector: mainSectorActivity,
					},
				})),
			setNumberOfClaimsPage: (numberOfClaimsPage) =>
				set((state) => ({ ...state, numberOfClaimsPage })),
			setActivityPage: (activityPage) =>
				set((state) => ({ ...state, activityPage })),
			setDeclarations: (declarations) =>
				set((state) => ({ ...state, declarations })),
			setHasDromComQuestions: (hasDromComQuestions) =>
				set((state) => ({ ...state, hasDromComQuestions })),
			setDeclarationsDromCom: (declarationsDromCom) =>
				set((state) => ({ ...state, declarationsDromCom })),
			setOldDeclarations: (oldDeclarations) =>
				set((state) => ({ ...state, oldDeclarations })),
			setChosenOptions: (chosenOptions) =>
				set((state) => ({ ...state, chosenOptions })),
			setQuote: (quote) => set((state) => ({ ...state, quote })),
			resetPricingData: () =>
				set((state) => ({
					...state,
					quote: {
						...state.quote,
						pricingData: undefined,
					},
				})),

			setInsuredEstablishments: (insuredEstablishments) =>
				set((state) => ({
					...state,
					establishments: {
						...state.establishments,
						...insuredEstablishments,
					},
				})),

			setClaimPage: (claimPage) => set((state) => ({ ...state, claimPage })),
			setCurrentStep: (currentStep) =>
				set((state) => ({ ...state, currentStep })),
			setCurrentSubStep: (currentSubStep) =>
				set((state) => ({ ...state, currentSubStep })),
			setCurrentStepKey: (currentStepKey) =>
				set((state) => {
					const isFirstStep =
						formRoutes[currentStepKey].step === 1 &&
						formRoutes[currentStepKey].subStep === 1;
					return { ...state, currentStepKey, isFirstStep };
				}),
			setSubscriptionId: (subscriptionId) =>
				set((state) => ({ ...state, subscriptionId })),
			resetChosenOptions: () =>
				set((state) => ({
					...state,
					chosenOptions: {
						chosenOptions: [],
					},
				})),
			resetChosenOptionsGuaranteeValues: () =>
				set((state) => ({
					...state,
					chosenOptions: {
						chosenOptions: state.chosenOptions.chosenOptions!.map(
							(chosenOption) => ({
								...chosenOption,
								chosenOptions: chosenOption.chosenOptions.map(
									(chosenOption) => ({
										...chosenOption,
										guaranteeValue: 0,
									}),
								),
							}),
						),
					},
				})),
			reset: () => set(initialState),
		})),

		{
			name: "form-store",
		},
	),
);
type FormActions = {
	setSirenPage: (sirenPage: SirenPageDto) => void;
	setMainEstablishmentDetailedAddress: (address?: DetailedAddressDto) => void;
	setMainEstablishmentSiret: (siret: string) => void;
	setMatchingEstablishmentsBySelectedEntreprise: (
		matchingEstablishments: MatchingEtablissementDto[],
	) => void;
	appendInsuredEstablishments: (data: EstablishmentSelectInputDTO) => void;
	removeInsuredEstablishments: (siret: string) => void;
	setInsuredEstablishmentsAddressAndSiret: (
		data: EstablishmentSelectInputDTO[],
	) => void;
	setInsuredEstablishmentsDataWithoutSiretAndAddress: (
		data: EstablishmentSelectInputDTO[],
	) => void;
	setMainActivity: (mainActivity?: MainActivityDto) => void;
	setMainSectorActivity: (mainSectorActivity?: ActivitySectorDto) => void;
	setNumberOfClaimsPage: (numberOfClaimsPage: NumberOfClaimsPageDto) => void;
	setActivityPage: (activityPage: ActivityPageInputDto) => void;
	setClaimPage: (claimPage: ClaimPageDto) => void;
	setDeclarations: (declarations: DeclarationsDto) => void;
	setHasDromComQuestions: (hasDromComQuestions: boolean) => void;
	setDeclarationsDromCom: (declarations: DeclarationsDto) => void;
	setOldDeclarations: (oldDeclarations: OldDeclarationsDto) => void;
	setChosenOptions: (chosenOptions: ChosenOptionsDto) => void;
	setQuote: (quote: QuoteDetailsDto) => void;
	resetPricingData: () => void;
	setCurrentStep: (currentStep: number) => void;
	setCurrentSubStep: (currentSubStep: number) => void;
	setCurrentStepKey: (currentStepKey: keyof typeof FormRoutes) => void;
	setSubscriptionId: (subscriptionId: string) => void;
	resetChosenOptions: () => void;
	resetChosenOptionsGuaranteeValues: () => void;

	reset: () => void;
};
