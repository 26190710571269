import { StyleSheet } from "@react-pdf/renderer";

const textStyles = StyleSheet.create({
	regular: {
		fontFamily: "Roboto",
		fontWeight: 400,
	},
	medium: {
		fontFamily: "Roboto",
		fontWeight: 500,
	},
	bold: {
		fontWeight: 600,
		fontFamily: "Roboto",
	},
	underline: {
		textDecoration: "underline",
	},
});

const style = StyleSheet.create({
	page: { width: "100%", height: "100%", paddingBottom: 50, paddingTop: 100 },
	container: {
		fontFamily: "Roboto",
		fontWeight: 400,
		display: "flex",
		flexDirection: "column",
		padding: 30,
	},
	pl_20: { paddingLeft: 20 },
	pr_20: { paddingRight: 20 },
	pt_20: { paddingTop: 20 },
	pb_20: { paddingBottom: 20 },
	m_20: { margin: 20 },
	ml_20: { marginLeft: 20 },
	mr_20: { marginRight: 20 },
	mt_20: { marginTop: 20 },
	mb_20: { marginBottom: 20 },
	mb_150: { marginBottom: 150 },
	mt_10: { marginTop: 10 },
	mb_10: { marginBottom: 10 },
	mt_5: { marginTop: 5 },
	justifyEnd: { alignSelf: "flex-end" },
	halfWidth: { maxWidth: "50%" },
	sectionHeader: {
		fontFamily: "Roboto",
		fontWeight: 500,
		color: "#B01016",
		paddingBottom: 2,
		marginBottom: 10,
		borderBottomWidth: 2,
		borderBottomColor: "#B01016",
		fontSize: 14,
		textTransform: "uppercase",
	},
	subSectionText: {
		fontSize: 10,
		fontFamily: "Roboto",
		fontWeight: 600,
	},
	bodyText: {
		fontSize: 10,
		marginVertical: 2,
	},
	max2Lines: {
		maxLines: 2,
		textOverflow: "ellipsis",
	},
	max1Line: {
		maxLines: 1,
		textOverflow: "ellipsis",
	},
	bgGray: {
		backgroundColor: "#F5F5F5",
	},
	signatureBox: {
		height: 50,
		marginTop: 20,
	},
	column: {
		flex: 1,
		borderRight: "1px solid black",
		padding: 10,
		textAlign: "center",
	},
	signatureContainer: {
		display: "flex",
		flexDirection: "row",
		border: "1px solid black",
	},
	signatureImage: {},
	tableContainer: {
		margin: "auto",
		width: "70%",
	},
});

export const styles = { ...style, ...textStyles };
