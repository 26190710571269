import FinancialInsurances from "@/components/quotePdf/FinancialInsurances.tsx";
import { HeaderFooter } from "@/components/quotePdf/HeaderFooter.tsx";
import LiabilityInsurances from "@/components/quotePdf/LiabilityInsurances.tsx";
import PropertyInsurances from "@/components/quotePdf/PropertyInsurances.tsx";
import { styles } from "@/components/quotePdf/styles.ts";
import { Page, Text, View } from "@react-pdf/renderer";

import type { SubscriptionDetailsForQuoteDto } from "@repos/mrp-dtos";

export const GuaranteesAndOptionsPdfPage = ({
	...props
}: SubscriptionDetailsForQuoteDto) => {
	return (
		<Page size="A4" style={styles.page}>
			<HeaderFooter />
			<View style={styles.container}>
				<PropertyInsurances {...props} />
				<View
					style={{
						display: "flex",
						flexDirection: "column",
						gap: 10,
						marginBottom: 30,
					}}
				>
					<Text style={[styles.bodyText, styles.medium]}>
						(1) En cas de manifestations, émeutes, mouvements populaires ou
						actes de sabotage.
					</Text>
				</View>
				<FinancialInsurances {...props} />
				{/* <View break /> */}
				<LiabilityInsurances {...props} />
				<View style={{ display: "flex", flexDirection: "column", gap: 10 }}>
					<Text style={[styles.bodyText, styles.medium]}>
						Ces montants s’entendent hors taxes et comprennent les frais de
						secrétariat, de déplacements et de photocopie.
					</Text>
					<Text style={[styles.bodyText, styles.medium]}>
						Ils sont majorés de la TVA en vigueur au jour de la facturation si
						vous n’êtes pas assujetti à la TVA. Par affaire, on entend la
						saisine d’une juridiction par des parties qui s’opposent sur des
						mêmes faits, afin que leurs positions soient tranchées, et ce quels
						que soient les développements procéduraux mis en œuvre devant cette
						juridiction.
					</Text>
					<Text style={[styles.bodyText, styles.medium]}>*indice FFB</Text>
				</View>
			</View>
		</Page>
	);
};
