import type { formRoutes } from "@/routes/formRoutes.tsx";
import type { Dispatch, SetStateAction } from "react";
import { createContext, useState } from "react";
import logo from "@/assets/logo.png";
import { Stepper } from "@/components/Stepper.tsx";
import { Button } from "@/components/ui/button.tsx";
import { Spinner } from "@/components/ui/Spinner";
import { Toaster } from "@/components/ui/toaster.tsx";
import { PROPERTY_DAMAGE_AMOUNT_TYPES } from "@/constants";
import { useCustomFormNavigate } from "@/hooks/customNavigate.ts";
import { formStore } from "@/pages/form/stores/formStore.ts";
import { FormRoutes } from "@/routes/formRoutes.tsx";
import { cn } from "@/utils";
import { Outlet } from "react-router-dom";

export const LayoutButtonsContext = createContext<{
	setNextButtonDisabled: Dispatch<SetStateAction<boolean>>;
	setNextButtonLoading: Dispatch<SetStateAction<boolean>>;
	nextButtonDisabled: boolean;
	nextButtonLoading: boolean;
}>({
	setNextButtonDisabled: () => {},
	setNextButtonLoading: () => {},
	nextButtonDisabled: false,
	nextButtonLoading: false,
});

const Layout = () => {
	const {
		isFirstStep,
		reset,
		currentStepKey,
		numberOfClaims,
		hasDromComQuestions,
		chosenOptions,
	} = formStore((state) => ({
		isFirstStep: state.isFirstStep,
		reset: state.reset,
		currentStepKey: state.currentStepKey,
		hasDromComQuestions: state.hasDromComQuestions,
		numberOfClaims: state.numberOfClaimsPage.numberOfClaims,
		chosenOptions: state.chosenOptions,
	}));
	const [nextButtonDisabled, setNextButtonDisabled] = useState(false);
	const [nextButtonLoading, setNextButtonLoading] = useState(false);

	const CustomFormNavigate = useCustomFormNavigate();
	const restart = () => {
		localStorage.clear();
		reset();
		CustomFormNavigate("SirenInputPage");
	};
	const handlePreviousStepKey = (
		currentStepKey: keyof typeof formRoutes,
	): keyof typeof formRoutes => {
		switch (currentStepKey) {
			case FormRoutes.SirenInputPage:
				return FormRoutes.SirenInputPage;
			case FormRoutes.NumberOfSinistresPage:
				return FormRoutes.SirenInputPage;
			case FormRoutes.ActivityPage:
				return FormRoutes.NumberOfSinistresPage;
			case FormRoutes.EstablishmentsSelectPage:
				return FormRoutes.ActivityPage;
			case FormRoutes.EtablissementsDetailsPage:
				return FormRoutes.EstablishmentsSelectPage;
			case FormRoutes.ClaimsPage:
				return FormRoutes.EtablissementsDetailsPage;
			case FormRoutes.DromComDeclarationsPage:
				return FormRoutes.EtablissementsDetailsPage;
			case FormRoutes.OldDeclarationsPage:
				if (hasDromComQuestions) {
					return FormRoutes.DromComDeclarationsPage;
				} else if (numberOfClaims !== "ZERO") {
					return FormRoutes.ClaimsPage;
				} else {
					return FormRoutes.EtablissementsDetailsPage;
				}
			case FormRoutes.DeclarationsPage:
				return FormRoutes.OldDeclarationsPage;
			case FormRoutes.BasicGuarantees:
				return FormRoutes.DeclarationsPage;
			case FormRoutes.OptionalGuarantees:
				return FormRoutes.BasicGuarantees;
			case FormRoutes.GuaranteeAmountsPage:
				return FormRoutes.OptionalGuarantees;
			case FormRoutes.QuotesPage:
				if (
					chosenOptions.chosenOptions?.some((activity) =>
						activity.chosenOptions.some((option) =>
							PROPERTY_DAMAGE_AMOUNT_TYPES.includes(option.guaranteeType),
						),
					)
				) {
					return FormRoutes.GuaranteeAmountsPage;
				}
				return FormRoutes.OptionalGuarantees;
			case FormRoutes.OfferSummaryPage:
				return FormRoutes.QuotesPage;
			case FormRoutes.SendingQuotesPage:
				return FormRoutes.OfferSummaryPage;
			case FormRoutes.SendingQuotesEndPage:
				return FormRoutes.SendingQuotesPage;

			default:
				return FormRoutes.SirenInputPage;
		}
	};
	return (
		<LayoutButtonsContext.Provider
			value={{
				nextButtonDisabled: nextButtonDisabled,
				nextButtonLoading: nextButtonLoading,
				setNextButtonDisabled: setNextButtonDisabled,
				setNextButtonLoading: setNextButtonLoading,
			}}
		>
			<div className="min-h-screen bg-[#F5F5F5]">
				<div className="flex h-[10%] w-full items-center bg-white px-10 py-5 shadow-md">
					<img src={logo} alt="Logo" className="w-42" />
					<Button variant="outline" onClick={restart} className="ml-auto">
						Refaire le parcours
					</Button>
				</div>
				<div className="flex min-h-[90%] w-full gap-8 px-10 py-5">
					<Stepper />
					<main className="flex h-full w-full flex-col justify-between self-start rounded-2xl bg-white p-10">
						<Outlet />
						<div
							className={cn(
								"flex w-full justify-center py-5",
								isFirstStep ? "justify-end" : "justify-between",
							)}
						>
							{!isFirstStep && (
								<Button
									className="border-2 px-10 py-5"
									variant="outline"
									onClick={() => {
										CustomFormNavigate(
											handlePreviousStepKey(
												currentStepKey as keyof typeof formRoutes,
											),
										);
									}}
								>
									Précédent
								</Button>
							)}
							<Button
								type="submit"
								form="form"
								disabled={nextButtonDisabled}
								className={cn("px-10 py-5 text-white")}
							>
								{nextButtonLoading && (
									<Spinner className="mr-2 text-white" size="small" />
								)}
								Confirmer
							</Button>
						</div>
					</main>
				</div>
				<Toaster />
			</div>
		</LayoutButtonsContext.Provider>
	);
};

export default Layout;
