import { z } from "zod";

export const sendQuoteToPyaloadSchema = z.object({
	data: z.object({
		courier: z.object({
			smalltoxId: z.string(),
			email: z.string(),
			siren: z.number(),
			orias: z.number().nullable(),
			businessName: z.string().nullable(),
			firstName: z.string().nullable(),
			lastName: z.string().nullable(),
		}),
		insuredCompanies: z.array(
			z.object({
				companyName: z.string(),
				email: z.string(),
				phoneNumber: z.string(),
				siren: z.number(),
				siret: z.number(),
			}),
		),
		insuredUsers: z.array(
			z.object({
				Mr: z.boolean(),
				Mrs: z.boolean(),
				firstName: z.string().min(1, "First name is required"),
				lastName: z.string().min(1, "Last name is required"),
				emailAddress: z.string().email("Invalid email address format"),
				phoneNumber: z.string(),
			}),
		),
		quote: z.object({
			reference: z.string(), // Ensures it starts with "MRP-" followed by a timestamp
			effectiveDate: z.string().or(z.date()), // Accepts a string or Date for flexibility
			status: z.string(), // Restricts status to specific allowed values
			// status: z.enum(["Issued", "Pending", "Cancelled"]), // Restricts status to specific allowed values // TODO: Uncomment this line to see the error
		}),
		submission: z.record(z.any()),
	}),
});

export type SendQuoteToPyaload = z.infer<typeof sendQuoteToPyaloadSchema>;
