import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";

const initialState: AuthStoreState = {
	partnershipId: null,
	lastBrokerPartnershipId: null,
	isFirstVisit: true,
};

export const useAuthStore = create<AuthStoreState & AuthStoreActions>()(
	persist(
		devtools((set) => ({
			...initialState,
			setPartnershipId: (partnershipId) => set({ partnershipId }),
			setIsFirstVisit: (isFirstVisit) => set({ isFirstVisit }),

			setLastBrokerPartnershipId: (lastBrokerPartnershipId) =>
				set({ lastBrokerPartnershipId }),
			reset: () => set(initialState),
		})),
		{
			name: "auth-store",
			partialize: (state) =>
				Object.fromEntries(
					Object.entries(state).filter(
						([key]) => !["isFirstVisit", "brokerApiKey"].includes(key),
					),
				),
		},
	),
);

type AuthStoreState = {
	isFirstVisit: boolean;

	partnershipId: string | null;
	lastBrokerPartnershipId: string | null;
};

type AuthStoreActions = {
	setIsFirstVisit: (value: boolean) => void;

	setPartnershipId: (brokerApiKey: string) => void;
	setLastBrokerPartnershipId: (lastBrokerApiKey: string) => void;
	reset: () => void;
};
