import { useEffect } from "react";
import * as Sentry from "@sentry/react";
import ReactDOM from "react-dom/client";
import {
	createRoutesFromChildren,
	matchRoutes,
	useLocation,
	useNavigationType,
} from "react-router-dom";

import "@fontsource/rubik";

import App from "./App.tsx";

import "./index.css";

Sentry.init({
	enabled: import.meta.env.VITE_PUBLIC_MRP_SENTRY_ENABLED === "true",
	dsn: import.meta.env.VITE_PUBLIC_MRP_SENTRY_DSN,
	integrations: [
		Sentry.reactRouterV6BrowserTracingIntegration({
			useEffect: useEffect,
			useLocation,
			useNavigationType,
			createRoutesFromChildren,
			matchRoutes,
		}),
		Sentry.replayIntegration(),
	],

	tracesSampleRate: 1.0,

	// Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
	// tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],

	// Capture Replay for 10% of all sessions,
	// plus for 100% of sessions with an error
	replaysSessionSampleRate: 0.1,
	replaysOnErrorSampleRate: 1.0,
});

ReactDOM.createRoot(document.getElementById("root")!).render(<App />);
