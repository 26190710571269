import type { OptionType } from "@/components/inputs/SingleSelectCombobox.tsx";
import type { ActivityPageInputDto } from "@/pages/form/schemas";
import { useCallback, useEffect, useState } from "react";
import DialogInfoIcon from "@/assets/dialogInfoIcon.svg";
import { ContactModal } from "@/components/ContactModal.tsx";
import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert";
import { AlertDialog, AlertDialogTrigger } from "@/components/ui/alert-dialog";
import { Button } from "@/components/ui/button.tsx";
import {
	Dialog,
	DialogContent,
	DialogDescription,
	DialogFooter,
	DialogHeader,
	DialogTitle,
} from "@/components/ui/dialog";
import { toast } from "@/components/ui/use-toast.ts";
import { MAX_SECONDARY_ACTIVITIES } from "@/config";
import { useCustomFormNavigate } from "@/hooks/customNavigate";
import { useClearForm } from "@/hooks/useClearForm.ts";
import { TotalTurnoverDisplay } from "@/pages/form/1/components/4/TotalTurnoverDisplay.tsx";
import { ActivityInput } from "@/pages/form/2/components/ActivityInput.tsx";
import { activityPageInputSchema } from "@/pages/form/schemas";
import { formStore } from "@/pages/form/stores/formStore.ts";
import { trpc } from "@/utils";
import { zodResolver } from "@hookform/resolvers/zod";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import { IoMdTrash } from "react-icons/io";
import { MdAddCircle } from "react-icons/md";

import type { ActivityInputDto } from "@repos/mrp-dtos";

import { DeleteAlertDialogContent } from "../../components/4/DeleteAlertDialog";
import { MainActivitySectorCard } from "../../components/MainActivitySectorCard.tsx";

export const ActivityPage = () => {
	const [swapDialogOpen, setSwapDialogOpen] = useState(false);
	const [contactModalOpen, setContactModalOpen] = useState(false);
	const [swapDetails, setSwapDetails] = useState<{
		oldPrincipalActivity: ActivityInputDto;
		newPrincipalActivity: ActivityInputDto;
		secondaryActivityThatHasHighestTurnoverIndex: number;
	} | null>(null);
	const [validationError, setValidationError] = useState<string | null>(null);

	const { activityPage, setActivityPage } = formStore((state) => ({
		activityPage: state.activityPage,
		setActivityPage: state.setActivityPage,
	}));
	const navigate = useCustomFormNavigate();
	const { clearForm } = useClearForm();

	const sectorId = activityPage.sector?.id;
	const { data: companySector = [] } =
		trpc.activities.getActivitiesBySector.useQuery(
			{ sectorId: sectorId! },
			{ enabled: sectorId !== undefined },
		);

	const methods = useForm<ActivityPageInputDto>({
		resolver: zodResolver(activityPageInputSchema),
		defaultValues: {
			...activityPage,
			secondaryActivities: activityPage.secondaryActivities?.map(
				(activity) => ({
					id: activity.id,
					turnover: activity.turnover,
					code: activity.code,
					label: activity.label,
				}),
			),
		},
		delayError: 500,
		mode: "onChange",
		reValidateMode: "onChange",
	});
	const {
		control,
		getValues,
		reset,
		clearErrors,
		handleSubmit,
		formState: { errors },
	} = methods;
	const { fields, append, remove } = useFieldArray({
		control: control,
		name: "secondaryActivities",
	});

	const handleAppendActivity = () => {
		append({} as ActivityInputDto);
	};

	const handleDelete = (index: number) => {
		remove(index);
	};

	const findActivityDetails = useCallback(
		(id: string) => {
			if (!id || !companySector) return null;

			// If companySector is an object with categories
			const allActivities = Object.values(companySector).flat();
			return allActivities.find((activity) => activity.id === id);
		},
		[companySector],
	);

	const handleCloseContactModal = () => {
		setContactModalOpen(false);
	};

	const handleConfirmContactModal = () => {
		setContactModalOpen(false);

		clearForm();
		navigate("SirenInputPage");
	};

	const handleNavigateNext = () => {
		navigate("EstablishmentsSelectPage");
	};

	const handleSwapConfirmation = () => {
		if (!swapDetails) return;

		const {
			oldPrincipalActivity,
			newPrincipalActivity,
			secondaryActivityThatHasHighestTurnoverIndex,
		} = swapDetails;
		const formData = getValues();

		// Create new form data with swapped activities
		const updatedData = {
			...formData,
			principalActivity: {
				id: findActivityDetails(newPrincipalActivity.id)?.id ?? "",
				turnover: newPrincipalActivity.turnover,
				label: findActivityDetails(newPrincipalActivity.id)?.label ?? "",
				code: findActivityDetails(newPrincipalActivity.id)?.nafCode ?? "",
			},
			secondaryActivities: [
				...formData.secondaryActivities.map((sec) => {
					const activityDetails = findActivityDetails(sec.id);
					return {
						id: activityDetails?.id ?? "",
						turnover: sec.turnover,
						label: activityDetails?.label ?? "",
						code: activityDetails?.nafCode ?? "",
					};
				}),
			],
		};

		// Update the secondary activity that was previously principal
		updatedData.secondaryActivities[
			secondaryActivityThatHasHighestTurnoverIndex - 1
		] = {
			id: findActivityDetails(oldPrincipalActivity.id)?.id ?? "",
			turnover: oldPrincipalActivity.turnover,
			label: findActivityDetails(oldPrincipalActivity.id)?.label ?? "",
			code: findActivityDetails(oldPrincipalActivity.id)?.nafCode ?? "",
		};

		// Update the form and store
		reset(updatedData);
		setActivityPage(updatedData);

		setSwapDialogOpen(false);
		setSwapDetails(null);
		handleNavigateNext();
	};

	const onSubmit = handleSubmit((data: ActivityPageInputDto) => {
		// Reset any existing validation error
		setValidationError(null);

		const checkNonAcceptedActivities = (activity: { id: string }) => {
			const activityDetails = findActivityDetails(activity.id);
			return !activityDetails || !activityDetails.isAccepted;
		};

		// Check principal activity
		if (checkNonAcceptedActivities(data.principalActivity)) {
			setContactModalOpen(true);
			return;
		}

		// Check secondary activities
		const nonAcceptedSecondaryActivity = data.secondaryActivities.find(
			checkNonAcceptedActivities,
		);
		if (nonAcceptedSecondaryActivity) {
			setContactModalOpen(true);
			return;
		}

		const principalActivity = findActivityDetails(data.principalActivity.id);
		const activities = [
			{
				id: data.principalActivity.id,
				turnover: data.principalActivity.turnover,
				type: "principal",
				label: principalActivity?.label,
				code: principalActivity?.nafCode,
			},
			...data.secondaryActivities.map((activity, index) => {
				const activityDetails = findActivityDetails(activity.id);
				return {
					id: activityDetails?.id,
					turnover: activity.turnover,
					type: "secondary",
					index,
					label: activityDetails?.label,
					code: activityDetails?.nafCode,
				};
			}),
		];

		// Sort activities by turnover in descending order
		const sortedActivities = [...activities].sort(
			(a, b) => b.turnover - a.turnover,
		);
		const highestTurnoverActivity = sortedActivities[0];

		// If the highest turnover activity is not the principal one, prepare for swap
		if (highestTurnoverActivity?.type === "secondary") {
			const oldPrincipalActivity = {
				id: data.principalActivity.id,
				turnover: data.principalActivity.turnover,
				label: data.principalActivity.label,
				code: data.principalActivity.code,
			};

			const secondaryActivityThatHasHighestTurnoverIndex = activities.findIndex(
				(activity) => activity.id === highestTurnoverActivity.id,
			);

			setSwapDetails({
				oldPrincipalActivity: oldPrincipalActivity,
				newPrincipalActivity: {
					...highestTurnoverActivity,
					id: highestTurnoverActivity.id ?? "",
					code: highestTurnoverActivity.code ?? "",
					label: highestTurnoverActivity.label ?? "",
				},
				secondaryActivityThatHasHighestTurnoverIndex:
					secondaryActivityThatHasHighestTurnoverIndex,
			});
			setSwapDialogOpen(true);
		} else {
			const principalActivityItem = findActivityDetails(
				data.principalActivity.id,
			);

			// THROW an error if principalActivityitem is undefined
			if (!principalActivityItem) return;
			// Normalize the principal activity (The principal receiv with empty values)
			const normlizedPrimaryActivity = {
				id: principalActivityItem.id,
				turnover: data.principalActivity.turnover,
				label: principalActivityItem.label,
				code: principalActivityItem.nafCode,
			};

			const updatedData = {
				...data,
				principalActivity: normlizedPrimaryActivity,
				secondaryActivities: data.secondaryActivities.map((activity) => {
					const activityDetails = findActivityDetails(activity.id);
					return {
						id: activityDetails?.id ?? "",
						turnover: activity.turnover,
						code: activityDetails?.nafCode ?? "",
						label: activityDetails?.label ?? "",
					};
				}),
			};

			setActivityPage(updatedData);

			handleNavigateNext();
		}
	});

	const formattedOptions: OptionType[] = Object.entries(companySector).flatMap(
		([category, activities]) =>
			activities.map((activity) => ({
				value: activity.id,
				label: activity.label,
				categoryLabel: category,
				isAccepted: activity.isAccepted,
				nafCode: activity.nafCode,
			})),
	);

	useEffect(() => {
		if (companySector.length) {
			reset({
				...activityPage,
				secondaryActivities:
					activityPage.secondaryActivities?.map((activity) => {
						const details = findActivityDetails(activity.id);
						return {
							id: details?.id ?? activity.id,
							turnover: activity.turnover,
							code: details?.nafCode ?? activity.code,
							label: details?.label ?? activity.label,
						};
					}) || [],
			});
		}
	}, [companySector, activityPage, methods, findActivityDetails, reset]);

	useEffect(() => {
		if (errors.principalActivity?.root?.type === "custom") {
			toast({
				title: "Erreur",
				description: errors.principalActivity.root.message,
				variant: "destructive",
			});
		}
	}, [errors]);

	return (
		<FormProvider {...methods}>
			<form
				onSubmit={onSubmit}
				id="form"
				className="flex w-full flex-col gap-6"
			>
				<div className="flex flex-col">
					<h1>Activité(s) de l&apos;entreprise</h1>
					<p className="text-[#595959]">
						Vous pouvez ajouter un total de 3 activités : une principale et deux
						secondaires.
					</p>
				</div>

				<MainActivitySectorCard
					onSectorChange={(sector) => {
						reset({
							sector: sector,
							principalActivity: {},
							secondaryActivities: [],
						});
						clearErrors();
					}}
				/>

				<ActivityInput<OptionType>
					options={formattedOptions}
					activityFormFieldName="principalActivity.id"
					activityFormFieldLabel="Activité principale"
					turnoverFormFieldLabel="Chiffre d'affaires HT N-1 ou prévisionnel"
					turnoverFormFieldName="principalActivity.turnover"
					onItemSelect={(id) => {
						const activityDetails = findActivityDetails(id);
						methods.setValue(
							"principalActivity.code",
							activityDetails?.nafCode ?? "",
						);
						methods.setValue(
							"principalActivity.label",
							activityDetails?.label ?? "",
						);
						methods.setValue("principalActivity.id", id);
						methods.clearErrors("principalActivity");
					}}
				/>

				{fields.map((_field, index) => (
					<ActivityInput<OptionType>
						key={index}
						options={formattedOptions}
						activityFormFieldName={`secondaryActivities.${index}.id`}
						activityFormFieldLabel={`Activité n° ${String(index + 1).padStart(2, "0")}`}
						onItemSelect={(id) => {
							const activityDetails = findActivityDetails(id);
							methods.setValue(
								`secondaryActivities.${index}.code`,
								activityDetails?.nafCode ?? "",
							);
							methods.setValue(
								`secondaryActivities.${index}.label`,
								activityDetails?.label ?? "",
							);
							methods.setValue(`secondaryActivities.${index}.id`, id);
							methods.clearErrors(`secondaryActivities.${index}`);
						}}
						turnoverFormFieldLabel="Chiffre d'affaires HT N-1 ou prévisionnel"
						turnoverFormFieldName={`secondaryActivities.${index}.turnover`}
						rightIcon={
							<span className="cursor-pointer self-center text-gray-600">
								<AlertDialog>
									<AlertDialogTrigger asChild>
										<IoMdTrash className="size-6 fill-[#F70004]" />
									</AlertDialogTrigger>
									<DeleteAlertDialogContent
										onConfirm={() => handleDelete(index)}
										description="Êtes-vous certain de vouloir supprimer cette activité ? Cette action est irréversible."
									/>
								</AlertDialog>
							</span>
						}
						index={index}
					/>
				))}

				<div className="w-auto">
					<Button
						className="justify-start gap-2 text-lg font-medium text-primary hover:bg-transparent hover:text-primary disabled:cursor-not-allowed disabled:text-gray-400"
						type="button"
						variant="ghost"
						disabled={fields.length == MAX_SECONDARY_ACTIVITIES}
						onClick={handleAppendActivity}
					>
						<MdAddCircle size="20" />
						Ajouter d&apos;autre(s) activité(s)
					</Button>
				</div>
				<div className="self-end">
					<TotalTurnoverDisplay />
				</div>
				{validationError && (
					<Alert variant="destructive" className="mt-4">
						<AlertTitle>Error</AlertTitle>
						<AlertDescription>{validationError}</AlertDescription>
					</Alert>
				)}
			</form>

			{/* Swap Activities Dialog */}
			<Dialog open={swapDialogOpen} onOpenChange={setSwapDialogOpen}>
				<DialogContent className="sm:max-w-xl">
					<DialogHeader className="flex flex-row gap-2">
						<div className="w-[28%]">
							<img src={DialogInfoIcon} className="size-12" />
						</div>
						<div className="">
							<DialogTitle className="text-xl font-semibold text-[#FF8426]">
								Changement d&apos;activité principale
							</DialogTitle>
							<DialogDescription className="text-lg font-medium text-[#333]">
								L&apos;activité secondaire n°
								{swapDetails &&
								swapDetails.secondaryActivityThatHasHighestTurnoverIndex
									? String(
											swapDetails.secondaryActivityThatHasHighestTurnoverIndex,
										).padStart(2, "0")
									: ""}{" "}
								a un chiffre d&apos;affaires supérieur à celui de
								l&apos;activité principale. Cette activité deviendra donc
								l&apos;activité principale.
							</DialogDescription>
						</div>
					</DialogHeader>
					<DialogFooter className="mt-8">
						<Button
							variant="ghost"
							className="border border-[#949494]"
							onClick={() => setSwapDialogOpen(false)}
						>
							Annuler
						</Button>
						<Button onClick={handleSwapConfirmation}>Confirmer</Button>
					</DialogFooter>
				</DialogContent>
			</Dialog>
			<ContactModal
				isOpen={contactModalOpen}
				onClose={handleCloseContactModal}
				description={
					"Nous ne pouvons pas assurer cette entreprise, car l’activité liée au code NAF fourni n’est pas éligible à notre couverture. Pour toute assistance, veuillez nous contacter à notre adresse mail"
				}
				onConfirm={handleConfirmContactModal}
			/>
		</FormProvider>
	);
};
