import type { ActivitySectorDto, SirenPageDto } from "@/pages/form/schemas";
import { useContext, useEffect, useState } from "react";
import InfoIcon from "@/assets/InfoIcon.svg";
import { ContactModal } from "@/components/ContactModal.tsx";
import { CompanySearch } from "@/components/inputs/CompanySearch.tsx";
import {
	Dialog,
	DialogClose,
	DialogContent,
	DialogHeader,
	DialogTitle,
	DialogTrigger,
} from "@/components/ui/dialog";
import { useCustomFormNavigate } from "@/hooks/customNavigate.ts";
import { LayoutButtonsContext } from "@/layouts/Layout.tsx";
import { EntrepriseNotCreatedYetButton } from "@/pages/form/1/components/1/EntrepriseNotCreatedYetButton.tsx";
import { sirenPageSchema } from "@/pages/form/schemas";
import { formStore } from "@/pages/form/stores/formStore.ts";
import { trpc } from "@/utils";
import { zodResolver } from "@hookform/resolvers/zod";
import { X } from "lucide-react";
import { FormProvider, useForm, useWatch } from "react-hook-form";

import type { DetailedAddressDto } from "@repos/mrp-dtos";

export const SirenInputPage = () => {
	const {
		sirenPage,
		setSirenPage,
		setMainEstablishmentDetailedAddress,
		mainEstablishment,
		setMainSectorActivity,
	} = formStore((state) => ({
		sirenPage: state.sirenPage,
		setSirenPage: state.setSirenPage,
		setMainEstablishmentDetailedAddress:
			state.setMainEstablishmentDetailedAddress,
		mainEstablishment: state.establishments.mainEstablishment,
		setMainSectorActivity: state.setMainSectorActivity,
	}));
	const { setNextButtonDisabled, setNextButtonLoading } =
		useContext(LayoutButtonsContext);
	const navigate = useCustomFormNavigate();
	const methods = useForm<SirenPageDto>({
		resolver: zodResolver(sirenPageSchema),
		defaultValues: {
			...sirenPage,
			mainEstablishmentAddress: mainEstablishment,
		},
	});

	const [
		isContactBecauseCreationDateDialogOpen,
		setIsContactBecauseCreationDateDialogOpen,
	] = useState(false);
	const [
		isContactBecauseLegalProcedureModelOpen,
		setIsContactBecauseLegalProcedureModelOpen,
	] = useState(false);

	useEffect(() => {
		if (
			methods.formState.errors.company?.creationDate &&
			methods.formState.errors.company.creationDate.type === "too_small"
		) {
			setIsContactBecauseCreationDateDialogOpen(true);
			document.body.style.pointerEvents = "auto";
		}
	}, [methods.formState.errors.company?.creationDate]);

	const [sectorData, setSectorData] = useState<ActivitySectorDto[] | null>(
		null,
	);
	const nafCode = useWatch({
		control: methods.control,
		name: "company.nafCode",
	});

	const { data: companySector = [] } =
		trpc.activities.getActivitiesDetails.useQuery(
			{ nafCode: nafCode },
			{ enabled: nafCode !== undefined },
		);
	const hasCompanyCleanRecordMutation =
		trpc.companies.hasCleanBodaccRecords.useMutation({
			onSuccess: (data) => {
				if (data === false) {
					setIsContactBecauseLegalProcedureModelOpen(true);
					return;
				}
				navigate("NumberOfSinistresPage");
			},
		});

	useEffect(() => {
		if (companySector.length > 0) {
			setSectorData(
				companySector.map((sector) => ({
					label: sector.sector.label,
					id: sector.sector.id,
					iconUrl: sector.sector.iconUrl,
					selectedIconUrl: sector.sector.selectedIconUrl,
				})),
			);
		}
	}, [companySector]);
	const onSubmit = async (data: SirenPageDto) => {
		setSirenPage(data);
		setMainEstablishmentDetailedAddress(
			data.mainEstablishmentAddress as DetailedAddressDto,
		);

		if (companySector.length === 0) {
			setIsContactBecauseLegalProcedureModelOpen(true);
			return;
		}

		if (sectorData) {
			// Get the sector from the sectorData
			const sector = sectorData[0];
			// Set the sector in the store
			if (sector) {
				setMainSectorActivity({
					id: sector.id,
					label: sector.label,
					iconUrl: sector.iconUrl,
					selectedIconUrl: sector.selectedIconUrl,
				});
			}
		}
		if (data.company.siren !== "999999999") {
			await hasCompanyCleanRecordMutation.mutateAsync({
				siren: data.company.siren,
			});
		} else {
			navigate("NumberOfSinistresPage");
			setNextButtonDisabled(false);
		}
	};
	//Initially set the next button to disabled until the user selects a company
	useEffect(() => {
		if (hasCompanyCleanRecordMutation.isIdle) {
			if (sirenPage.company) {
				setNextButtonDisabled(false);
			} else {
				setNextButtonDisabled(true);
			}
		} else {
			setNextButtonDisabled(hasCompanyCleanRecordMutation.isLoading);
			setNextButtonLoading(hasCompanyCleanRecordMutation.isLoading);
		}
		return () => {
			setNextButtonDisabled(false);
			setNextButtonLoading(false);
		};
	}, [
		setNextButtonDisabled,
		setNextButtonLoading,
		hasCompanyCleanRecordMutation.isLoading,
		hasCompanyCleanRecordMutation.isIdle,
		sirenPage.company,
	]);

	return (
		<>
			<FormProvider {...methods}>
				<form
					onSubmit={methods.handleSubmit(onSubmit)}
					className="flex w-full flex-col gap-12"
					id="form"
				>
					<Dialog>
						<div className="flex flex-col items-start justify-between">
							<h1>
								Indiquez la raison sociale ou le numéro de SIREN de
								l&apos;entreprise
							</h1>
							<div className="mt-3 flex items-center justify-start gap-2 text-left">
								<img src={InfoIcon} />
								<p className="font-medium">
									Certains devis nécessitent une visite de risque
								</p>
								<DialogTrigger asChild>
									<span className="cursor-pointer font-medium text-[#598EAD] underline">
										Plus de détails
									</span>
								</DialogTrigger>
								<DialogContent className="max-w-2xl outline-0">
									<DialogHeader className="flex flex-row items-center justify-between">
										<DialogTitle className="text-primary">
											Critères de visite de risque :
										</DialogTitle>
										<DialogClose>
											<X className="size-6" />
										</DialogClose>
									</DialogHeader>
									<table className="mt-4 w-full rounded-sm">
										<tbody className="rounded-lg border border-[#E4E4E4]">
											<tr className="bg-[#F2F4F6]">
												<td className="p-3">
													<span className="font-semibold">
														C.A. = Chiffre d&apos;Affaires - S.D. =
													</span>{" "}
													Superficie Développée de l&apos;ensemble des locaux,
													ensemble des additions des surfaces extérieures par
													niveau.
												</td>
											</tr>
											<tr className="bg-white">
												<td className="p-3">
													<span className="font-semibold">
														C.A. {"≤"} 250 000 € et/ou S.D. ≤ 100 m² =
													</span>{" "}
													déclaration client.
												</td>
											</tr>
											<tr className="bg-[#F2F4F6]">
												<td className="p-3">
													<span className="font-semibold">
														C.A. entre 250 001 € et 1 000 000 € et/ou S.D. entre
														101 m² à 999 m² =
													</span>{" "}
													visite de risque courtier et/ou inspecteur +
													validation souscripteur.
												</td>
											</tr>
											<tr className="bg-white">
												<td className="p-3">
													<span className="font-semibold">
														C.A. entre 1 000 001 € et de 5 000 000 € et/ou S.D.
														entre 1 000 m² à 3 000 m² =
													</span>{" "}
													visite d&apos;un expert + validation inspecteur +
													validation souscripteur.
												</td>
											</tr>
											<tr className="bg-[#F2F4F6]">
												<td className="p-3">
													<span className="font-semibold">
														C.A. entre 5 000 001 € et 9 000 000 € et/ou S.D.
														entre 3 001 m² à 5 000 m² =
													</span>{" "}
													visite d&apos;un expert + validation inspecteur +
													validation compagnie.
												</td>
											</tr>
											<tr className="bg-white">
												<td className="p-3">
													<span className="font-semibold">
														C.A. ≥ 9 000 001 € et/ou ≥ 5 000 m² =
													</span>{" "}
													refus de souscription.
												</td>
											</tr>
										</tbody>
									</table>
								</DialogContent>
							</div>
						</div>
						<div className="relative h-fit max-w-3xl gap-8">
							<div>
								<CompanySearch
									label="Raison Sociale / numéro SIREN"
									required
									setNextButtonDisabled={setNextButtonDisabled}
								/>
								<EntrepriseNotCreatedYetButton />
							</div>
						</div>
					</Dialog>
				</form>
			</FormProvider>
			<ContactModal
				isOpen={isContactBecauseLegalProcedureModelOpen}
				onClose={() => {
					setIsContactBecauseLegalProcedureModelOpen(false);
				}}
				description="Nous ne sommes malheureusement pas en mesure de vous proposer une couverture adaptée aux besoins de votre client. Veuillez envoyer votre demande à notre adresse e-mail."
			/>
			<ContactModal
				description="Nous regrettons que nous n’assurions pas les sociétés dont la date de création est avant 1800. Pour toute assistance, veuillez nous contacter à notre adresse mail."
				isOpen={isContactBecauseCreationDateDialogOpen}
				onClose={() => {
					setIsContactBecauseCreationDateDialogOpen(false);
					document.body.style.pointerEvents = "auto";
				}}
			/>
		</>
	);
};
