import { useMemo } from "react";
import LiabilityIcon from "@/assets/LiabilityIcon.svg";
import LosesIcon from "@/assets/LosesIcon.svg";
import PropertyDamageIcon from "@/assets/PropertyDamageIcon.svg";
import {
	Accordion,
	AccordionContent,
	AccordionItem,
	AccordionTrigger,
} from "@/components/ui/accordion";
import { Card, CardContent, CardHeader } from "@/components/ui/card";
import { Checkbox } from "@/components/ui/checkbox";
import { Label } from "@/components/ui/label";
import {
	Tooltip,
	TooltipContent,
	TooltipProvider,
	TooltipTrigger,
} from "@/components/ui/tooltip";
import { useCustomFormNavigate } from "@/hooks/customNavigate";
import { formStore } from "@/pages/form/stores/formStore";
import { cn, trpc } from "@/utils";
import { FormProvider, useForm } from "react-hook-form";
import { z } from "zod"; // Define base types

// Define base types
interface Guarantee {
	guaranteeType: string;
	guaranteeValue: number | undefined;
	riskClass: string;
	Guarantee: {
		label: string;
		order: number;
		isOptional: boolean;
	};
}

interface Activity {
	turnover: number;
	establishmentLabel: string;
	id: string;
	code: string;
	label: string;
}

interface ExtendedActivity extends Partial<Activity> {
	isPrincipal: boolean;
}

interface GuaranteeRiskClass {
	guaranteeRiskClass: Guarantee[];
}

// Zod schemas
export const chosenOptionSchema = z.object({
	guaranteeType: z.string(),
	guaranteeValue: z.number(),
	order: z.number(),
	label: z.string(),
});

export type ChosenOption = z.infer<typeof chosenOptionSchema>;

export const activityOptionsSchema = z.object({
	activityId: z.string(),
	chosenOptions: z.array(chosenOptionSchema),
	establishmentId: z.string(),
});

export type ActivityOptions = z.infer<typeof activityOptionsSchema>;

export interface ActivityPageData {
	principalActivity: Partial<Activity>;
	secondaryActivities?: Partial<Activity>[];
}

export interface FormValues {
	chosenOptions: Array<{
		activityId: string;
		establishmentId: string;
		chosenOptions: ChosenOption[];
	}>;
}

// Helper constants
const PROPERTY_DAMAGE_TYPES = [
	"INCENDIE",
	"TGNA",
	"ASSIST",
	"CATNAT",
	"DDE",
	"BDG",
	"VOL",
	"MEF",
	"BDM",
	"MMT",
	"DEL",
	"EEX",
	"SFM",
	"EFFONDREMENT",
];

const LIABILITY_TYPES = ["RCE", "RCPRO", "PJ", "DPR"];

const LOSES_TYPES = ["PE_INCENDIE", "PVF", "PE_VOL"];

const categorizeGuarantees = (guarantees: Guarantee[]) => {
	const propertyDamage: Guarantee[] = [];
	const liability: Guarantee[] = [];
	const loses: Guarantee[] = [];

	guarantees.forEach((item) => {
		if (
			PROPERTY_DAMAGE_TYPES.includes(item.guaranteeType) &&
			item.Guarantee.isOptional
		) {
			propertyDamage.push(item);
		} else if (
			LIABILITY_TYPES.includes(item.guaranteeType) &&
			item.Guarantee.isOptional
		) {
			liability.push(item);
		} else if (
			LOSES_TYPES.includes(item.guaranteeType) &&
			item.Guarantee.isOptional
		) {
			loses.push(item);
		}
	});

	propertyDamage.sort((a, b) => a.Guarantee.order - b.Guarantee.order);
	liability.sort((a, b) => a.Guarantee.order - b.Guarantee.order);
	loses.sort((a, b) => a.Guarantee.order - b.Guarantee.order);

	return { propertyDamage, liability, loses };
};

export const OptionalGuarantees = () => {
	const navigate = useCustomFormNavigate();

	const {
		activityPage,
		setChosenOptions,
		chosenOptions,
		establishments,
		resetChosenOptionsGuaranteeValues,
	} = formStore((state) => ({
		activityPage: state.activityPage as ActivityPageData,
		setChosenOptions: state.setChosenOptions,
		chosenOptions: state.chosenOptions.chosenOptions || [],
		establishments: state.establishments.insuredEstablishments,
		resetChosenOptionsGuaranteeValues: state.resetChosenOptionsGuaranteeValues,
	}));

	const { principalActivity, secondaryActivities } = activityPage;

	const activitiesMap = useMemo(() => {
		const map = new Map<string, boolean>();

		// Mark principal activity
		if (principalActivity.id) {
			map.set(principalActivity.id, true);
		}

		// Mark secondary activities
		if (secondaryActivities) {
			secondaryActivities.forEach((activity) => {
				if (activity.id) {
					map.set(activity.id, false);
				}
			});
		}

		return map;
	}, [principalActivity, secondaryActivities]);

	const establishmentActivities = useMemo(() => {
		return establishments
			?.map((establishment) => {
				// Find the activity that matches the relatedActivityId in the establishment
				const matchedActivity =
					principalActivity.id === establishment.hightestActivityId
						? principalActivity
						: secondaryActivities?.find(
								(activity) => activity.id === establishment.hightestActivityId,
							);

				// Create an extended activity object with isPrincipal
				const extendedActivity: ExtendedActivity = matchedActivity
					? {
							...matchedActivity,
							establishmentLabel: establishment.siret,
							isPrincipal: activitiesMap.get(matchedActivity.id ?? "") ?? false,
						}
					: {
							id: establishment.hightestActivityId,
							establishmentLabel: establishment.siret,
							code: undefined,
							label: undefined,
							isPrincipal:
								activitiesMap.get(establishment.hightestActivityId || "") ??
								false,
						};

				return {
					...establishment,
					activity: extendedActivity,
				};
			})
			.filter((item) => item.activity); // Remove any establishments without a matching activity
	}, [establishments, principalActivity, secondaryActivities, activitiesMap]);

	const activitiesQueries = establishmentActivities?.map((estActivity) =>
		trpc.activities.getActivityGuarantees.useQuery({
			activityId: estActivity.activity.id ?? "",
		}),
	);

	const methods = useForm<FormValues>({
		defaultValues: {
			chosenOptions: chosenOptions || [],
		},
	});

	const chosenOptionsWatch = methods.watch("chosenOptions");

	const onSubmit = (data: FormValues) => {
		const sortedData = {
			...data,
			chosenOptions: data.chosenOptions.map((activity) => ({
				...activity,
				chosenOptions: activity.chosenOptions.sort((a, b) => a.order - b.order),
			})),
		};
		const hasChosenOptions = sortedData.chosenOptions.some(
			(option) => option.chosenOptions.length > 0,
		);

		if (hasChosenOptions) {
			setChosenOptions(sortedData);
			navigate("GuaranteeAmountsPage");
		} else {
			navigate("QuotesPage");
		}
	};

	const handleCheckboxChange = (
		activityId: string,
		establishmentId: string,
		guarantee: Guarantee,
		checked: boolean,
	) => {
		resetChosenOptionsGuaranteeValues();
		const updatedChosenOptions = [...chosenOptionsWatch];

		// Special handling for PJ guarantee
		if (guarantee.guaranteeType === "PJ") {
			// Create a copy of the chosenOptions to update

			// Iterate over all establishments
			establishmentActivities?.forEach((establishment) => {
				const activity = establishment.activity;
				const estId = establishment.siret;
				// Find or create the activity options for this establishment
				let activityOptions = updatedChosenOptions.find(
					(option) =>
						option.activityId === activity.id &&
						option.establishmentId === estId,
				);

				if (!activityOptions) {
					activityOptions = {
						activityId: activity.id ?? "",
						establishmentId: estId,
						chosenOptions: [],
					};
					updatedChosenOptions.push(activityOptions);
				}

				// Handle PJ selection
				const pjGuaranteeExists = activityOptions.chosenOptions.some(
					(opt) => opt.guaranteeType === "PJ",
				);

				if (checked && !pjGuaranteeExists) {
					// Add PJ guarantee if not present
					activityOptions.chosenOptions.push({
						guaranteeType: "PJ",
						guaranteeValue: 0,
						order: guarantee.Guarantee.order,
						label: "Garantie PJ",
					});
				} else if (!checked && pjGuaranteeExists) {
					// Remove PJ guarantee
					activityOptions.chosenOptions = activityOptions.chosenOptions.filter(
						(opt) => opt.guaranteeType !== "PJ",
					);
				}
			});
		} else {
			// Find or create the activity options for this specific establishment
			let activityOptions = updatedChosenOptions.find(
				(option) =>
					option.activityId === activityId &&
					option.establishmentId === establishmentId,
			);

			if (!activityOptions) {
				activityOptions = {
					activityId,
					establishmentId,
					chosenOptions: [],
				};
				updatedChosenOptions.push(activityOptions);
			}

			if (
				activityOptions.establishmentId === establishmentId &&
				activityOptions.activityId === activityId
			) {
				if (checked) {
					// Add guarantee if not already present
					const existingGuarantee = activityOptions.chosenOptions.find(
						(opt) => opt.guaranteeType === guarantee.guaranteeType,
					);

					if (!existingGuarantee) {
						activityOptions.chosenOptions.push({
							guaranteeType: guarantee.guaranteeType,
							// @ts-expect-error waiting for number not string
							guaranteeValue: guarantee.guaranteeValue ?? "",
							order: guarantee.Guarantee.order,
							label: guarantee.Guarantee.label,
						});
					}
				} else {
					// Remove guarantee
					activityOptions.chosenOptions = activityOptions.chosenOptions.filter(
						(opt) => opt.guaranteeType !== guarantee.guaranteeType,
					);
				}
			}
		}
		methods.setValue("chosenOptions", updatedChosenOptions);
	};

	const isGuaranteeChecked = (
		activityId: string,
		establishmentId: string,
		guaranteeType: string,
	): boolean => {
		const activityOptions = chosenOptionsWatch.find(
			(option) =>
				option.activityId === activityId &&
				option.establishmentId === establishmentId &&
				option.chosenOptions.some(
					(option) => option.guaranteeType === guaranteeType,
				),
		);

		return !!activityOptions;
	};

	const totalTurnover = useMemo(() => {
		return (
			establishmentActivities?.reduce(
				(total, estActivity) => total + (estActivity.activity.turnover || 0),
				0,
			) ?? 0
		);
	}, [establishmentActivities]);

	return (
		<FormProvider {...methods}>
			<form
				onSubmit={methods.handleSubmit(onSubmit)}
				className="flex w-full flex-col gap-12"
				id="form"
			>
				<div className="flex items-center justify-between">
					<h1>
						Veuillez sélectionner les garanties optionnelles pour ajuster la
						couverture aux besoins de votre client
					</h1>
				</div>
				<Card className="flex w-full flex-col gap-4 rounded-2xl border-none bg-white shadow-none">
					<Accordion
						type="single"
						collapsible
						className="w-full p-0"
						defaultValue="item-1"
					>
						{establishmentActivities?.map((estActivity, index) => {
							const establishmentId = estActivity.siret;
							const activity = estActivity.activity;
							const activityData = activitiesQueries?.[index]?.data as
								| GuaranteeRiskClass
								| undefined;
							const { propertyDamage, liability, loses } =
								activityData?.guaranteeRiskClass
									? categorizeGuarantees(activityData.guaranteeRiskClass)
									: { propertyDamage: [], liability: [], loses: [] };

							const turnover = Number(activity.turnover);
							const formattedTurnover = new Intl.NumberFormat("fr-FR", {
								useGrouping: true,
							}).format(turnover);

							return (
								<AccordionItem
									key={activity.id + establishmentId + index}
									value={`item-${index + 1}`}
									className="mb-4 w-full border-none"
								>
									<AccordionTrigger
										className={cn(
											"flex w-full flex-row items-center justify-between rounded-2xl rounded-b-none py-0 no-underline",
											activity.isPrincipal ? "bg-[#023048]" : "bg-[#F8F5F5]",
										)}
									>
										<CardHeader
											className={cn(
												"flex w-full flex-row items-center justify-between rounded-2xl rounded-b-none no-underline",
												activity.isPrincipal ? "bg-[#023048]" : "bg-[#F8F5F5]",
											)}
										>
											<div className="company-infos flex items-center gap-4 no-underline">
												<div className="flex flex-col gap-1 text-left no-underline">
													<p
														className={cn(
															"text-lg font-bold no-underline",
															activity.isPrincipal
																? "text-white"
																: "text-[#333333]",
														)}
													>
														{activity.isPrincipal
															? "Activité Principale "
															: `Activité Secondaire `}
														: {activity.label}
													</p>
													<p
														className={cn(
															"text-sm no-underline",
															activity.isPrincipal
																? "text-white"
																: "text-[#595959]",
														)}
													>
														Etablissement :{" "}
														{estActivity.activity.establishmentLabel}
													</p>
													<p
														className={cn(
															"text-sm no-underline",
															activity.isPrincipal
																? "text-white"
																: "text-[#595959]",
														)}
													>
														{activity.code} - CA : {formattedTurnover} €
													</p>
												</div>
											</div>
										</CardHeader>
									</AccordionTrigger>
									<AccordionContent className="p-0">
										<CardContent className="border border-[#E4E4E4] p-0">
											{propertyDamage.length > 0 && (
												<>
													<div className="flex w-full flex-row gap-3 border-b border-[#E4E4E4] bg-[#FCFCFC] px-4 py-6">
														<img src={PropertyDamageIcon} alt="" />
														<p className="text-lg font-semibold">
															Dommages aux biens
														</p>
													</div>
													<div className="flex w-full flex-wrap bg-white px-4 py-6">
														{propertyDamage
															.filter(
																(guarantee) => guarantee.riskClass !== "R",
															)
															.map((guarantee) => (
																<div
																	key={
																		guarantee.guaranteeType + establishmentId
																	}
																	className="mb-4 flex w-1/2 items-center space-x-2"
																>
																	<Checkbox
																		id={`${activity.id}-${guarantee.guaranteeType}`}
																		checked={isGuaranteeChecked(
																			activity.id ?? "",
																			establishmentId,
																			guarantee.guaranteeType,
																		)}
																		onCheckedChange={(checked) => {
																			handleCheckboxChange(
																				activity.id ?? "",
																				establishmentId,
																				guarantee,
																				checked as boolean,
																			);
																		}}
																		className="rounded-full border-primary data-[state=checked]:bg-primary"
																	/>
																	<Label
																		htmlFor={`${activity.id}-${guarantee.guaranteeType}`}
																		className="text-md cursor-pointer font-medium"
																	>
																		{guarantee.Guarantee.label}
																	</Label>
																</div>
															))}
													</div>
												</>
											)}
											{loses.length > 0 && (
												<>
													<div className="flex w-full flex-row gap-3 border-b border-[#E4E4E4] bg-[#FCFCFC] px-4 py-6">
														<img src={LosesIcon} alt="" />
														<p className="text-lg font-semibold">
															Pertes d&apos;activités
														</p>
													</div>
													<div className="flex w-full flex-wrap bg-white px-4 py-6">
														{loses
															.filter(
																(guarantee) => guarantee.riskClass !== "R",
															)
															.map((guarantee) => (
																<div
																	key={
																		guarantee.guaranteeType +
																		establishmentId +
																		activity.id
																	}
																	className="mb-4 flex w-1/2 items-center space-x-2"
																>
																	<Checkbox
																		id={`${activity.id}-${guarantee.guaranteeType}-${establishmentId}`}
																		checked={isGuaranteeChecked(
																			activity.id ?? "",
																			establishmentId,
																			guarantee.guaranteeType,
																		)}
																		onCheckedChange={(checked) =>
																			handleCheckboxChange(
																				activity.id ?? "",
																				establishmentId,
																				guarantee,
																				checked as boolean,
																			)
																		}
																		className="rounded-full border-primary data-[state=checked]:bg-primary"
																	/>
																	<Label
																		htmlFor={`${activity.id}-${guarantee.guaranteeType}-${establishmentId}`}
																		className="text-md cursor-pointer font-medium"
																	>
																		{guarantee.Guarantee.label}
																	</Label>
																</div>
															))}
													</div>
												</>
											)}
											{liability.length > 0 && (
												<>
													<div className="flex w-full flex-row gap-3 border-b border-[#E4E4E4] bg-[#FCFCFC] px-4 py-6">
														<img src={LiabilityIcon} alt="" />
														<p className="text-lg font-semibold">
															Responsabilité civile
														</p>
													</div>
													<div className="flex w-full flex-wrap bg-white px-4 py-6">
														{liability
															.filter(
																(guarantee) => guarantee.riskClass !== "R",
															)
															.map((guarantee) => {
																const isPJDisabled = !!(
																	totalTurnover &&
																	guarantee.guaranteeType === "PJ" &&
																	totalTurnover > 5000000
																);
																return (
																	<div
																		key={
																			guarantee.guaranteeType +
																			establishmentId +
																			activity.id
																		}
																		className="mb-4 flex w-1/2 items-center space-x-2"
																	>
																		<TooltipProvider>
																			<Tooltip>
																				<TooltipTrigger asChild>
																					<div className="flex items-center gap-2">
																						<Checkbox
																							id={`${activity.id}-${guarantee.guaranteeType}`}
																							checked={isGuaranteeChecked(
																								activity.id ?? "",
																								establishmentId,
																								guarantee.guaranteeType,
																							)}
																							onCheckedChange={(checked) => {
																								handleCheckboxChange(
																									activity.id ?? "",
																									establishmentId,
																									guarantee,
																									checked as boolean,
																								);
																							}}
																							disabled={isPJDisabled}
																							className="rounded-full border-primary data-[state=checked]:bg-primary"
																						/>
																						<Label
																							htmlFor={`${activity.id}-${guarantee.guaranteeType}`}
																							className="text-md cursor-pointer font-medium"
																						>
																							{guarantee.Guarantee.label}
																						</Label>
																					</div>
																				</TooltipTrigger>
																				{isPJDisabled && (
																					<TooltipContent>
																						<p>
																							La garantie Protection Juridique
																							ne peut pas être souscrite, car le
																							total des chiffres d&apos;affaires
																							déclarés est supérieur à 5 000 000
																							€.
																						</p>
																					</TooltipContent>
																				)}
																			</Tooltip>
																		</TooltipProvider>
																	</div>
																);
															})}
													</div>
												</>
											)}
										</CardContent>
									</AccordionContent>
								</AccordionItem>
							);
						})}
					</Accordion>
				</Card>
			</form>
		</FormProvider>
	);
};

export default OptionalGuarantees;
