import { styles } from "@/components/quotePdf/styles";
import { Image, Text, View } from "@react-pdf/renderer";

import type { SubscriptionDetailsForQuoteDto } from "@repos/mrp-dtos";

const OtherDispositions = ({
	numberOfPages,
	...props
}: SubscriptionDetailsForQuoteDto & {
	numberOfPages: number;
}) => {
	return (
		<>
			<Text style={[styles.sectionHeader, styles.mt_10]}>
				AUTRES DISPOSITIONS
			</Text>
			<Text style={[styles.bodyText, styles.bold]}>
				{`Le Souscripteur certifie :`}
			</Text>
			<Text style={[styles.bodyText, styles.mb_10]}>
				{`• Être juridiquement habilité à représenter l’entreprise ;`}
			</Text>
			<Text style={[styles.bodyText, styles.mb_10]}>
				{`• Que le devis a été établi selon ces déclarations et que le Code des assurances prévoit expressément la nullité
du contrat (art. L. 113-8) ou la réduction des indemnités (art. L. 113-9) en cas de réticence, omission, fausse
déclaration ou déclaration inexacte.`}
			</Text>
			<Text style={[styles.bodyText, styles.bold]}>
				{`Je reconnais avoir reçu et pris connaissance, préalablement à la souscription, des documents ci-dessous en
vigueur à la date de souscription du contrat :`}
			</Text>
			<Text style={[styles.bodyText, styles.mb_10]}>• La fiche IPID</Text>
			<Text style={[styles.bodyText, styles.mb_10]}>
				• Les Dispositions Générales MRP ACCELERANTE V1.9 270924
			</Text>
			<Text style={[styles.bodyText, styles.mb_10]}>
				• La notice d’information assistance
			</Text>
			{props.isPJChosen && (
				<Text style={[styles.bodyText, styles.mb_10]}>
					• Les Conditions générales de la protection juridique CFDP
				</Text>
			)}
			{props.isPJChosen && (
				<Text style={[styles.bodyText, styles.mb_10]}>
					• La Fiche IPID Protection juridique
				</Text>
			)}
			<Text style={[styles.bodyText, styles.bold]}>
				{`RECLAMATION et MEDIATION`}
			</Text>
			<Text style={[styles.bodyText, styles.mb_10]}>
				{`En cas de mécontentement concernant le contrat ou le service d'assurance, Vous pouvez adresser vos éventuelles
réclamations au Service Réclamation :`}
			</Text>
			<Text style={[styles.bodyText, styles.mb_10]}>
				• Par courrier AS SOLUTIONS, à l’adresse suivante, 15 rue Bernoulli
				75008 Paris
			</Text>
			<Text style={[styles.bodyText, styles.mb_10]}>
				• Par mail à reclamations@as-solutions.fr
			</Text>
			<Text style={[styles.bodyText, styles.mb_10]}>
				• En cas d’appel téléphonique, nous vous remercions de confirmer votre
				réclamation par écrit en utilisant l’un des 3 moyens précédents.
			</Text>
			<Text style={[styles.bodyText, styles.mb_10]}>
				{`Nous nous engageons d’accuser réception par écrit de votre réclamation écrite, 10 (dix) jours ouvrables maximum après
l’envoi* de votre réclamation et vous apporter une réponse dans un délai de 2 (deux) mois maximum après l’envoi* de
votre réclamation.
Si toutefois vous n’étiez pas satisfait(e) de notre réponse, vous pouvez solliciter le Médiateur à l’adresse : La Médiation
de l’Assurance - TSA 50110 - 75441 Paris Cedex 09 ou directement sur le site internet www.mediation-assurance.org. Le
médiateur peut être saisi deux mois après l’envoi de la première réclamation écrite quel que soit l’interlocuteur ou le
service auprès duquel la réclamation a été formulée.
* le cachet de la Poste faisant foi`}
			</Text>
			<Text style={[styles.bodyText, styles.bold]}>
				{`Information sur la protection des données personnelles :`}
			</Text>
			<Text style={[styles.bodyText, styles.mb_10]}>
				{`Les informations personnelles recueillies sont indispensables pour vous proposer la meilleure couverture d'assurance adaptée à votre profil. Conformément à la réglementation européenne sur la protection des données personnelles, vous pouvez exercer vos droits d'accès, de rectification, de suppression, à la portabilité des données et de limitation du traitement aux données vous concernant en contactant notre Délégué à la Protection des Données Personnelles (DPO), par mail à dpo@as-solutions.fr, ou par courrier à l’adresse suivante : DPO AS Solutions 15 Rue Bernoulli 75008 Paris.`}
			</Text>
			<Text style={[styles.bodyText, styles.bold]}>
				{`Informatique et libertés :`}
			</Text>
			<Text style={[styles.bodyText, styles.mb_10]}>
				{`En application de la Loi n° 78-17 du 6 janvier 1978, dite « Informatique et Liberté » il est précisé que les données à
caractère personnel recueillies sont obligatoires pour conclure le Contrat, et qu’à ce titre elles feront l’objet d’un
traitement dont les co-responsables sont AS Solutions et l’Assureur, ce qu’acceptent expressément les personnes sur
lesquelles portent les données. Ces données pourront être utilisées par AS SOLUTIONS et l’assureur pour les besoins de
la gestion du Contrat. Ces données pourront être communiquées à des tiers pour satisfaire aux obligations légales et
réglementaires.
Vous pouvez demander communication et rectification de toute information Vous* concernant qui figurerait sur un ou
plusieurs de nos fichiers.`}
			</Text>
			<Text style={[styles.bodyText, styles.bold]}>
				{`En apposant ma signature au bas du présent document, je reconnais que les Dispositions Générales en vigueur à la date de souscription du devis et les Dispositions Particulières constitueront mon contrat d’assurance.`}
			</Text>
			<Text style={[styles.bodyText, styles.bold]}>
				{`Le contrat sera conclu pour une durée d’un an et se renouvellera par tacite reconduction chaque année à sa date
d'échéance principale sauf dénonciation par l'une des parties dans les conditions moyennant un préavis de 3 mois
et dans les cas prévus aux Dispositions Générales.`}
			</Text>
			<Text style={[styles.bodyText, styles.mb_10]}>
				{`En cas de retrait de l’assureur pour quelque motif que ce soit, j’autorise As solutions et ses représentants, dans la
mesure où les nouvelles garanties ne sont pas moins favorables que celles portées sur le contrat, à déplacer les
garanties auprès d’un nouvel assureur choisi par ses soins. `}
			</Text>
			<Text style={[styles.bodyText, styles.bold]}>
				{`Le souscripteur s'engage à transmettre AS Solutions dans les 30 jours suivants la date d'effet :`}
			</Text>
			<Text style={[styles.bodyText, styles.mb_10]}>
				{`• Le mandat de prélèvement joint, complété, daté et signé par vos soins, accompagné d'un RIB, nécessaire au
paiement des prochaines échéances.`}
			</Text>
			<Text style={[styles.bodyText, styles.mb_10]}>
				{`• Un exemplaire des Dispositions Particulières signées et paraphées par ses soins.`}
			</Text>
			<Text style={[styles.bodyText, styles.mb_10]}>
				Quelle que soit la périodicité de règlement choisie par le souscripteur,
				elle ne représente qu’une facilité de paiement et la prime annuelle d’un
				montant de{" "}
				{(
					props.totalInsurancePremium +
					props.managementFees +
					props.releaseFees +
					props.fileFees
				).toFixed(2)}{" "}
				€ reste due dans son intégralité.
			</Text>
			<Text style={[styles.bodyText, styles.mb_10]}>
				Les présents devis sont établis en 2 exemplaires et sur {numberOfPages}{" "}
				pages.
			</Text>
			<Text style={[styles.bodyText, styles.mb_10]} break>
				Fait à Paris, le {new Date().toLocaleDateString()}
			</Text>
			<View style={styles.signatureContainer}>
				{/* Left Column */}
				<View style={styles.column}>
					<Text style={[styles.bodyText, styles.mb_10]}>
						Signature du souscripteur
					</Text>
					<Text style={[styles.bodyText, styles.mb_10]}>
						Précédée de la mention « Lu et approuvé »
					</Text>
					<View style={styles.signatureBox}></View>
				</View>

				{/* Center Column */}
				<View style={styles.column}>
					<Text
						style={[styles.bodyText, styles.mb_10]}
					>{`Signature AS Solutions pour l'Assureur`}</Text>
					<View style={styles.signatureBox}>
						<Image style={styles.signatureImage} src="/images/signature.png" />
					</View>
				</View>

				{/* Right Column */}
				<View style={[styles.column, { borderRight: "none" }]}>
					<Text style={[styles.bodyText, styles.mb_10]}>
						Signature du courtier
					</Text>
					<Text style={[styles.bodyText, styles.mb_10]}>Distributeur</Text>
					<View style={styles.signatureBox}></View>
				</View>
			</View>
		</>
	);
};

export default OtherDispositions;
