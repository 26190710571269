import type {
	EstablishmentDetailsPageDto,
	EstablishmentDto,
} from "@/pages/form/schemas";
import type { FieldValues, Path, UseControllerProps } from "react-hook-form";
import { useEffect, useMemo } from "react";
import { BuildingIcon } from "@/assets/BuildingIcon.tsx";
import { ActivitySelectCombobox } from "@/components/inputs/ActivitySelectCombobox";
// import { MultiSelect } from "@/components/inputs/MultiSelect";
import PriceInput from "@/components/inputs/PriceInput.tsx";
import { SelectInput } from "@/components/inputs/SelectInput.tsx";
import {
	AlertDialog,
	AlertDialogTrigger,
} from "@/components/ui/alert-dialog.tsx";
import { Card, CardContent, CardHeader } from "@/components/ui/card.tsx";
import { DeleteAlertDialogContent } from "@/pages/form/1/components/4/DeleteAlertDialog.tsx";
import { addSpacingBetweenThousandsToString, cn, trpc } from "@/utils";
import { useFormContext } from "react-hook-form";
import { FaBuilding } from "react-icons/fa6";
import { IoMdTrash } from "react-icons/io";
import { PiMapPinAreaFill } from "react-icons/pi";

import type {
	ActivityInputDto,
	EstablishmentOccupationStatusDto,
} from "@repos/mrp-dtos";

// import { ActivitySelectCombobox } from "@/components/inputs/ActivitySelectCombobox";

interface EstablishmentDetailsCardProps<T extends FieldValues>
	extends UseControllerProps<T> {
	establishment: EstablishmentDto;
	remove: () => void;
	isMainEstablishment?: boolean;
	surfaceAreaField: Path<T>;
	turnoverField: Path<T>;
	occupationalStatusField: Path<T>;
	employeesNumberField: Path<T>;
	operationalContentValueField: Path<T>;
	stockValueExclVatPurchase: Path<T>;
	contractualIndemnityLimitPerSqm: Path<T>;
	relatedActivityIdField: Path<T>;
	allActivities: ActivityInputDto[];
}

export function EstablishmentDetailsCard<T extends FieldValues>({
	establishment,
	remove,
	isMainEstablishment = false,
	surfaceAreaField,
	stockValueExclVatPurchase,
	turnoverField,
	occupationalStatusField,
	employeesNumberField,
	operationalContentValueField,
	contractualIndemnityLimitPerSqm,
	relatedActivityIdField,
	allActivities,
}: EstablishmentDetailsCardProps<T>) {
	const occupationStatusOptions = trpc.config.listOccupationTypes.useQuery();

	const { setValue } = useFormContext<T>();
	// const selectedActivityId = useWatch({ name: relatedActivityIdField });

	// Fetch turnover based on selected activity
	// const { data: activityTurnover } = useActivityTurnover(selectedActivityId);

	const totalTurnover = useMemo(() => {
		return allActivities.reduce((acc, activity) => {
			acc += activity.turnover ?? 0;
			return acc;
		}, 0);
	}, [allActivities]);

	// Set turnover field whenever activity turnover changes
	useEffect(() => {
		if (totalTurnover) {
			//@ts-expect-error "activityTurnover" is number
			setValue(turnoverField, totalTurnover);
		}
	}, [totalTurnover, setValue, turnoverField]);

	return (
		<Card className="flex w-full flex-col gap-4 rounded-2xl border-gray-200 bg-white">
			<CardHeader
				className={cn(
					"flex flex-row items-center justify-between rounded-2xl rounded-b-none border-b border-b-gray-200",
					isMainEstablishment ? "bg-[#023048]" : "bg-[#FCFCFC]",
				)}
			>
				<div className="company-infos flex items-center gap-4">
					<div
						className={cn(
							"flex h-12 w-12 items-center justify-center rounded-full",
							isMainEstablishment ? "bg-white" : "bg-[#2178B2]",
						)}
					>
						{isMainEstablishment ? (
							<FaBuilding className={cn("size-8 fill-[#023048]")} />
						) : (
							<BuildingIcon className={cn("size-7 fill-white")} />
						)}
					</div>
					<div className="flex flex-col gap-1">
						<p
							className={cn(
								"text-lg font-bold",
								isMainEstablishment ? "text-white" : "text-[#333333]",
							)}
						>
							{isMainEstablishment
								? "Siège social"
								: "Établissement secondaire"}{" "}
							| {addSpacingBetweenThousandsToString(establishment.siret)}
						</p>
						<p
							className={cn(
								+"text-sm",
								isMainEstablishment ? "text-white" : "text-[#595959]",
							)}
						>
							{establishment.postalCode} {establishment.city}
							{", "}
							{establishment.country}
						</p>
					</div>
				</div>
				<div className="buttons flex items-center gap-4">
					<AlertDialog>
						<AlertDialogTrigger asChild>
							<IoMdTrash className="size-6 fill-[#F70004]" />
						</AlertDialogTrigger>
						<DeleteAlertDialogContent
							onConfirm={remove}
							description="Êtes-vous certain de vouloir supprimer cet établissement? Cette
						action est irréversible."
						/>
					</AlertDialog>
					<PiMapPinAreaFill
						className={cn(
							"size-6",
							isMainEstablishment ? "fill-white" : "fill-[#333333]",
						)}
					/>
				</div>
			</CardHeader>
			<CardContent className="grid grid-cols-2 gap-x-6 gap-y-0 px-8">
				<ActivitySelectCombobox
					label="Activité(s) reliée(s)"
					className="col-span-2 xl:col-span-1"
					name={relatedActivityIdField}
					options={allActivities.map((activity) => ({
						id: activity.id,
						value: activity.id,
						label: activity.label,
					}))}
					required
				/>
				<PriceInput
					label="Superficie développée"
					name={surfaceAreaField}
					required
					endorment="m²"
				/>
				<PriceInput
					label="Chiffre d'affaires HT N-1 ou prévisionnel total de l’entreprise"
					name={turnoverField}
					required
					defaultValue={establishment.turnover}
					disabled
					endorment="€"
				/>
				<PriceInput
					label="Nombre de salariés (y compris dirigeant lors de l'étude)"
					name={employeesNumberField}
					required
				/>
				<SelectInput<
					EstablishmentDetailsPageDto,
					{
						label: string;
						value: EstablishmentOccupationStatusDto;
					}
				>
					label="Statut d’occupation"
					name={occupationalStatusField as Path<EstablishmentDetailsPageDto>}
					options={occupationStatusOptions.data || []}
					required
				/>
				<PriceInput
					label="Valeur du contenu professionnel d'exploitation"
					name={operationalContentValueField}
					required
					endorment="€"
					labelWithTooltip
					tooltipDescription="hors marchandises"
				/>
				<PriceInput
					label="Valeur du stock en valeur achat HT"
					name={stockValueExclVatPurchase}
					required
					endorment="€"
				/>
				<SelectInput<
					any,
					{
						value: string;
						label: string;
					}
				>
					label="Limite contractuelle d’indemnité du bâtiment au m²"
					name={contractualIndemnityLimitPerSqm}
					options={[
						{ value: "800", label: "800 €" },
						{ value: "1500", label: "1 500 €" },
						{ value: "2500", label: "2 500 €" },
						{ value: "3500", label: "3 500 €" },
						{ value: "5000", label: "5 000 €" },
					]}
					required
					labelWithTooltip
					tooltipDescription="Limite Contractuelle d’Indemnité Globale = Limite Contractuelle d’Indemnité du Bâtiment au m² * Superficie + Valeur du Stock en valeur achat HT + Valeur du Contenu Professionnel d’Exploitation (hors marchandises) = max 9 999 999 € pour l’ensemble des sites"
				/>
			</CardContent>
		</Card>
	);
}
