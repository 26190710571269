import type { StateType } from "@/hooks/customNavigate.ts";
import type { InsuredEstablishmentsDto } from "@/pages/form/schemas";
import type {
	ChosenOptionsDto,
	FormStoreState,
} from "@/pages/form/stores/formStore.ts";
import type { FormRoutes } from "@/routes/formRoutes.tsx";
import type { WithNullableFields } from "@/utils";
import { getRelatedGuarantees } from "@/lib/GetRelatedGuarantees";
import { captureException } from "@sentry/react";
import { format } from "date-fns";

import type {
	DeductibleDto,
	PricingInputDto,
	PricingInputEstablishmentDto,
	SplittingOption,
} from "@repos/mrp-dtos";

export const formatPricingInputData = ({
	fileFees,
	navigate,
	formStoreData,
	deductible,
	splittingOption,
}: {
	fileFees: number;
	navigate: (route: keyof typeof FormRoutes, state?: StateType) => void;
	formStoreData: FormStoreState;
	deductible: DeductibleDto;
	splittingOption: SplittingOption;
}): PricingInputDto | void => {
	const nafCode = formStoreData.sirenPage.company?.nafCode.replace(/\./g, "");
	const companyCreationDate = formStoreData.sirenPage.company?.creationDate;
	const legalStatus = formStoreData.sirenPage.company?.legalStatus;

	if (!legalStatus || !nafCode || !companyCreationDate) {
		captureException(
			"legalStatus or legalStatus or companyCreationDate is undefined in OfferSummaryPage",
			{
				data: {
					legalStatus,
					nafCode,
					companyCreationDate,
					formStoreData,
				},
			},
		);

		navigate("SirenInputPage");
		return;
	}

	if (!formStoreData.establishments.insuredEstablishments) {
		captureException("insuredEstablishments is undefined in OfferSummaryPage", {
			data: {
				insuredEstablishments:
					formStoreData.establishments.insuredEstablishments,
				formStoreData,
			},
		});
		navigate("EtablissementsDetailsPage");
		return;
	}

	if (!formStoreData.chosenOptions) {
		captureException("chosenOptions is undefined in OfferSummaryPage", {
			data: {
				chosenOptions: formStoreData.chosenOptions,
				formStoreData,
			},
		});
		navigate("OptionalGuarantees");
		return;
	}

	const chosenOptions: WithNullableFields<ChosenOptionsDto> =
		formStoreData.chosenOptions;

	const insuredEstablishments: InsuredEstablishmentsDto =
		formStoreData.establishments.insuredEstablishments;

	const establishments: PricingInputEstablishmentDto =
		insuredEstablishments.map((establishment) => {
			const establishmentChosenOptions = chosenOptions.chosenOptions?.find(
				(option) =>
					establishment.relatedActivityId.includes(option.activityId) &&
					establishment.siret === option.establishmentId,
			);

			return {
				postalCode: Number(establishment.postalCode),
				city: establishment.city,
				siret: Number(establishment.siret),
				legalStatus,
				activityId: establishment.hightestActivityId || "",
				turnover: establishment.turnover,
				occupantTitle:
					establishment.occupationStatus === "Owner"
						? "Propriétaire"
						: "Locataire",
				area: establishment.surfaceArea,
				valuePerM2: establishment.contractualIndemnityLimitPerSqm,
				insuredStockValue: establishment.stockValueExclVatPurchase,
				insuredPropertyValue: establishment.operationalContentValue,
				relatedActivityGuarantees: getRelatedGuarantees(
					establishmentChosenOptions?.chosenOptions || [],
				),
			};
		});

	return {
		nafCode,
		companyCreationDate: format(companyCreationDate, "dd/MM/yyyy"),
		establishments,
		fileFees: fileFees,
		deductible: deductible,
		splittingOption: splittingOption,
	};
};
