import type { FieldValues, UseControllerProps } from "react-hook-form";
import TooltipIcon from "@/assets/TooltipIcon.svg";
import {
	FormField,
	FormItem,
	FormLabel,
	FormMessage,
} from "@/components/ui/form.tsx";
import { InputWithEndorment } from "@/components/ui/input-with-endorment";
import { cn } from "@/utils";
import { useFormContext } from "react-hook-form";
import { NumericFormat } from "react-number-format";

import {
	Tooltip,
	TooltipContent,
	TooltipProvider,
	TooltipTrigger,
} from "../ui/tooltip";

interface PriceInputProps<T extends FieldValues> extends UseControllerProps<T> {
	label: string;
	placeholder?: string;
	required?: boolean;
	defaultValue?: any;
	disabled?: boolean;
	customOnChange?: (value: number | undefined) => void;
	endorment?: string;
	labelWithTooltip?: boolean;
	tooltipDescription?: string;
}

export default function PriceInput<T extends FieldValues>({
	label,
	placeholder,
	name,
	required,
	defaultValue,
	disabled,
	endorment,
	customOnChange,
	labelWithTooltip,
	tooltipDescription,
}: PriceInputProps<T>) {
	const { control, setValue } = useFormContext<T>();

	return (
		<FormField
			name={name}
			control={control}
			render={({ field }) => (
				<FormItem className="flex w-full flex-col">
					<FormLabel className="flex items-end text-sm font-medium text-[#333333]">
						{labelWithTooltip && tooltipDescription ? (
							<TooltipProvider>
								<Tooltip>
									<span>
										{label}{" "}
										<TooltipTrigger type="button">
											<img
												src={TooltipIcon}
												className="size-3 cursor-pointer"
											/>
										</TooltipTrigger>
									</span>
									<TooltipContent>
										<div className="max-w-xs">{tooltipDescription}</div>
									</TooltipContent>
								</Tooltip>
							</TooltipProvider>
						) : (
							label
						)}
						{required && <span className="text-red-500">&nbsp;*</span>}
					</FormLabel>
					<NumericFormat
						className={cn(
							"w-full justify-between overflow-hidden overflow-ellipsis whitespace-nowrap rounded-md border-gray-300 bg-white p-5 font-normal text-black",
							!field.value && "text-muted-foreground",
						)}
						thousandSeparator=" "
						placeholder={placeholder}
						inputMode="decimal"
						value={field.value}
						onValueChange={({ floatValue }) => {
							setValue(name, floatValue as any);
							field.onChange(floatValue);
							customOnChange?.(floatValue);
						}}
						customInput={InputWithEndorment}
						defaultValue={defaultValue}
						disabled={disabled}
						endorment={endorment}
					/>
					<FormMessage />
				</FormItem>
			)}
		/>
	);
}
