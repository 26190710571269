import type {
	EstablishmentDetailsPageDto,
	NewEstablishmentDto,
} from "@/pages/form/schemas";
import { useCallback, useEffect, useState } from "react";
// import { toast } from "@/components/ui/use-toast";
import { useCustomFormNavigate } from "@/hooks/customNavigate.ts";
import { EstablishmentDetailsCard } from "@/pages/form/1/components/4/EstablishmentDetailsCard.tsx";
// import { TotalTurnoverDisplay } from "@/pages/form/1/components/4/TotalTurnoverDisplay.tsx";
import { establishmentDetailsPageSchema } from "@/pages/form/schemas";
import { formStore } from "@/pages/form/stores/formStore.ts";
import { trpc } from "@/utils";
import { zodResolver } from "@hookform/resolvers/zod";
import {
	FormProvider,
	useFieldArray,
	useForm,
	useWatch,
} from "react-hook-form";

import type { ActivityInputDto } from "@repos/mrp-dtos";

const getHighestTurnover = (activities: ActivityInputDto[]) => {
	if (activities.length === 0) {
		throw new Error("Activities array is empty");
	}

	let highestTurnover = 0;
	let highestActivityId = "";

	activities.forEach((current) => {
		if (current.turnover > highestTurnover) {
			highestTurnover = current.turnover;
			highestActivityId = current.id;
		}
	});
	return highestActivityId;
};

export const EstablishmentsDetailsPage = () => {
	const [errors, setErrors] = useState<{
		surfaceAreaErrors: string[];
		operationalContentValueErrors: string[];
		globalContractualIndemnityLimitErrors: string[];
		totalSurfaceArea: number;
		totalOperationalContentValue: number;
		globalContractualIndemnityLimit: number;
	}>({
		surfaceAreaErrors: [],
		operationalContentValueErrors: [],
		globalContractualIndemnityLimitErrors: [],
		totalSurfaceArea: 0,
		totalOperationalContentValue: 0,
		globalContractualIndemnityLimit: 0,
	});

	const navigate = useCustomFormNavigate();
	const {
		establishments,
		activityPage,
		setInsuredEstablishmentsDataWithoutSiretAndAddress,
		removeInsuredEstablishments,
		setHasDromComQuestions,
		numberOfClaimsPage,
		resetChosenOptions,
	} = formStore((state) => ({
		establishments: state.establishments,
		activityPage: state.activityPage,
		setInsuredEstablishmentsDataWithoutSiretAndAddress:
			state.setInsuredEstablishmentsDataWithoutSiretAndAddress,
		removeInsuredEstablishments: state.removeInsuredEstablishments,
		setHasDromComQuestions: state.setHasDromComQuestions,
		numberOfClaimsPage: state.numberOfClaimsPage,
		resetChosenOptions: state.resetChosenOptions,
	}));

	const mainEstablishmentPostalCode =
		establishments.mainEstablishment?.postalCode;

	const { data: dromComData } = trpc.config.listDeclarations.useQuery(
		{
			postalCodes: mainEstablishmentPostalCode
				? [mainEstablishmentPostalCode]
				: [],
		},
		{ enabled: !!mainEstablishmentPostalCode },
	);

	const handleNavigateNext = () => {
		// Check if DromComLocation exists and has questions
		const hasDromComQuestions =
			dromComData &&
			"DromComLocation" in dromComData &&
			Array.isArray(dromComData.DromComLocation) &&
			dromComData.DromComLocation.length > 0;

		if (hasDromComQuestions) {
			navigate("DromComDeclarationsPage");
			setHasDromComQuestions(true);
		} else {
			if (
				numberOfClaimsPage.numberOfClaims &&
				numberOfClaimsPage.numberOfClaims !== "ZERO"
			) {
				navigate("ClaimsPage");
			} else {
				navigate("OldDeclarationsPage");
			}
			setHasDromComQuestions(false);
		}
	};

	const methods = useForm<EstablishmentDetailsPageDto>({
		defaultValues: {
			insuredEstablishments: establishments.insuredEstablishments,
			// TODO: check if this is needed at all ??
			totalInsuredEstablishmentsTurnover: 0,
		},
		resolver: zodResolver(
			establishmentDetailsPageSchema.omit({ mainEstablishment: true }),
		),
	});

	const { control, handleSubmit } = methods;

	const onSubmit = useCallback(
		(data) => {
			//compare if the establishments Siret are the same
			if (
				establishments.insuredEstablishments !== undefined &&
				JSON.stringify(
					data.insuredEstablishments.map((e) => ({ siret: e.siret })),
				) !==
					JSON.stringify(
						//attributes must be in the same order or it will not work
						establishments.insuredEstablishments.map((e) => ({
							siret: e.siret,
						})),
					)
			) {
				resetChosenOptions();
			}

			const globalContractualIndemnityLimitErrors: string[] = [];
			const globalContractualIndemnityLimit = data.insuredEstablishments.reduce(
				(acc, est) =>
					acc +
					(est.contractualIndemnityLimitPerSqm || 0) * (est.surfaceArea || 0) +
					(est.stockValueExclVatPurchase || 0) +
					(est.operationalContentValue || 0),
				0,
			);

			if (globalContractualIndemnityLimit > 9999999) {
				globalContractualIndemnityLimitErrors.push(
					"Global contractual indemnity limit exceeds the limit of €9,999,999.",
				);
			}

			setErrors((prevErrors) => ({
				...prevErrors,
				globalContractualIndemnityLimitErrors,
				globalContractualIndemnityLimit,
			}));

			if (
				globalContractualIndemnityLimitErrors.length === 0 &&
				errors.surfaceAreaErrors.length === 0 &&
				errors.operationalContentValueErrors.length === 0
			) {
				const getEstablishmentActivities = (activityIds: string[]) => {
					return allActivities.filter((activity) => {
						return activityIds.includes(activity.id);
					});
				};
				const newData: NewEstablishmentDto[] = data.insuredEstablishments.map(
					(value) => {
						const highestActivityId = getHighestTurnover(
							getEstablishmentActivities(value.relatedActivityId),
						);
						return {
							...value,
							hightestActivityId: highestActivityId,
						} as NewEstablishmentDto;
					},
				);

				setInsuredEstablishmentsDataWithoutSiretAndAddress(newData);

				handleNavigateNext();
			}
		},
		[
			errors,
			setInsuredEstablishmentsDataWithoutSiretAndAddress,
			handleNavigateNext,
			resetChosenOptions,
		],
	);

	const watchedEstablishments = useWatch({
		control,
		name: "insuredEstablishments",
	});

	useEffect(() => {
		let totalSurfaceArea = 0;
		let totalOperationalContentValue = 0;

		watchedEstablishments.forEach((establishment) => {
			totalSurfaceArea += establishment.surfaceArea || 0;
			totalOperationalContentValue +=
				establishment.operationalContentValue || 0;
		});

		const surfaceAreaErrors =
			totalSurfaceArea > 5000
				? ["Total surface area exceeds the limit of 5 000 m²."]
				: [];
		const operationalContentValueErrors =
			totalOperationalContentValue > 2000000
				? ["Total operational content value exceeds the limit of €2,000,000."]
				: [];

		setErrors((prevErrors) => ({
			...prevErrors,
			surfaceAreaErrors,
			operationalContentValueErrors,
			totalSurfaceArea,
			totalOperationalContentValue,
		}));
	}, [watchedEstablishments]);

	const { fields, remove, move } = useFieldArray({
		name: "insuredEstablishments",
		control: control,
	});
	//make sure that the main establishment is always the first element in the array
	useEffect(() => {
		if (fields.length > 1) {
			const mainEstablishmentIndex = fields.findIndex(
				(establishment) =>
					establishment.siret === establishments.mainEstablishment?.siret,
			);
			if (mainEstablishmentIndex > 0) {
				move(mainEstablishmentIndex, 0);
			}
		}
	}, [fields, establishments.mainEstablishment, move]);
	const allActivities: ActivityInputDto[] = [
		activityPage.principalActivity!,
		...(activityPage.secondaryActivities as ActivityInputDto[]),
	];

	return (
		<FormProvider {...methods}>
			<form
				onSubmit={handleSubmit(onSubmit)}
				id="form"
				className="flex w-full flex-col gap-6"
			>
				<h1>Établissements à assurer</h1>
				{fields.map((establishment, index) => (
					<EstablishmentDetailsCard<EstablishmentDetailsPageDto>
						key={establishment.siret}
						establishment={establishment}
						name={`insuredEstablishments.${index}`}
						surfaceAreaField={`insuredEstablishments.${index}.surfaceArea`}
						turnoverField={`insuredEstablishments.${index}.turnover`}
						occupationalStatusField={`insuredEstablishments.${index}.occupationStatus`}
						employeesNumberField={`insuredEstablishments.${index}.numberOfEmployees`}
						operationalContentValueField={`insuredEstablishments.${index}.operationalContentValue`}
						stockValueExclVatPurchase={`insuredEstablishments.${index}.stockValueExclVatPurchase`}
						contractualIndemnityLimitPerSqm={`insuredEstablishments.${index}.contractualIndemnityLimitPerSqm`}
						relatedActivityIdField={`insuredEstablishments.${index}.relatedActivityId`}
						allActivities={allActivities}
						remove={() => {
							removeInsuredEstablishments(establishment.siret);
							remove(index);
						}}
						isMainEstablishment={
							establishments.mainEstablishment?.siret === establishment.siret
						}
					/>
				))}
				{fields.length > 1 && (
					<>
						{errors.surfaceAreaErrors.length > 0 && (
							<div
								className="relative rounded border border-red-400 bg-red-100 px-4 py-3 text-red-700"
								role="alert"
							>
								<span className="block sm:inline">
									La valeur maximale de la superficie totale développée = 5 000
									m².
								</span>
							</div>
						)}
						{errors.operationalContentValueErrors.length > 0 && (
							<div
								className="relative rounded border border-red-400 bg-red-100 px-4 py-3 text-red-700"
								role="alert"
							>
								<span className="block sm:inline">
									La valeur maximale de la valeur du contenu opérationnel = 2
									000 000 €.
								</span>
							</div>
						)}
					</>
				)}
				{errors.globalContractualIndemnityLimitErrors.length > 0 && (
					<div
						className="relative rounded border border-red-400 bg-red-100 px-4 py-3 text-red-700"
						role="alert"
					>
						<span className="block sm:inline">
							La Limite Contractuelle d&apos;Indemnité Globale [= Limite
							Contractuelle d&apos;Indemnité du Bâtiment au m² * Superficie +
							Valeur du Stock en valeur achat HT + Valeur du Contenu
							Professionnel d&apos;Exploitation (hors marchandises) ] ne doit
							pas dépasser 9 999 999 € pour l&apos;ensemble des sites.
						</span>
					</div>
				)}
			</form>
		</FormProvider>
	);
};
