import type { ReactNode } from "react";
import * as React from "react";
import { cn } from "@/utils";

export interface InputProps
	extends React.InputHTMLAttributes<HTMLInputElement> {
	startAdornment?: ReactNode;
	endAdornment?: ReactNode;
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
	({ className, type, startAdornment, endAdornment, ...props }, ref) => {
		const hasAdornment = Boolean(startAdornment) || Boolean(endAdornment);
		return (
			<>
				{hasAdornment ? (
					<div
						className={cn(
							"flex h-10 items-center justify-between gap-2 border border-input bg-transparent px-3 ring-offset-background focus-within:ring-1 focus-within:ring-ring focus-within:ring-offset-2 data-[disabled=true]:cursor-not-allowed data-[disabled=true]:opacity-50",
							"w-full justify-between overflow-hidden overflow-ellipsis whitespace-nowrap rounded-md border-gray-300 bg-white p-5 font-normal text-black",
						)}
						data-disabled={props.disabled}
					>
						{startAdornment && (
							<div className={cn("text-muted-foreground")}>
								{startAdornment}
							</div>
						)}
						<input
							type={type}
							className={cn(
								"flex h-10 w-full rounded-md border-none bg-transparent py-2 shadow-none outline-none file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:border-none focus-visible:shadow-none focus-visible:outline-none",
							)}
							ref={ref}
							{...props}
						/>
						{endAdornment && (
							<div className={cn("text-muted-foreground")}>{endAdornment}</div>
						)}
					</div>
				) : (
					<input
						type={type}
						className={cn(
							"flex h-5 w-full border border-input bg-transparent p-0 px-4 py-0 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:cursor-not-allowed disabled:opacity-50",
							"w-full justify-between overflow-hidden overflow-ellipsis whitespace-nowrap rounded-md border-gray-300 bg-white p-5 font-normal text-black",
							className,
						)}
						ref={ref}
						{...props}
					/>
				)}
			</>
		);
	},
);
Input.displayName = "Input";

export { Input };
