import type { PricingInputEstablishmentDto } from "@repos/mrp-dtos";

export const getRelatedGuarantees = (
	establishmentChosenOptions: {
		guaranteeType: string;
		guaranteeValue: number;
	}[],
): PricingInputEstablishmentDto[number]["relatedActivityGuarantees"] => {
	const guaranteeMapping = [
		{ key: "waterDamage", type: "DDE" },
		{ key: "glassBreakage", type: "BDG" },
		{ key: "electricalDamage", type: "DEL" },
		{ key: "theft", type: "VOL" },
		{ key: "refrigeratedGoods", type: "MEF" },
		{ key: "externalInstallation", type: "EEX" },
		{ key: "fairsAndEvents", type: "SFM" },
		{ key: "collapse", type: "EFFONDREMENT" },
		{ key: "machineBreakage", type: "BDM" },
		{ key: "legalDefense", type: "DPR" },
		{ key: "civilLiabilityOperational", type: "RCE" },
		{ key: "civilLiabilityProfessional", type: "RCPRO" },
		{ key: "legalProtection", type: "PJ" },
		{ key: "lossOfCommercialValue", type: "PVF" },
		{ key: "transportedGoods", type: "MMT" },
		{ key: "businessInterruptionFire", type: "PE_INCENDIE" },
		{ key: "businessInterruptionTheft", type: "PE_VOL" },
	];

	const results = guaranteeMapping.reduce(
		(acc, { key, type }) => {
			const option = establishmentChosenOptions.find(
				(opt) => opt.guaranteeType === type,
			);

			acc[key] = {
				subscribed: !!option,
				insuredCapital: option?.guaranteeValue || 0,
			};
			return acc;
		},
		{} as Record<
			keyof PricingInputEstablishmentDto[number]["relatedActivityGuarantees"],
			{ subscribed: boolean; insuredCapital: number }
		>,
	);

	results.legalDefense = {
		subscribed:
			results.civilLiabilityOperational.subscribed ||
			results.civilLiabilityProfessional.subscribed,
		insuredCapital: results.legalDefense.insuredCapital,
	};

	return results;
};
