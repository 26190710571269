import { useState } from "react";
import { HeaderFooter } from "@/components/quotePdf/HeaderFooter.tsx";
import LegalMentions from "@/components/quotePdf/LegalMentions.tsx";
import OtherDispositions from "@/components/quotePdf/OtherDispositions.tsx";
import { styles } from "@/components/quotePdf/styles.ts";
import Subscription from "@/components/quotePdf/Subscription.tsx";
import WarrantySummary from "@/components/quotePdf/WarrantySummary.tsx";
import { Page0 } from "@/pages/devis/0";
import { Page1 } from "@/pages/devis/1";
import { InsuredEstablishmentsAndActivitiesPdfPage } from "@/pages/devis/2/InsuredEstablishmentsAndActivitiesPdfPage.tsx";
import { InsuredEstablishmentsPdfPage } from "@/pages/devis/4/InsuredEstablishmentsPdfPage.tsx";
import { DeclarationsPdfPage } from "@/pages/devis/5/DeclarationsPdfPage.tsx";
import { GuaranteesAndOptionsPdfPage } from "@/pages/devis/6/GuaranteesAndOptionsPdfPage.tsx";
import { addSpacingBetweenThousandsToString, trpc } from "@/utils";
import {
	Document,
	Font,
	Page,
	PDFViewer,
	Text,
	View,
} from "@react-pdf/renderer";
import { useNavigate } from "react-router-dom";

import type { SubscriptionDetailsForQuoteDto } from "@repos/mrp-dtos";

import RobotoFontBold from "/fonts/Roboto-Bold.ttf";
import RobotoFontMedium from "/fonts/Roboto-Medium.ttf";
import RobotoFont from "/fonts/Roboto-Regular.ttf";

Font.register({
	family: "Roboto",
	fonts: [
		{
			src: RobotoFont,
			fontWeight: 400,
		},
		{
			src: RobotoFontMedium,
			fontWeight: 500,
		},
		{
			src: RobotoFontBold,
			fontWeight: 700,
		},
	],
});

const MainDocument = ({ ...props }: SubscriptionDetailsForQuoteDto) => {
	const [numPages, setNumPages] = useState(0);
	return (
		<Document
			onRender={(data) => {
				// @ts-expect-error property does exist
				setNumPages(data._INTERNAL__LAYOUT__DATA_.children.length);
			}}
		>
			<Page0 {...props} />
			<Page1 {...props} />
			<InsuredEstablishmentsAndActivitiesPdfPage {...props} />
			<InsuredEstablishmentsPdfPage {...props} />
			<DeclarationsPdfPage {...props} />
			<GuaranteesAndOptionsPdfPage {...props} />
			<Page size="A4" style={styles.page}>
				<HeaderFooter />
				<View style={styles.container}>
					<WarrantySummary {...props} />
					<View
						style={{
							display: "flex",
							flexDirection: "column",
							gap: 10,
							marginBottom: 30,
						}}
					>
						<Text style={[styles.bodyText, styles.medium]}>*indice FFB</Text>
					</View>
					<Text style={styles.sectionHeader}>LES FRANCHISES</Text>
					<Text style={[styles.bodyText]}>
						Vous avez sélectionné la Franchise Générale :{" "}
						{addSpacingBetweenThousandsToString(
							Number(props.deductible).toFixed(2),
						)}
						{" €."}
					</Text>
					<Text style={[styles.bodyText]}>
						Toutefois, si une franchise différente figure au « Tableau
						Récapitulatif des montants de garanties et de franchises » des
						Dispositions Générales ou dans une annexe jointe à votre contrat,
						c’est cette dernière qui s’applique.
					</Text>
					<Text style={[styles.bodyText]}>
						Pour les sinistres « Catastrophes naturelles », vous conservez à
						votre charge une franchise dont le montant est fixé par Arrêté.
					</Text>
					<Text style={[styles.sectionHeader, styles.mt_20]}>
						CLAUSE DE RÉVISION
					</Text>
					<Text style={[styles.bodyText]}>
						Le souscripteur s’engage à fournir annuellement à As Solutions les
						éléments de révision suivant :
					</Text>
					<Text style={[styles.bodyText]}>- La surface des bâtiments</Text>
					<Text style={[styles.bodyText]}>- Le chiffre d’affaires</Text>
					<Text style={[styles.bodyText]}>- L’effectif</Text>
					<Text style={[styles.bodyText]}>
						Le souscripteur s’engage à informer la compagnie dans les 15 jours
						suivant la modification du risque.
					</Text>
					<View break />
					<Subscription {...props} />
					<View break />
					<Text style={[styles.sectionHeader, styles.mb_150]}>
						CLAUSE AU PRESENT CONTRAT :
					</Text>
					{/*empty space*/}
					{/*<Text style={[styles.bodyText, styles.medium]}>*/}
					{/*	Clause d’exclusion d’activité (référence 200)*/}
					{/*</Text>*/}
					{/*<Text style={styles.bodyText}>*/}
					{/*	S’il y a pluralité d’activités et que l’une d’elle soit exclue, une*/}
					{/*	clause d’exclusion de celle -ci sera indiquée*/}
					{/*</Text>*/}
					{/*<Text style={[styles.bodyText, styles.medium]}>*/}
					{/*	Clause d’exclusion de garantie (référence 300)*/}
					{/*</Text>*/}
					{/*<Text style={styles.bodyText}>*/}
					{/*	Cette clause sera utilisée dans le cas où nous refuserions une*/}
					{/*	garantie spécifique liée au risque.*/}
					{/*</Text>*/}
					{/*<Text style={[styles.bodyText, styles.medium]}>*/}
					{/*	Clause d’exclusion de sécurité (référence 400)*/}
					{/*</Text>*/}
					{/*<Text style={styles.bodyText}>*/}
					{/*	Cette clause sera utilisée dans le cadre d’une mise en conformité du*/}
					{/*	risque avec un délai imparti.*/}
					{/*</Text>*/}
					{/*<Text style={[styles.bodyText, styles.medium]}>*/}
					{/*	Clause texte libre (référence 500)*/}
					{/*</Text>*/}
					{/*<Text style={styles.bodyText}>*/}
					{/*	Cette clause sera utilisée de manière exceptionnelle pour*/}
					{/*	l’aménagement de conditions d’acceptation ou de refus du risque ou de*/}
					{/*	partie du risque*/}
					{/*</Text>*/}
					<LegalMentions {...props} />
					<OtherDispositions numberOfPages={numPages} {...props} />
				</View>
			</Page>
		</Document>
	);
};

export const DevisPdfViewer = () => {
	const searchParams = new URLSearchParams(window.location.search);
	const subscriptionId = searchParams.get("subscription_id");
	const navigate = useNavigate();
	if (!subscriptionId) {
		navigate("/404");
	}
	const getSubscriptionPdf = trpc.quote.getQuoteDetails.useQuery({
		subscriptionId: subscriptionId!,
	});
	const data = getSubscriptionPdf.data;
	return (
		<PDFViewer className="h-[100vh] w-[100vw]">
			{data && <MainDocument {...data} />}
		</PDFViewer>
	);
};
export default DevisPdfViewer;
