import type { FieldValues, UseControllerProps } from "react-hook-form";
import { TextInput } from "@/components/inputs/TextInput.tsx";
import {
	Dialog,
	DialogClose,
	DialogContent,
	DialogHeader,
	DialogTitle,
	DialogTrigger,
} from "@/components/ui/dialog";
import {
	FormField,
	FormItem,
	FormLabel,
	FormMessage,
} from "@/components/ui/form.tsx";
import { InputWithEndorment } from "@/components/ui/input-with-endorment";
import { cn } from "@/utils";
import { X } from "lucide-react";
import { useFormContext } from "react-hook-form";
import { NumericFormat } from "react-number-format";

interface GuaranteePriceInputProps<T extends FieldValues>
	extends UseControllerProps<T> {
	label: string;
	placeholder?: string;
	required?: boolean;
	disabled?: boolean;
	// TODO REMOVE ANY
	defaultValue?: any;
	endorment?: string;
	isOnlyPlaceholder?: boolean;
}

export default function GuaranteePriceInput<T extends FieldValues>({
	label,
	placeholder,
	name,
	required,
	defaultValue,
	disabled,
	endorment,
	isOnlyPlaceholder,
}: GuaranteePriceInputProps<T>) {
	const { control, setValue } = useFormContext<T>();
	if (isOnlyPlaceholder) {
		return (
			<TextInput
				label={label}
				name={"none"}
				placeholder={placeholder}
				inputClassName="disabled:opacity-90 placeholder-gray-800 placeholder:text-gray-900"
				disabled
			/>
		);
	}
	return (
		<FormField
			name={name}
			control={control}
			render={({ field }) => (
				<FormItem className="flex w-full flex-col">
					<FormLabel className="flex items-end text-sm font-medium text-[#333333]">
						<span>{label}</span>

						{required && <span className="text-red-500">&nbsp;*</span>}
						{label.includes("Vol") && (
							<Dialog>
								<DialogTrigger>
									<span className="ml-1 text-[#598EAD] underline">
										Plus de détails
									</span>
								</DialogTrigger>
								<DialogContent>
									<DialogHeader className="flex flex-row items-center justify-between">
										<DialogTitle className="text-primary">
											Tableau de la garantie Vol
										</DialogTitle>
										<DialogClose>
											<X className="size-6" />
										</DialogClose>
									</DialogHeader>
									<table className="mt-4 w-full rounded-sm">
										<thead>
											<tr className="bg-gray-100">
												<th className="p-3 text-left font-semibold">
													Niveau garantie
												</th>
												<th className="p-3 text-left font-semibold">
													Montant plafond
												</th>
												<th className="p-3 text-left font-semibold">
													Mesures de Prévention
												</th>
											</tr>
										</thead>
										<tbody>
											<tr className="bg-white">
												<td className="p-3">1</td>
												<td className="p-3">{`< 40000 €`}</td>
												<td className="p-3">
													<ul className="list-disc">
														<li>
															Pour les portes d`&apos;accès : 2 systèmes de
															fermeture dont un au moins de sûreté
														</li>
													</ul>
												</td>
											</tr>
											<tr className="bg-[#F2F4F6]">
												<td className="p-3" width="20%">
													2
												</td>
												<td
													className="p-3"
													width="30%"
												>{`>40 001€ < 80 000 €`}</td>
												<td className="p-3" width="50%">
													<ul className="list-disc">
														<li>
															Pour les parties vitrées (autre que les vitrines
															et devantures), soupiraux ou autres ouvertures
															accessibles : des barreaux ou grilles métalliques
															fixés dans la maçonnerie et espacés au maximum de
															12 cm
														</li>
														<li>
															Pour les vitrines ou devantures : rideaux ou
															grilles métalliques ou glaces antieffraction
														</li>
													</ul>
												</td>
											</tr>
											<tr className="bg-white">
												<td className="p-3">3</td>
												<td className="p-3">{`> 80 001 €`}</td>
												<td className="p-3">
													<ul className="list-disc">
														<li>
															Une installation d`&apos;alarme figure parmi ces
															moyens de protection, elle doit être enclenchée et
															vous vous engagez à respecter les prescriptions
															suivantes :
														</li>
													</ul>
												</td>
											</tr>
											<tr className="bg-[#F2F4F6]">
												<td className="p-3">4</td>
												<td className="p-3">{`Option si valeur > 1 500 €`}</td>
												<td className="p-3">
													<ul className="list-disc">
														<li>Un coffre-fort</li>
													</ul>
												</td>
											</tr>
										</tbody>
									</table>
								</DialogContent>
							</Dialog>
						)}
					</FormLabel>

					<NumericFormat
						className={cn(
							"w-full justify-between overflow-hidden overflow-ellipsis whitespace-nowrap rounded-md border-gray-300 bg-white p-5 font-normal text-black data-[disabled=true]:border-gray-400" +
								"placeholder-gray-800",
							!field.value && "text-muted-foreground",
						)}
						thousandSeparator=" "
						placeholder={placeholder}
						inputMode="decimal"
						value={typeof defaultValue === "number" ? field.value : ""}
						onValueChange={({ floatValue }) => {
							setValue(name, floatValue as any);
							field.onChange(floatValue);
						}}
						customInput={InputWithEndorment}
						disabled={disabled}
						endorment={endorment}
					/>
					<FormMessage />
				</FormItem>
			)}
		/>
	);
}
