import type { SirenPageDto } from "@/pages/form/schemas";
import { useEffect, useState } from "react";
import {
	FormControl,
	FormItem,
	FormLabel,
	FormMessage,
} from "@/components/ui/form.tsx";
import { EntrepriseAutoComplete } from "@/pages/form/1/components/1/EntrepriseAutoComplete.tsx";
import { formStore } from "@/pages/form/stores/formStore.ts";
import { cn, trpc } from "@/utils";
import { useFormContext } from "react-hook-form";
import { useDebounceValue } from "usehooks-ts";

import type {
	CompaniesInfoDto,
	CompanyInfoDto,
} from "@repos/mrp-dtos/src/dtos/company.dto.ts";

interface SirenInputProps {
	label: string;
	className?: string;
	required?: boolean;
	setNextButtonDisabled?: (value: boolean) => void;
}

export function CompanySearch({
	label,
	className,
	required,
	setNextButtonDisabled,
}: SirenInputProps) {
	const { setValue, getValues, reset } = useFormContext<SirenPageDto>();
	const { setMainEstablishmentSiret } = formStore((state) => ({
		setMainEstablishmentSiret: state.setMainEstablishmentSiret,
	}));
	const [inputValue, setInputValue] = useState<string>(
		getValues("company.siren") !== undefined
			? `${getValues("company.companyName")} | ${getValues("company.siren")}`
			: "",
	);
	const [companiesByNameData, setCompaniesByNameData] =
		useState<CompaniesInfoDto>([]);

	const SirenMutation = trpc.companies.getCompaniesInfoByName.useMutation({
		onSuccess: (data) => {
			setCompaniesByNameData(data);
		},
	});
	const [debouncedInputValue] = useDebounceValue(inputValue, 500);
	useEffect(() => {
		if (debouncedInputValue.length < 3) {
			return;
		}
		SirenMutation.mutate({ name: debouncedInputValue });
	}, [debouncedInputValue]);
	return (
		<FormItem className={cn("flex w-full flex-col", className)}>
			<FormLabel className="flex items-end font-medium text-[#333333]">
				<span>{label}</span>
				{required && <span className="text-red-500"> *</span>}
			</FormLabel>
			<FormControl className="flex justify-between">
				<EntrepriseAutoComplete<CompanyInfoDto>
					items={companiesByNameData}
					searchValue={inputValue}
					onSearchValueChange={(value) => {
						setInputValue(value);
					}}
					onSelectedValueChange={(value) => {
						if (!value) {
							reset();
							return;
						}
						setInputValue(`${value.completeName} | ${value.siren}`);
						if (setNextButtonDisabled) {
							setNextButtonDisabled(false);
						}
						//update the form data here
						setValue("company.siren", value.siren);

						setValue("company.companyName", value.completeName);
						setValue("company.creationDate", value.creationDate);
						setValue("company.nafCode", value.mainActivity.code);
						setValue("company.legalStatus", value.legalStatus);

						setValue(
							"mainEstablishmentAddress",
							value.headquarters.detailedAddress,
						);
						setValue("mainEstablishment.address", value.headquarters.address);
						setValue(
							"mainEstablishment.detailedAddress",
							value.headquarters.detailedAddress,
						);
						setValue("mainEstablishment.siret", value.headquarters.siret);
						setValue("mainEstablishment.isHeadquarters", true);
						setValue("mainEstablishment.siren", value.siren);

						setMainEstablishmentSiret(value.headquarters.siret);
					}}
					isLoading={SirenMutation.isLoading}
				/>
			</FormControl>
			<FormMessage />
		</FormItem>
	);
}
