import type { ReactNode } from "react";
import { forwardRef } from "react";
import * as React from "react";
import { cn } from "@/utils";

export interface InputProps
	extends React.InputHTMLAttributes<HTMLInputElement> {
	startAdornment?: ReactNode;
	endAdornment?: ReactNode;
	endorment?: string;
}

const InputWithEndorment = forwardRef<HTMLInputElement, InputProps>(
	({ className, endorment, type, ...props }, ref) => {
		return (
			<div
				className={cn(
					"flex h-10 items-center justify-between gap-2 rounded-md border border-input bg-transparent px-3 ring-offset-background focus-within:ring-1 focus-within:ring-ring focus-within:ring-offset-2 data-[disabled=true]:cursor-not-allowed data-[disabled=true]:opacity-90",
					"w-full justify-between overflow-hidden overflow-ellipsis whitespace-nowrap rounded-md border-gray-300 bg-white p-5 font-normal text-black",
					className,
				)}
				data-disabled={props.disabled}
			>
				<input
					type={type}
					className={cn(
						"flex h-10 w-full rounded-md border-none bg-transparent py-2 shadow-none outline-none file:bg-transparent file:text-sm file:font-medium placeholder:text-gray-700",
					)}
					ref={ref}
					{...props}
				/>
				{endorment && (
					<div className={cn("text-muted-foreground")}>{endorment}</div>
				)}
			</div>
		);
	},
);
InputWithEndorment.displayName = "InputWithEndorment";

export { InputWithEndorment };
