import type { FormRoutes } from "@/routes/formRoutes.tsx";
import type { Location } from "react-router-dom";
import { useEffect, useState } from "react";
import { useCustomFormNavigate } from "@/hooks/customNavigate.ts";
import { formStore } from "@/pages/form/stores/formStore.ts";
import { formRoutes } from "@/routes/formRoutes.tsx";
import { useLocation } from "react-router-dom";

interface FormStepWrapperProps {
	children: React.ReactNode;
	stepKey: keyof typeof FormRoutes;
}

type LocationState = {
	isSafe?: boolean;
};
export const FormStepWrapper = ({
	children,
	stepKey,
}: FormStepWrapperProps) => {
	const {
		currentStep,
		currentSubStep,
		setCurrentStep,
		setCurrentSubStep,
		setCurrentStepKey,
	} = formStore((state) => ({
		currentStep: state.currentStep,
		currentSubStep: state.currentSubStep,
		setCurrentStep: state.setCurrentStep,
		setCurrentSubStep: state.setCurrentSubStep,
		setCurrentStepKey: state.setCurrentStepKey,
	}));
	const location: Location<LocationState | undefined> = useLocation();
	const [loading, setLoading] = useState(true);
	const isSafe = location.state?.isSafe;
	const navigate = useCustomFormNavigate();
	useEffect(() => {
		if (isSafe) {
			setCurrentStep(formRoutes[stepKey].step);
			setCurrentSubStep(formRoutes[stepKey].subStep);
			setCurrentStepKey(stepKey);
		} else {
			//navigate to step that corresponds to the current step and current substep in formroutes
			const currentStepKey: keyof typeof FormRoutes =
				(Object.keys(formRoutes).find(
					(key) =>
						formRoutes[key].step === currentStep &&
						formRoutes[key].subStep === currentSubStep,
				) as keyof typeof FormRoutes) || "SirenInputPage";

			navigate(currentStepKey);
		}
		setLoading(false);
	}, [
		currentStep,
		currentSubStep,
		isSafe,
		navigate,
		setCurrentStep,
		setCurrentStepKey,
		setCurrentSubStep,
		stepKey,
	]);
	if (loading) {
		return <div>Loading...</div>;
	}
	return children;
};
