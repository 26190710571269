import type { QuoteDetailsDto } from "@/pages/form/schemas";
import { useCallback, useContext, useEffect, useMemo } from "react";
import PaiementInfoIcon from "@/assets/PaiementInfoIcon.svg";
import UserInfoIcon from "@/assets/UserInfoIcon.svg";
import { DateInput } from "@/components/inputs/DateInput/DateInput";
import { PhoneInput } from "@/components/inputs/PhoneInput";
import PriceInput from "@/components/inputs/PriceInput.tsx";
import { SelectInput } from "@/components/inputs/SelectInput";
import { TextInput } from "@/components/inputs/TextInput";
import { Button } from "@/components/ui/button";
import { Spinner } from "@/components/ui/Spinner.tsx";
import { toast } from "@/components/ui/use-toast.ts";
import { useCustomFormNavigate } from "@/hooks/customNavigate";
import { LayoutButtonsContext } from "@/layouts/Layout.tsx";
import { PricingSummaryTable } from "@/pages/form/5/components/PricingSummaryTable";
import { quoteSchema } from "@/pages/form/schemas";
import { formStore } from "@/pages/form/stores/formStore";
import { formatPricingInputData } from "@/pages/form/utils/formatPricingInputData";
import { trpc } from "@/utils";
import { zodResolver } from "@hookform/resolvers/zod";
import { addDays, addMonths, addYears, endOfYear, subYears } from "date-fns";
import { FormProvider, useForm, useWatch } from "react-hook-form";

import type {
	CivilityDto,
	DeductibleDto,
	PaymentMethodDto,
	SplitOptionDto,
} from "@repos/mrp-dtos";

export const QuotesPage = () => {
	const { quote, setQuote, resetPricingData } = formStore((state) => ({
		quote: state.quote,
		setQuote: state.setQuote,
		resetPricingData: state.resetPricingData,
	}));
	const { setNextButtonDisabled, setNextButtonLoading } =
		useContext(LayoutButtonsContext);

	const methods = useForm<QuoteDetailsDto>({
		defaultValues: quote,
		resolver: zodResolver(quoteSchema),
		reValidateMode: "onChange",
		mode: "onChange",
	});
	const navigate = useCustomFormNavigate();
	const fileFees = useWatch({
		control: methods.control,
		name: "fileFees",
	});

	const tarificationMutation = trpc.pricing.getPricing.useMutation({
		onSuccess: (data) => {
			methods.setValue("pricingData", data);
			setNextButtonDisabled(false);
		},
		onError: () => {
			toast({
				title: "Erreur",
				description: "Erreur lors de la tarification. Veuillez réessayer.",
				variant: "destructive",
				duration: 500,
			});
			setNextButtonDisabled(false);
		},
	});

	useEffect(() => {
		if (tarificationMutation.data) {
			setNextButtonDisabled(false);
		} else {
			setNextButtonDisabled(true);
		}
		return () => {
			setNextButtonDisabled(false);
			setNextButtonLoading(false);
		};
	}, [setNextButtonLoading, setNextButtonDisabled, tarificationMutation.data]);

	const formStoreData = formStore((state) => state);

	const EighteenYearsAgo = endOfYear(subYears(new Date(), 18));
	const onSubmit = (data: QuoteDetailsDto) => {
		setQuote(data);
		navigate("OfferSummaryPage");
	};
	const handleTarifier = async () => {
		const isValid = await methods.trigger([
			"desiredEffectiveDate",
			"splittingOption",
			"paymentMethod",
			"contractDueDate",
			"valeurFranchise",
			"fileFees",
			"commissionCourtier",
			"civiliteDirigeant",
			"nomDirigeant",
			"prenomDirigeant",
			"emailDirigeant",
			"dateNaissanceDirigeant",
			"telPortableDirigeant",
			"telFixeDirigeant",
		]);

		if (!isValid) {
			window.scrollTo({ top: 0 });
			// Find the first error and focus on its field
			const errors = methods.formState.errors;
			const firstErrorField = Object.keys(errors)[0];

			if (firstErrorField) {
				methods.setFocus(firstErrorField as keyof QuoteDetailsDto);
			}
			return;
		}

		if (pricingInputData) {
			tarificationMutation.mutate(pricingInputData);
		}
	};
	const splittingOption = useWatch({
		control: methods.control,
		name: "splittingOption",
	});
	const deductible = useWatch({
		control: methods.control,
		name: "valeurFranchise",
	});
	const pricingInputData = useMemo(
		() =>
			formatPricingInputData({
				fileFees,
				navigate,
				formStoreData: formStoreData,
				deductible: deductible,
				splittingOption: splittingOption,
			}),
		[fileFees, navigate, formStoreData, deductible],
	);
	const resetPricingDataCb = useCallback(() => {
		// @ts-expect-error the default value is undefined
		methods.setValue("pricingData", undefined);
		setNextButtonDisabled(true);
		resetPricingData();
		tarificationMutation.reset();
	}, [resetPricingData, setNextButtonDisabled, tarificationMutation]);

	const splittingOptionDivisor = useMemo(() => {
		switch (splittingOption) {
			case "Monthly":
				return 12;
			case "Quarterly":
				return 4;
			case "HalfYearly":
				return 2;
			case "Yearly":
				return 1;
			default:
				return 12;
		}
	}, [splittingOption]);

	return (
		<FormProvider {...methods}>
			<form id="form" onSubmit={methods.handleSubmit(onSubmit)}>
				<div className="mb-4 flex flex-col items-start justify-between gap-2 text-left">
					<h1>Devis</h1>
					<p>
						Vous devez renseignez les informations de paiement et du dirigeant
					</p>
				</div>
				<div className="mb-4 w-full rounded-lg border border-gray-200">
					<div className="flex items-center justify-start gap-2 border-b border-[#E4E4E4] bg-gray-50 p-4">
						<img src={PaiementInfoIcon} />
						<h2 className="text-xl font-semibold">Détails de paiement</h2>
					</div>
					<div className="space-y-4 p-4">
						<div className="grid grid-cols-1 gap-4 md:grid-cols-2">
							<DateInput<QuoteDetailsDto>
								label="Date d'effet du contrat"
								name="desiredEffectiveDate"
								minDate={addDays(new Date(), 0)}
								maxDate={addMonths(new Date(), 9)}
								defaultActiveStartDate={addDays(new Date(), 0)}
								onCustomChange={(value) => {
									if (value)
										methods.setValue("contractDueDate", addYears(value, 1));
									// @ts-expect-error the default value is undefined
									else methods.setValue("contractDueDate", undefined);
								}}
								required
							/>
							<SelectInput<
								QuoteDetailsDto,
								{ label: string; value: SplitOptionDto }
							>
								label="Fractionnement de la Prime"
								name="splittingOption"
								placeholder="Veuillez choisir le fractionnement de la prime"
								//Reset pricing data when the splitting option changes because the pricing data contains the total premium that depends on the splitting option to choose the divisor
								customOnChange={resetPricingDataCb}
								options={[
									{
										label: "Mensuel",
										value: "Monthly",
									},
									{
										label: "Trimestriel",
										value: "Quarterly",
									},
									{
										label: "Semestriel",
										value: "HalfYearly",
									},
									{
										label: "Annuel",
										value: "Yearly",
									},
								]}
								required
							/>
							<SelectInput<
								QuoteDetailsDto,
								{
									label: string;
									value: PaymentMethodDto;
								}
							>
								label="Mode de paiement"
								name="paymentMethod"
								placeholder="Veuillez choisir le mode de paiement"
								options={[
									{
										label: "Prélèvement",
										value: "Deduction",
									},
									{
										label: "Virement",
										value: "Transfer",
									},
									{
										label: "Carte Bancaire",
										value: "Card",
									},
									{
										label: "Chèque",
										value: "Cheque",
									},
								]}
								required
							/>
							<DateInput<QuoteDetailsDto>
								label="Date d'échéance du contrat"
								name="contractDueDate"
								required
								disabled
								showOnlyDayMonth
							/>
							<SelectInput<
								QuoteDetailsDto,
								{ label: string; value: DeductibleDto }
							>
								label="Valeur de la franchise"
								name="valeurFranchise"
								placeholder="Veuillez choisir la valeur de la franchise"
								customOnChange={resetPricingDataCb}
								options={[
									{
										label: "450 €",
										value: "450",
									},
									{
										label: "750 €",
										value: "750",
									},
									{
										label: "1 000 €",
										value: "1000",
									},
									{
										label: "1 500 €",
										value: "1500",
									},
									{
										label: "2 000 €",
										value: "2000",
									},
								]}
								required
							/>
							<PriceInput<QuoteDetailsDto>
								label="Frais de dossier Courtier à la souscription"
								name="fileFees"
								placeholder="Veuillez ajouter les frais"
								customOnChange={resetPricingDataCb}
								required
								endorment="€"
							/>
							<TextInput<QuoteDetailsDto>
								label="Commission courtier"
								name="commissionCourtier"
								placeholder="Veuillez ajouter la commission"
								type="number"
								endAdornment="%"
								defaultValue={20}
								required
								disabled
							/>
						</div>
					</div>
				</div>
				<div className="mb-4 w-full rounded-lg border border-gray-200">
					<div className="flex items-center justify-start gap-2 border-b border-[#E4E4E4] bg-gray-50 p-4">
						<img src={UserInfoIcon} />
						<h2 className="text-xl font-semibold">Informations du Dirigeant</h2>
					</div>
					<div className="space-y-4 p-4">
						<div className="grid grid-cols-1 gap-4 md:grid-cols-2">
							<SelectInput<
								QuoteDetailsDto,
								{ label: string; value: CivilityDto }
							>
								label="Civilité"
								name="civiliteDirigeant"
								placeholder="Veuillez choisir la civilité du dirigeant"
								options={[
									{
										label: "M.",
										value: "MR",
									},
									{
										label: "Mme.",
										value: "MRS",
									},
								]}
								required
							/>
							<TextInput<QuoteDetailsDto>
								label="Nom"
								name="nomDirigeant"
								placeholder="Veuillez entrer le nom du dirigeant"
								required
							/>
							<TextInput<QuoteDetailsDto>
								label="Prénom"
								name="prenomDirigeant"
								placeholder="Veuillez entrer le prénom du dirigeant"
								required
							/>
							<TextInput<QuoteDetailsDto>
								label="E-mail"
								name="emailDirigeant"
								placeholder="Veuillez entrer l’email du dirigeant"
								type="email"
								required
							/>
							<DateInput<QuoteDetailsDto>
								label="Date de naissance"
								name="dateNaissanceDirigeant"
								placeholder="Veuillez entrer la date de naissance du dirigeant"
								required
								maxDate={EighteenYearsAgo}
								minDate={subYears(EighteenYearsAgo, 120)}
							/>
							<PhoneInput<QuoteDetailsDto>
								label="Téléphone portable"
								name="telPortableDirigeant"
								placeholder="Veuillez entrer le numéro du dirigeant"
								required
							/>
							<PhoneInput<QuoteDetailsDto>
								label="Téléphone fixe"
								name="telFixeDirigeant"
							/>
						</div>
					</div>
				</div>
				<div className="mb-6 w-full text-right">
					<Button
						className="min-w-32 gap-4 py-5 text-white"
						type="button"
						onClick={handleTarifier}
						disabled={tarificationMutation.isLoading}
					>
						{tarificationMutation.isLoading ? (
							<Spinner className="text-white" size="small" />
						) : (
							"Tarifier"
						)}
					</Button>
				</div>
				{(quote.pricingData || tarificationMutation.data) && (
					<PricingSummaryTable
						splittingOption={splittingOption}
						pricingData={tarificationMutation.data}
						splittingOptionDivisor={splittingOptionDivisor}
					/>
				)}
			</form>
		</FormProvider>
	);
};
