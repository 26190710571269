import type { GuaranteeOptionalType } from "@/constants";

export const getPlaceholder = (
	guaranteeType: GuaranteeOptionalType,
): string => {
	switch (guaranteeType) {
		case "DDE":
			return "Min 1 € /Max = 50 % du contenu professionnel total";
		case "BDG":
			return "Min 1 € / Max 50 000 €";
		case "VOL":
			return "Min 1 € /Max = 50 % du contenu professionnel total ";
		case "MEF":
			return "Min 1 € / Max 10 000 €";
		case "BDM":
			return "Min 1 € / Max 100 000 €";
		case "MMT":
			return "Min 1 € / Max 5 000 €";
		case "DEL":
			return "Min 1 € / Max 100 000 €";
		case "EEX":
			return "Min 1 € / Max 100 000 €";
		case "SFM":
			return "6 fois l'indice FFB";
		case "EFFONDREMENT":
			return "1 500 000";
		case "PJ":
			return "Max 25 000 € par sinistre";
		default:
			return "";
	}
};
