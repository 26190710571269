import type { ActivityPageInputDto } from "@/pages/form/schemas";
import { useMemo } from "react";
import { addSpacingBetweenThousandsToNumberWithDecimals, cn } from "@/utils";
import { useWatch } from "react-hook-form";

export const TotalTurnoverDisplay = () => {
	let totalTurnover = 0;
	const principalActivity = useWatch<ActivityPageInputDto>({
		name: "principalActivity",
	}) as ActivityPageInputDto["principalActivity"];
	const secondaryActivities = useWatch<ActivityPageInputDto>({
		name: "secondaryActivities",
	}) as ActivityPageInputDto["secondaryActivities"];
	totalTurnover = useMemo(() => {
		let totalTurnover = 0;
		if (principalActivity) {
			totalTurnover += principalActivity.turnover;
		}
		if (secondaryActivities) {
			secondaryActivities.forEach((activity) => {
				totalTurnover += activity.turnover ? activity.turnover : 0;
			});
		}
		return totalTurnover;
	}, [principalActivity, secondaryActivities]);

	return (
		<div className="flex w-full flex-col p-4">
			<div className="flex w-full flex-col items-center justify-between">
				<p className={cn("text-lg font-bold text-[#333333]")}>
					Chiffre d’affaires HT N-1 ou prévisionnel total de l’entreprise
				</p>
				<div className="flex w-full rounded-lg border border-gray-200 p-4">
					<p className={cn("text-lg font-bold text-primary")}>
						{addSpacingBetweenThousandsToNumberWithDecimals(totalTurnover || 0)}{" "}
						€
					</p>
				</div>
			</div>
		</div>
	);
};
