import { formStore } from "@/pages/form/stores/formStore.ts";
import { formRoutes } from "@/routes/formRoutes.tsx";
import { cn } from "@/utils";
import { Check } from "lucide-react";

export const Stepper = () => {
	const currentStep = formStore((state) => state.currentStep);

	const uniqueSteps = Object.values(formRoutes).reduce(
		(acc: (typeof formRoutes)["ActivityPage"][], curr) => {
			if (!acc.find((step) => step.step === curr.step)) {
				acc.push(curr);
			}
			return acc;
		},
		[]
	);

	const stepsLength = uniqueSteps.length;

	return (
		<div className="mx-auto h-fit max-w-md rounded-2xl bg-white p-12">
			<ol className={cn("relative")}>
				{uniqueSteps.map((step, index) => (
					<li key={step.step} className="mb-8 ml-6">
						<div>
							{index !== stepsLength - 1 && (
								<div
									className={cn(
										"absolute -left-2 h-[100px] w-[2px] bg-gray-200",
										index < currentStep - 1 ? "bg-primary" : "bg-gray-400"
									)}
								/>
							)}
							<span
								className={cn(
									"absolute -left-8 flex size-12 items-center justify-center rounded-full p-3 text-lg ring-[10px] ring-white",
									index < currentStep - 1
										? "bg-green-500 text-white" // Completed steps
										: index === currentStep - 1
											? "bg-primary text-white" // Current step
											: "border-2 border-gray-500 bg-white text-gray-500" // Future steps
								)}
							>
								{index < currentStep - 1 ? (
									<Check className="h-6 w-6" />
								) : (
									String(index + 1).padStart(2, "0")
								)}
							</span>
							<h3
								className={`text-lg font-medium leading-tight ${index === currentStep - 1 ? "text-[#333333]" : "text-gray-500"
									}`}
							>
								{step.title}
							</h3>
							<p
								className={cn(
									"text-sm text-gray-500",
									index === currentStep - 1
										? "text-[#595959]"
										: "text-[#757575]"
								)}
							>
								{step.description}
							</p>
						</div>
					</li>
				))}
			</ol>
		</div>
	);
};

export default Stepper;