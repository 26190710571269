import {
	AlertDialogAction,
	AlertDialogCancel,
	AlertDialogContent,
	AlertDialogDescription,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogTitle,
} from "@/components/ui/alert-dialog.tsx";
import { cn } from "@/utils";
import { IoMdTrash } from "react-icons/io";

interface DeleteAlertDialogProps {
	onConfirm: () => void;
	description: string;
}

export const DeleteAlertDialogContent = ({
	onConfirm,
	description,
}: DeleteAlertDialogProps) => {
	return (
		<AlertDialogContent>
			<AlertDialogHeader className="flex flex-row items-center gap-3">
				<div
					className={cn(
						"flex max-h-12 min-w-12 items-center justify-center rounded-full bg-[#FFDDDD] p-3",
					)}
				>
					<IoMdTrash className={cn("size-5 fill-[#F70004]")} />
				</div>
				<div className="flex flex-col justify-center">
					<AlertDialogTitle className="text-[#F70004]">
						Confirmer la suppression
					</AlertDialogTitle>
					<AlertDialogDescription>{description}</AlertDialogDescription>
				</div>
			</AlertDialogHeader>
			<AlertDialogFooter>
				<AlertDialogCancel className="border-[#333333] text-[#333333]">
					Annuler
				</AlertDialogCancel>
				<AlertDialogAction className="bg-[#F70004]" onClick={onConfirm}>
					Oui, supprimer
				</AlertDialogAction>
			</AlertDialogFooter>
		</AlertDialogContent>
	);
};
