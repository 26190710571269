import { useCallback, useContext, useEffect, useState } from "react";
import { Button } from "@/components/ui/button";
import {
	Dialog,
	DialogClose,
	DialogContent,
	DialogHeader,
	DialogTitle,
	DialogTrigger,
} from "@/components/ui/dialog";
import { toast } from "@/components/ui/use-toast.ts";
import { useCustomFormNavigate } from "@/hooks/customNavigate";
import { LayoutButtonsContext } from "@/layouts/Layout.tsx";
import { SuccessModal } from "@/pages/form/5/components/SuccessModal.tsx";
import { formStore } from "@/pages/form/stores/formStore";
import { cn, trpc } from "@/utils";
import { Download, X } from "lucide-react";
import { FormProvider, useForm } from "react-hook-form";

export const SendingQuotesPage = () => {
	const navigate = useCustomFormNavigate();
	const { setNextButtonDisabled } = useContext(LayoutButtonsContext);
	useEffect(() => {
		setNextButtonDisabled(true);
	}, []);
	const { chosenOptions, email, firstName, lastName, reset } = formStore(
		(state) => ({
			chosenOptions: state.chosenOptions,
			email: state.quote.emailDirigeant,
			firstName: state.quote.prenomDirigeant,
			lastName: state.quote.nomDirigeant,
			reset: state.reset,
		}),
	);

	const isPJChosen =
		chosenOptions.chosenOptions?.some((activity) =>
			activity.chosenOptions.some((option) => option.guaranteeType === "PJ"),
		) || false;

	const { data: documents } = trpc.documents.relevantDocuments.useQuery({
		isPJchosen: isPJChosen,
	});
	const { subscriptionId } = formStore((state) => state);

	const methods = useForm();
	const [isSuccessOpen, setIsSuccessOpen] = useState(false);

	const isDisabled = false;

	const sendDevisMutation = trpc.mailing.sendQuoteEmail.useMutation({
		onSuccess: () => {
			setIsSuccessOpen(true);
		},
		onError: () => {
			toast({
				title: "Erreur lors de l'envoi du devis",
				description: "Veuillez réessayer plus tard",
				variant: "destructive",
			});
		},
	});
	const onSubmit = () => {
		navigate("SendingQuotesEndPage");
	};

	const handleSendDevis = async () => {
		await sendDevisMutation.mutateAsync({
			subscriptionId: subscriptionId!,
			email: email!,
			firstName: firstName!,
			lastName: lastName!,
		});
	};

	const CustomFormNavigate = useCustomFormNavigate();
	const restart = useCallback(() => {
		localStorage.clear();
		reset();
		CustomFormNavigate("SirenInputPage");
	}, []);

	return (
		<FormProvider {...methods}>
			<form id="form" onSubmit={methods.handleSubmit(onSubmit)}>
				<SuccessModal
					isOpen={isSuccessOpen}
					onClose={() => setIsSuccessOpen(false)}
					onConfirm={restart}
				/>
				<Dialog>
					<div className="mb-4 flex flex-col items-center justify-between gap-2">
						<h1>Proposez l&apos;étape suivante à votre client</h1>
						<p>
							Sélectionnez l&apos;option adaptée : envoyer le devis, partager un
							lien de souscription ou poursuivre directement la souscription
							avec votre client.
						</p>
					</div>
					<div className="space-y-4">
						<div className="flex flex-row items-center justify-between rounded-sm border border-primary p-1 pl-4">
							<p className="font-semibold text-[#333]">
								Envoyer le devis à votre client
							</p>
							<Button
								disabled={isDisabled}
								className={cn(
									"h-auto w-40 bg-primary px-8 py-4 text-lg font-medium text-white disabled:bg-[#EAEAEA] disabled:text-[#8B7172]",
								)}
								type="button"
								onClick={handleSendDevis}
							>
								Envoyer
							</Button>
						</div>
						<div className="flex flex-row items-center justify-between rounded-sm border border-primary p-1 pl-4">
							<p className="font-semibold text-[#333]">
								Accédez aux documents associés à l&apos;offre
							</p>
							<DialogTrigger asChild>
								<Button
									disabled={isDisabled}
									className={cn(
										"h-auto w-40 bg-primary px-8 py-4 text-lg font-medium text-white disabled:bg-[#EAEAEA] disabled:text-[#8B7172]",
									)}
								>
									Télécharger
								</Button>
							</DialogTrigger>
							<DialogContent className="max-w-2xl outline-0">
								<DialogHeader className="flex flex-row items-center justify-between">
									<DialogTitle className="text-primary">
										Documents associés à l&apos;offre
									</DialogTitle>
									<DialogClose>
										<X className="size-6" />
									</DialogClose>
								</DialogHeader>
								<table className="mt-4 w-full rounded-sm">
									<tbody>
										{documents?.map((row, index) => (
											<tr
												key={index}
												className={
													index % 2 === 0 ? "bg-[#F2F4F6]" : "bg-white"
												}
											>
												<td className="p-3">{row.label}</td>
												<td className="p-3">
													<Button
														disabled={isDisabled}
														variant="ghost"
														asChild
														className="flex flex-row justify-end gap-2 hover:bg-transparent"
													>
														<a href={row.url} target="_blank" rel="noreferrer">
															<Download className="size-4 text-primary" />
															<span className="text-base font-bold text-primary">
																Télécharger
															</span>
														</a>
													</Button>
												</td>
											</tr>
										))}
									</tbody>
								</table>
							</DialogContent>
						</div>
					</div>
				</Dialog>
			</form>
		</FormProvider>
	);
};
