import {
	DeclarationsSectionsEnum,
	GuaranteeType,
	LeaseClauses,
	LocalStatus,
	NatureOfClaim,
	NumberOfClaimsInLastThreeYears,
	OccupationStatus,
	PaymentMethodEnum,
	RiskClass,
	SplittingOptionEnum,
	SubscriberCivility,
} from "@prisma/client";
import { addMonths, isBefore } from "date-fns";
import parseMax from "libphonenumber-js/max";
import { isValidPhoneNumber as isValidMobilePhoneNumber } from "libphonenumber-js/mobile";
import { z } from "zod";

export const establishmentTurnoverSchema = z.coerce
	.number({
		invalid_type_error: "",
		required_error: "",
	})
	.min(0, "")
	.max(9999999, "Le chiffre d'affaires ne peut pas dépasser 9 999 999 €.");

export const activityTurnoverSchema = z
	.number({
		invalid_type_error: "Veuillez saisir le chiffre d’affaires.",
		required_error: "Le chiffre d'affaires doit être renseigné.",
	})
	.min(1, "Le chiffre d’affaires doit être supérieur à 0.")
	.max(9000000, "Le chiffre d'affaires ne peut pas dépasser 9 000 000 €.");
export const emailSchema = z
	.string({
		required_error: "Veuillez saisir une adresse e-mail.",
		invalid_type_error: "L'adresse e-mail doit être valide.",
	})
	.email("L'adresse e-mail doit être valide.");

export const sirenSchema = z
	.string({
		required_error: "Veuillez saisir le numéro SIREN.",
		invalid_type_error: "Veuillez saisir le numéro SIREN.5",
	})
	.regex(/^\d*$/, "Le numéro SIREN doit contenir uniquement des chiffres.")
	.refine(
		//This refine is to allow empty string in case the company is not created yet
		(value) => value.length === 9 || value.length === 0,
		"Le numéro SIREN doit contenir 9 chiffres.",
	);

export const phoneNumberSchema = z
	.string({
		required_error: "Veuillez saisir le numéro de téléphone portable.",
		invalid_type_error: "Veuillez saisir le numéro de téléphone portable.",
	})
	.refine((value) => value && isValidMobilePhoneNumber(value, "FR"), {
		message: "Veuillez saisir un numéro de téléphone portable valide.",
	});
export const landlinePhoneNumberSchema = z
	.string({
		required_error: "Veuillez saisir le numéro de téléphone fixe",
		invalid_type_error: "Veuillez saisir le numéro de téléphone fixe.",
	})
	// Check https://www.npmjs.com/package/libphonenumber-js#isvalid
	// for more information about the difference between libphonenumber-js/mobile and libphonenumber-js/max
	.refine(
		(value) =>
			value &&
			parseMax(value, "FR") &&
			parseMax(value, "FR")!.getType() === "FIXED_LINE",
		{
			message: "Veuillez saisir un numéro de téléphone fixe valide.",
		},
	);

export const companyNameSchema = z
	.string({
		required_error: "Veuillez saisir la raison sociale de l'entreprise.",
		invalid_type_error: "Veuillez saisir la raison sociale de l'entreprise.",
	})
	.min(1, "Veuillez saisir la raison sociale de l'entreprise.");

export const streetLabelSchema = z
	.string({
		required_error: "Veuillez saisir l'adresse de l'entreprise.",
		invalid_type_error: "Veuillez saisir l'adresse de l'entreprise.",
	})
	.min(1, "Veuillez saisir l'adresse de l'entreprise.");

export const firstNameSchema = z
	.string({
		required_error: "Veuillez saisir votre nom.",
		invalid_type_error: "Veuillez saisir votre nom.",
	})
	.min(1, "Veuillez saisir votre nom.")
	.regex(
		/^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžæÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/u,
		"Veuillez saisir un prénom valide.",
	);

export const lastNameSchema = z
	.string({
		required_error: "Veuillez saisir votre prénom.",
		invalid_type_error: "Veuillez saisir votre prénom.",
	})
	.min(1, "Veuillez saisir votre prénom.")
	.regex(
		/^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžæÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/u,
		"Veuillez saisir un prénom valide.",
	);

export const firstNameLeaderSchema = z
	.string({
		required_error: "Veuillez saisir le prénom du dirigeant.",
		invalid_type_error: "Veuillez saisir le prénom du dirigeant.",
	})
	.min(1, "Veuillez saisir le prénom du dirigeant.")
	.regex(
		/^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžæÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/u,
		"Veuillez saisir un prénom valide.",
	);

export const lastNameLeaderSchema = z
	.string({
		required_error: "Veuillez saisir le nom du dirigeant.",
		invalid_type_error: "Veuillez saisir le nom du dirigeant.",
	})
	.min(1, "Veuillez saisir le nom du dirigeant.")
	.regex(
		/^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžæÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/u,
		"Veuillez saisir un prénom valide.",
	);

export const citySchema = z
	.string({
		required_error: "Veuillez saisir votre ville.",
		invalid_type_error: "Veuillez saisir votre ville.",
	})
	.min(1, "Veuillez saisir votre ville.");

export const countrySchema = z
	.string({
		required_error: "Veuillez saisir votre pays.",
		invalid_type_error: "Veuillez saisir votre pays.",
	})
	.min(2, "Veuillez saisir votre pays.")
	.default("FR");

export const birthdaySchema = z.coerce
	.date({
		errorMap: (error) => {
			switch (error.message) {
				case "Invalid date":
					return {
						message: "Veuillez saisir une date d'effet du contrat valide.",
					};
				case "Invalid type":
					return {
						message: "Veuillez saisir une date d'effet du contrat valide.",
					};
				default:
					return {
						message: "Veuillez saisir une date d'effet du contrat valide.",
					};
			}
		},
	})
	.refine((date) => date <= new Date(), {
		message: "La date de naissance ne peut pas être dans le futur.",
	})
	.refine(
		(date) => {
			const today = new Date();
			const age = today.getFullYear() - date.getFullYear();
			return age >= 18;
		},
		{
			message: "Veuillez saisir votre date de naissance.",
		},
	)
	.refine(
		(date) => {
			//make sure it isnt Thu Jan 01 1970 01:00:00 GMT+0100
			return date.toISOString() !== new Date(0).toISOString();
		},
		{
			message: "Veuillez saisir une date de naissance valide.",
		},
	);

export const birthdayLeaderSchema = z.coerce
	.date({
		errorMap: (error) => {
			switch (error.message) {
				case "Invalid date":
					return {
						message: "Veuillez saisir la date de naissance du dirigeant.",
					};
				case "Invalid type":
					return {
						message: "Veuillez saisir la date de naissance du dirigeant.",
					};
				default:
					return {
						message: "Veuillez saisir la date de naissance du dirigeant.",
					};
			}
		},
	})
	.refine((date) => date <= new Date(), {
		message: "La date de naissance ne peut pas être dans le futur.",
	})
	.refine(
		(date) => {
			const today = new Date();
			const age = today.getFullYear() - date.getFullYear();
			return age >= 18;
		},
		{
			message: "Veuillez saisir votre date de naissance.",
		},
	)
	.refine(
		(date) => {
			//make sure it isnt Thu Jan 01 1970 01:00:00 GMT+0100
			return date.toISOString() !== new Date(0).toISOString();
		},
		{
			message: "Veuillez saisir une date de naissance valide.",
		},
	);

export const postalCodeSchema = z
	.string({
		required_error: "Veuillez saisir un code postal.",
		invalid_type_error: "Veuillez saisir un code postal valide.",
	})
	.regex(/^\d+$/, "Le code postal doit contenir uniquement des chiffres.")
	.min(3, "Le code postal doit contenir au moins 3 caractères.")
	.max(5, "Le code postal doit contenir au plus de 5 caractères.");

export const natureOfClaimSchema = z.nativeEnum(NatureOfClaim, {
	required_error: "Veuillez sélectionner la nature de sinistre.",
	invalid_type_error: "Veuillez sélectionner la nature de sinistre.",
});

export const detailedAddressSchema = z.object({
	streetLabel: streetLabelSchema,
	city: citySchema,
	country: countrySchema,
	postalCode: postalCodeSchema,
});
export type DetailedAddressDto = z.infer<typeof detailedAddressSchema>;
export const effectiveDateSchema = z.coerce
	.date({
		errorMap: (error) => {
			switch (error.message) {
				case "Invalid date":
					return {
						message: "Veuillez sélectionner la date d'effet du contrat.",
					};
				case "Invalid type":
					return {
						message: "Veuillez sélectionner la date d'effet du contrat.",
					};
				default:
					return {
						message: "Veuillez sélectionner la date d'effet du contrat.",
					};
			}
		},
	})
	.refine(
		(date) => {
			const nineMonthsFromNow = addMonths(new Date(), 9);
			return isBefore(date, nineMonthsFromNow);
		},
		{
			message:
				"La date d'effet ne peut pas dépasser 9 mois à partir d'aujourd'hui.",
		},
	);

export const partnershipIdSchema = z.string({
	required_error: "Veuillez saisir l'ID du partenariat.",
	invalid_type_error: "Veuillez saisir l'ID du partenariat.",
});

export const nafCodeSchema = z
	.string({
		required_error: "Veuillez saisir le code NAF.",
		invalid_type_error: "Veuillez saisir le code NAF.",
	})
	.regex(/^\d{2}\.\d{2}[A-Z]$/, {
		message: "Le code NAF doit être au format 12.34Z",
	});

export const activityFamily = z.string();

export const activityLabelInputSchema = z.string().min(3);

export const mainActivityLabelSchema = z.string();

export const mainActivityCodeSchema = z.string();

export const mainActivitySchema = z.object({
	nafCode: nafCodeSchema,
	code: mainActivityCodeSchema,
	label: mainActivityLabelSchema,
	turnover: establishmentTurnoverSchema,
	id: z.string(),
});

export type MainActivityDto = z.infer<typeof mainActivitySchema>;

export const companyCreationDateSchema = z.coerce
	.date({
		errorMap: (error) => {
			switch (error.message) {
				case "Invalid date":
					return {
						message: "Veuillez saisir une date de création valide.",
					};
				case "Invalid type":
					return {
						message: "Veuillez saisir une date de création valide.",
					};
				default:
					return {
						message: "Veuillez saisir une date de création valide.",
					};
			}
		},
	})
	.min(new Date("1800-01-01"), "La date de création doit être après 1800.");

export const activityInputSchema = z.object(
	{
		id: z.string({
			required_error: "Veuillez sélectionner une activité.",
			invalid_type_error: "Veuillez sélectionner une activité.",
		}),
		turnover: activityTurnoverSchema,
		code: z.string(),
		label: z.string(),
	},
	{
		required_error: "Veuillez sélectionner une activité.",
		invalid_type_error: "Veuillez sélectionner une activité.",
	},
);
export type ActivityInputDto = z.infer<typeof activityInputSchema>;
export const sectorIdSchema = z.string({
	required_error: "Veuillez choisir le secteur d'activité.",
});
export const sectorLabelSchema = z.string({
	required_error: "Veuillez choisir le secteur d'activité.",
});

export const sectorUrlSchema = z
	.string({
		required_error: "Veuillez choisir le secteur d'activité.",
	})
	.url();
export const establishmentNameSchema = z.string({
	required_error: "Veuillez saisir le nom de l'établissement.",
	invalid_type_error: "Veuillez saisir le nom de l'établissement.",
});

export const numberOfEmployeesSchema = z.coerce
	.number({
		required_error: "Veuillez saisir le nombre de salariés.",
		invalid_type_error: "Veuillez saisir le nombre de salariés.",
	})
	.min(1, "Veuillez saisir le nombre de salariés.");
export const surfaceAreaSchema = z.coerce
	.number({
		required_error: "Veuillez saisir la superficie développée.",
		invalid_type_error: "Veuillez saisir la superficie développée.",
	})
	.min(1, "Veuillez saisir la superficie développée.")
	.max(5000, "La surface de l'établissement ne peut pas dépasser 5 000 m².");
export const professionalContentValueSchema = z.coerce
	.number({
		required_error:
			"Veuillez saisir la valeur du contenu professionnel d'exploitation (hors marchandises)",
		invalid_type_error:
			"Veuillez saisir la valeur du contenu professionnel d'exploitation (hors marchandises)",
	})
	.min(
		0,
		"Veuillez saisir la valeur du contenu professionnel d'exploitation (hors marchandises)",
	)
	.max(
		2000000,
		"La valeur du contenu professionnel d’exploitation (hors marchandises) ne peut pas dépasser 2 000 000 €.",
	);
export const stockValueExclVatPurchaseSchema = z.coerce
	.number({
		required_error: "Veuillez saisir la valeur du stock en valeur achat HT.",
		invalid_type_error:
			"Veuillez saisir la valeur du stock en valeur achat HT.",
	})
	.min(0, "Veuillez saisir la valeur du stock en valeur achat HT.");
export const contractualIndemnityLimitPerSqmSchema = z.coerce.number({
	required_error:
		"Veuillez sélectionner la limite contractuelle d'indemnité du bâtiment au m²",
	invalid_type_error:
		"Veuillez sélectionner la limite contractuelle d'indemnité du bâtiment au m²",
});

export const numberOfClaimsInLast3YearsSchema = z.nativeEnum(
	NumberOfClaimsInLastThreeYears,
	{
		required_error: "Veuillez choisir une option.",
	},
);
export type NumberOfClaimsInLast3YearsDto = z.infer<
	typeof numberOfClaimsInLast3YearsSchema
>;
export const siretSchema = z
	.string({
		required_error: "Veuillez saisir le numéro SIRET.",
		invalid_type_error: "Veuillez saisir le numéro SIRET.",
	})
	.regex(/^\d{14}$/, "Le numéro SIRET doit contenir 14 chiffres.");
export const establishmentOccupationStatusSchema = z.nativeEnum(
	OccupationStatus,
	{
		required_error: "Veuillez sélectionner le statut d'occupation.",
	},
);
export type EstablishmentOccupationStatusDto = z.infer<
	typeof establishmentOccupationStatusSchema
>;
export const occupationStatusObjectSchema = z.array(
	z.object({
		value: establishmentOccupationStatusSchema,
		label: z.string(),
	}),
);

export const leaseClausesSchema = z.array(
	z.object({
		value: z.nativeEnum(LeaseClauses),
		label: z.string(),
	}),
);

export const BuninessTypesSchema = z.array(
	z.object({
		code: z.string(),
		label: z.string(),
	}),
);

export const legalStatusCodeSchema = z.string({
	required_error: "Veuillez saisir la forme juridique.",
	invalid_type_error: "Veuillez saisir la forme juridique.",
});

// export const legalStatusSchema = z
// 	.object({
// 		code: legalStatusCodeSchema,
// 		label: z.string().optional(),
// 	})
// 	.or(z.string())
// 	.transform((val) => {
// 		if (typeof val === "string") {
// 			return { code: val };
// 		}
// 		return val;
// 	});

const legalStatusStringSchema = z.string();

const legalStatusObjectSchema = z.object({
	code: legalStatusCodeSchema,
	label: z.string().optional(),
});

const legalStatusSchema = z.union([
	legalStatusObjectSchema,
	legalStatusStringSchema,
]);

export const transformedLegalStatusSchema = legalStatusSchema.transform(
	(val) => {
		if (typeof val === "string") {
			return { code: val };
		}
		return val;
	},
);

// schema for validation company manual input
export const LegalEntityInputSchema = z.object({
	companyName: companyNameSchema,
	siren: sirenSchema,
	creationDate: companyCreationDateSchema,
	nafCode: nafCodeSchema,
	legalStatus: legalStatusSchema,
	address: detailedAddressSchema,
});

export const DeclarationSchema = z.object({
	id: z.string(),
	label: z.string(),
	section: z.nativeEnum(DeclarationsSectionsEnum),
});

export const DeclarationsSchema = z.array(DeclarationSchema);

export const activityIdStringSchema = z
	.string({
		required_error: "Veuillez saisir l'ID de l'activité.",
		invalid_type_error: "Veuillez saisir l'ID de l'activité.",
	})
	.regex(/^\d{2}\.\d{2}[A-Z]-[A-Z0-9]{5}$/, {
		message:
			"Invalid ID format. Expected pattern: XX.XXA-XXXXX (digits, letters)",
	});

export const ActivityIdSchema = z.object({
	activityId: activityIdStringSchema,
});

export const sectorSchema = z.object({
	id: sectorIdSchema,
	label: sectorLabelSchema,
	iconUrl: sectorUrlSchema,
	selectedIconUrl: sectorUrlSchema,
});

export const guaranteeSchema = z.object({
	type: z.nativeEnum(GuaranteeType),
	label: z.string(),
	isOptional: z.boolean(),
	iconUrl: z.string(),
	order: z.number().int().positive(),
	description: z.string(),
});

export const guaranteeRiskClassSchema = z.object({
	guaranteeType: z.nativeEnum(GuaranteeType),
	riskClass: z.nativeEnum(RiskClass),
	Guarantee: guaranteeSchema,
});

export const activityGuaranteeDetailsSchema = z.object({
	id: z.string(),
	label: z.string(),
	nafCode: z.string(),
	isAccepted: z.boolean(),
	sector: sectorSchema,
	guaranteeRiskClass: z.array(guaranteeRiskClassSchema),
});

export type ActivityGuaranteeDetailsDto = z.infer<
	typeof activityGuaranteeDetailsSchema
>;

export const chosenOptionInputSchema = z.object({
	guaranteeValue: z
		.union([z.number(), z.string().transform((val) => parseFloat(val))])
		.transform((val) => {
			if (typeof val === "string") {
				return parseFloat(val);
			}
			if (!val) {
				return 0;
			}
			return val;
		}),
	guaranteeType: z.nativeEnum(GuaranteeType),
});

const activityChosenOptionsSchema = z.array(
	z.object({
		activityId: z.string(),
		chosenOptions: z.array(
			z.object({
				guaranteeType: z.string(),
				guaranteeValue: z.number(),
			}),
		),
	}),
);

export type ChosenOptionsDto = z.infer<typeof activityChosenOptionsSchema>;

export const establishmentInputSchema = z.object({
	siret: siretSchema,
	activitiesIds: z.array(z.string()),
	address: detailedAddressSchema,
	turnover: establishmentTurnoverSchema,
	area: surfaceAreaSchema,
	numberOfEmployees: numberOfEmployeesSchema,
	occupationStatus: establishmentOccupationStatusSchema,
	professionalOperatingContent: professionalContentValueSchema,
	stockPurchasedValueExcludingTax: stockValueExclVatPurchaseSchema,
	contractualLimitOfCompensationPerM2: contractualIndemnityLimitPerSqmSchema,
	chosenOptions: z.array(chosenOptionInputSchema),
	// isPrimary: z.boolean(),
});

export type EstablishmentInputDto = z.infer<typeof establishmentInputSchema>;

export const declarationConfirmationInputSchema = z.object({
	declarationId: z.string(),
	answer: z
		.boolean({
			required_error: "Veuillez confirmer les déclarations",
			invalid_type_error: "Veuillez confirmer les déclarations",
		})
		.or(z.string()),
});

export type DeclarationDto = z.infer<typeof declarationConfirmationInputSchema>;
export const civilitySchema = z.nativeEnum(SubscriberCivility, {
	required_error: "Veuillez choisir une civilité.",
});
export const civilityLeaderSchema = z.nativeEnum(SubscriberCivility, {
	required_error: "Veuillez saisir la civilité du dirigeant.",
});
export type CivilityDto = z.infer<typeof civilitySchema>;
export const paymentMethodSchema = z.nativeEnum(PaymentMethodEnum, {
	required_error: "Veuillez choisir le mode de paiement.",
});

export type PaymentMethodDto = z.infer<typeof paymentMethodSchema>;
export const splitOptionSchema = z.nativeEnum(SplittingOptionEnum, {
	required_error: "Veuillez choisir le fractionnement de la prime.",
});
export type SplitOptionDto = z.infer<typeof splitOptionSchema>;
export const contractDueDateSchema = z.coerce.date({
	errorMap: (error) => {
		switch (error.message) {
			case "Invalid date":
				return {
					message: "",
				};
			case "Invalid type":
				return {
					message: "",
				};
			default:
				return {
					message: "",
				};
		}
	},
});
export const fileFeesSchema = z
	.number({
		required_error:
			"Veuillez saisir les frais de dossier Courtier à la souscription.",
		invalid_type_error:
			"Veuillez saisir les frais de dossier Courtier à la souscription.",
	})
	.min(0, "Veuillez saisir les frais de dossier Courtier à la souscription.")
	.max(300, "Les frais de dossier Courtier ne peuvent pas dépasser 300 €.");
export const brokerCommissionSchema = z
	.number({
		required_error: "Veuillez ajouter la commission courtier.",
		invalid_type_error: "Veuillez ajouter la commission courtier.",
	})
	.min(0, "Veuillez ajouter la commission courtier.")
	.max(20, "La commission du courtier ne peut pas dépasser 20%");
export const localStatusSchema = z.nativeEnum(LocalStatus, {
	required_error: "Veuillez choisir le statut local.",
	invalid_type_error: "Veuillez choisir le statut local.",
});
export type LocalStatusDto = z.infer<typeof localStatusSchema>;
export const deductibleSchema = z.enum(["450", "750", "1000", "1500", "2000"], {
	required_error: "Veuillez sélectionner la valeur de la franchise.",
});
export type DeductibleDto = z.infer<typeof deductibleSchema>;

export const subscriberInputSchema = z.object({
	civility: civilitySchema,
	lastName: firstNameSchema,
	firstName: lastNameSchema,
	email: emailSchema,
	phoneNumber: phoneNumberSchema,
	birthDate: birthdaySchema,
});

export type SubscriberInputDto = z.infer<typeof subscriberInputSchema>;

export const declarationSchema = z.object({
	declarationId: z.string(),
	answer: z.boolean({
		required_error: "Veuillez répondre à toutes les questions.",
	}),
});

export const declarationsConfirmationInputSchema = z.object({
	declarationsArray: z.array(declarationSchema),
	certify: z
		.boolean({
			required_error: "Veuillez certifier l'exactitude de vos réponses.",
		})
		.refine((val) => val === true, {
			message: "Veuillez certifier l'exactitude de vos réponses.",
		}),
});

export type DeclarationsDto = z.infer<
	typeof declarationsConfirmationInputSchema
>;

export const oldDeclarationsConfirmationInputSchema = z.object({
	declarationsArray: z.array(declarationSchema),
});

export type OldDeclarationsDto = z.infer<
	typeof oldDeclarationsConfirmationInputSchema
>;

export const subsctiprionIdSchema = z.object({
	subscriptionId: z.string(),
});

export const guaranteeTypesSchema = z.nativeEnum(GuaranteeType);
// export type GuaranteeTypesDto = z.infer<typeof guaranteeTypesSchema>;
