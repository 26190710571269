import type { EditSecondaryEstablishmentDTO } from "@/pages/form/schemas";
import { useState } from "react";
import { AddressInput } from "@/components/inputs/AdressInput.tsx";
import { TextInput } from "@/components/inputs/TextInput.tsx";
import { Button } from "@/components/ui/button.tsx";
import {
	DialogContent,
	DialogDescription,
	DialogFooter,
	DialogHeader,
	DialogTitle,
} from "@/components/ui/dialog.tsx";
import { toast } from "@/components/ui/use-toast.ts";
import { MAX_ESTABLISHMENTS } from "@/config/index.ts";
import { editSecondaryEstablishmentSchema } from "@/pages/form/schemas";
import { formStore } from "@/pages/form/stores/formStore.ts";
import { zodResolver } from "@hookform/resolvers/zod";
import { FormProvider, useForm } from "react-hook-form";

import type { MatchingEtablissementDto } from "@repos/mrp-dtos/src/dtos/company.dto.ts";

interface AddSecondaryEstablishmentModalContentProps {
	closeDialog: () => void;
}

export const AddSecondaryEstablishmentModalContent = ({
	closeDialog,
}: AddSecondaryEstablishmentModalContentProps) => {
	const {
		appendInsuredEstablishments,
		matchingEstablishmentsBySelectedEntreprise,
		setMatchingEstablishmentsBySelectedEntreprise,
		insuredEstablishments,
		siren,
	} = formStore((state) => ({
		appendInsuredEstablishments: state.appendInsuredEstablishments,
		setMatchingEstablishmentsBySelectedEntreprise:
			state.setMatchingEstablishmentsBySelectedEntreprise,
		matchingEstablishmentsBySelectedEntreprise:
			state.matchingEstablishmentsBySelectedEntreprise,
		insuredEstablishments: state.establishments.insuredEstablishments,
		siren: state.sirenPage.company?.siren,
	}));

	const methods = useForm<EditSecondaryEstablishmentDTO>({
		resolver: zodResolver(editSecondaryEstablishmentSchema),
	});
	const [renderState, setRenderState] = useState(false);

	const onSubmit = (data: EditSecondaryEstablishmentDTO) => {
		if (data.siret.slice(0, 9) !== siren) {
			toast({
				title: "Le siret de l'entreprise est incorrect",
				variant: "destructive",
			});
			return;
		}

		if (
			insuredEstablishments &&
			insuredEstablishments.length < MAX_ESTABLISHMENTS
		) {
			appendInsuredEstablishments({
				siret: data.siret,
				postalCode: data.address.postalCode,
				city: data.address.city,
				streetLabel: data.address.streetLabel,
				country: data.address.country,
			});
			setMatchingEstablishmentsBySelectedEntreprise([
				...matchingEstablishmentsBySelectedEntreprise,
				{
					siret: data.siret,
					detailedAddress: {
						postalCode: data.address.postalCode,
						city: data.address.city,
						streetLabel: data.address.streetLabel,
						country: data.address.country,
					},
				} as MatchingEtablissementDto,
			]);
		} else {
			toast({
				title: "Impossible d'ajouter un établissement",
				description: `Vous avez déjà atteint le nombre maximum d'établissements (${MAX_ESTABLISHMENTS})`,
				variant: "destructive",
			});
		}
		methods.reset();
		setRenderState((prev) => !prev);
		closeDialog();
	};
	return (
		<DialogContent className="max-w-2xl">
			<FormProvider {...methods}>
				<form
					id="add-secondary-establishment-details-form"
					onSubmit={(e) => {
						e.preventDefault();
						methods.handleSubmit(onSubmit)();
						e.stopPropagation();
					}}
				>
					<DialogHeader>
						<DialogTitle>Ajouter un établissement</DialogTitle>
						<DialogDescription>
							Veuillez remplir les informations nécessaires pour ajouter un
							nouvel établissement
						</DialogDescription>
					</DialogHeader>
					<div className="grid gap-4 py-4">
						<TextInput<EditSecondaryEstablishmentDTO>
							label="Numéro SIRET"
							key={renderState ? "rerender" : "no-rerender"}
							name="siret"
							required
							disabled={siren === "999999999"}
							defaultValue={
								siren === "999999999"
									? `9999999990000${matchingEstablishmentsBySelectedEntreprise.length + 1}`
									: undefined
							}
						/>
						<AddressInput<EditSecondaryEstablishmentDTO>
							label="Adresse"
							name="address.streetLabel"
							cityProps={{ name: "address.city" }}
							postalCodeProps={{ name: "address.postalCode" }}
							countryProps={{ name: "address.country" }}
							required
						/>
					</div>
					<DialogFooter>
						<Button
							type="submit"
							form="add-secondary-establishment-details-form"
						>
							Enregistrer
						</Button>
					</DialogFooter>
				</form>
			</FormProvider>
		</DialogContent>
	);
};
