import type { SirenPageDto } from "@/pages/form/schemas";
import { Button } from "@/components/ui/button.tsx";
import { Dialog, DialogTrigger } from "@/components/ui/dialog.tsx";
import { ManualEntrepriseInputModalContent } from "@/pages/form/1/components/1/ManualEntrepriseInputModalContent.tsx";
import { useFormContext } from "react-hook-form";
import { IoIosArrowDroprightCircle } from "react-icons/io";

export function EntrepriseNotCreatedYetButton() {
	const { setValue, clearErrors, reset } = useFormContext<SirenPageDto>();
	return (
		<Dialog
			onOpenChange={(v) => {
				setValue("companyUnderCreation", v);
				clearErrors();
				reset();
			}}
		>
			<DialogTrigger asChild>
				<Button className="w-full bg-[#E0F3FF] p-6 hover:bg-[#E0F3FF]/90">
					<div className="flex w-full items-center justify-between text-[#598EAD] underline">
						<span>Mon entreprise est en cours de création </span>
						<span>
							<IoIosArrowDroprightCircle size={20} />
						</span>
					</div>
				</Button>
			</DialogTrigger>
			<ManualEntrepriseInputModalContent
				sirenRequired={false}
				headerTitle="Ajouter les Informations de l’entreprise"
			/>
		</Dialog>
	);
}
