import type { NumberOfClaimsPageDto } from "@/pages/form/schemas";
import { useEffect, useState } from "react";
import { ContactModal } from "@/components/ContactModal.tsx";
import { CustomSpinner } from "@/components/CustomSpinner.tsx";
import {
	FormControl,
	FormField,
	FormItem,
	FormLabel,
} from "@/components/ui/form.tsx";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group.tsx";
import { useCustomFormNavigate } from "@/hooks/customNavigate.ts";
import { NumberOFClaimsCard } from "@/pages/form/1/components/NumberOFClaimsCard.tsx";
import { numberOfClaimsPageSchema } from "@/pages/form/schemas";
import { formStore } from "@/pages/form/stores/formStore.ts";
import { trpc } from "@/utils";
import { zodResolver } from "@hookform/resolvers/zod";
import { FormProvider, useForm } from "react-hook-form";

import type {
	MatchingEtablissementDto,
	NumberOfClaimsInLast3YearsDto,
} from "@repos/mrp-dtos";

export const NumberOfClaimsPage = () => {
	const {
		numberOfClaimsPage,
		setNumberOfClaimsPage,
		setMatchingEstablishmentsBySelectedEntreprise,
		insuredEstablishments,
		appendInsuredEstablishments,
		siren,
		mainEstablishment,
	} = formStore((state) => ({
		numberOfClaimsPage: state.numberOfClaimsPage,
		setNumberOfClaimsPage: state.setNumberOfClaimsPage,
		setMatchingEstablishmentsBySelectedEntreprise:
			state.setMatchingEstablishmentsBySelectedEntreprise,
		insuredEstablishments: state.establishments.insuredEstablishments,
		appendInsuredEstablishments: state.appendInsuredEstablishments,
		siren: state.sirenPage.company?.siren,
		mainEstablishment: state.sirenPage.mainEstablishment,
	}));
	const navigate = useCustomFormNavigate();
	const openContactModal = () => {
		setContactModalOpen(true);
	};
	const methods = useForm<NumberOfClaimsPageDto>({
		resolver: zodResolver(numberOfClaimsPageSchema),
		defaultValues: {
			...numberOfClaimsPage,
		},
	});
	if (!siren) {
		navigate("SirenInputPage");
	}

	const getMainEstablishmentsQuery =
		trpc.companies.getMatchingEstablishmentsBySelectedEntreprise.useQuery(
			{
				sirenOrSiret: siren!,
			},
			{
				enabled: siren !== "999999999",
			},
		);
	useEffect(() => {
		if (!getMainEstablishmentsQuery.isSuccess) return;
		const newMatchingEstablishments: MatchingEtablissementDto[] = [];
		if (mainEstablishment) {
			newMatchingEstablishments.push(mainEstablishment);
		}
		newMatchingEstablishments.push(
			...getMainEstablishmentsQuery.data.filter(
				(establishment) => establishment.siret !== mainEstablishment?.siret,
			),
		);
		setMatchingEstablishmentsBySelectedEntreprise(newMatchingEstablishments);
		//if insuredEstablishments is empty, append the main establishment
		if (insuredEstablishments && insuredEstablishments.length === 0) {
			const mainEstablishment = newMatchingEstablishments.find(
				(establishment) => establishment.isHeadquarters,
			);
			if (mainEstablishment) {
				appendInsuredEstablishments({
					...mainEstablishment,
					postalCode: mainEstablishment.detailedAddress.postalCode,
					city: mainEstablishment.detailedAddress.city,
					streetLabel: mainEstablishment.detailedAddress.streetLabel,
					country: mainEstablishment.detailedAddress.country,
				});
			}
		}
	}, [getMainEstablishmentsQuery.data]);
	const onSubmit = (data: NumberOfClaimsPageDto) => {
		setNumberOfClaimsPage(data);
		if (data.numberOfClaims === "MORETHANTHREE") {
			openContactModal();
			return;
		} else navigate("ActivityPage");
	};

	const enumKeys: NumberOfClaimsInLast3YearsDto[] = [
		"ZERO",
		"ONE",
		"TWO",
		"THREE",
		"MORETHANTHREE",
	];
	const [contactModalOpen, setContactModalOpen] = useState(false);

	const onModalClose = () => {
		setContactModalOpen(false);
	};
	if (siren !== "999999999" && getMainEstablishmentsQuery.isLoading) {
		return <CustomSpinner />;
	}
	return (
		<FormProvider {...methods}>
			<ContactModal
				isOpen={contactModalOpen}
				onClose={onModalClose}
				description={
					"En raison d'un nombre élevé de sinistres récents, nous ne sommes malheureusement pas en mesure de vous proposer une couverture adaptée aux besoins de votre client.Veuillez saisir votre numéro de téléphone et notre support vous contactera rapidement."
				}
			/>
			<form
				onSubmit={methods.handleSubmit(onSubmit)}
				className="flex w-full flex-col gap-12"
				id="form"
			>
				<div className="flex items-center justify-between">
					<h1>
						Combien de sinistres ont été déclarés à l’assurance au cours de ces
						36 derniers mois ?
					</h1>
				</div>
				<FormField
					control={methods.control}
					name="numberOfClaims"
					render={({ field }) => (
						<FormItem className="space-y-3">
							<FormControl>
								<RadioGroup
									onValueChange={field.onChange}
									defaultValue={field.value}
									className="flex flex-col space-y-1"
								>
									<div className="grid grid-cols-3 gap-4 xl:grid-cols-4">
										{enumKeys.map((value) => (
											<FormItem
												className="flex items-center space-x-3 space-y-0"
												key={value}
											>
												<FormControl>
													<RadioGroupItem value={value} hidden />
												</FormControl>
												<FormLabel>
													<NumberOFClaimsCard
														numberOfClaimsValue={value}
														isSelected={value === field.value}
													/>
												</FormLabel>
											</FormItem>
										))}
									</div>
								</RadioGroup>
							</FormControl>
						</FormItem>
					)}
				/>
			</form>
		</FormProvider>
	);
};
