import { HeaderFooter } from "@/components/quotePdf/HeaderFooter.tsx";
import RiskDetail from "@/components/quotePdf/RiskDetail.tsx";
import { styles } from "@/components/quotePdf/styles.ts";
import { Page, Text, View } from "@react-pdf/renderer";

import type { SubscriptionDetailsForQuoteDto } from "@repos/mrp-dtos";

export const InsuredEstablishmentsPdfPage = ({
	...props
}: SubscriptionDetailsForQuoteDto) => {
	return (
		<Page size="A4" style={styles.page}>
			<HeaderFooter />
			<View style={styles.container}>
				<Text style={[styles.sectionHeader, styles.mt_10]}>
					LES LOCAUX À ASSURER
				</Text>
				<RiskDetail {...props} />
			</View>
		</Page>
	);
};
