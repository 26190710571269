import type { TableData } from "@/components/quotePdf/Table";
import { styles } from "@/components/quotePdf/styles";
import ThreeColumnTable from "@/components/quotePdf/Table";
import { addSpacingBetweenThousandsToString } from "@/utils";
import { StyleSheet, Text, View } from "@react-pdf/renderer";

import type { SubscriptionDetailsForQuoteDto } from "@repos/mrp-dtos";

const localStyles = StyleSheet.create({
	tableHeader: {
		color: "red",
		fontSize: 14,
		textAlign: "center",
		border: "1px solid black",
		padding: 5,
		borderBottomWidth: 0,
	},
});
const FinancialInsurances = ({ ...props }: SubscriptionDetailsForQuoteDto) => {
	const selectedOptionsData: TableData = [];
	if (props.isPerteExpVolChosen || props.isEvenementClimatiquesChosen) {
		selectedOptionsData.push({
			type: "row",
			data: [
				"Perte d’exploitation, perte de revenus et/ou frais supplémentaires",
				<View
					key="1-3"
					style={{ display: "flex", flexDirection: "column", gap: 10 }}
				>
					<Text>
						120 % du chiffre d’affaires, des revenus ou des honoraires déclarés
						(hors TVA) multiplié par le taux de marge brute, pour la période
						d’indemnisation indiquée aux Conditions particulières, sauf autre
						montant précisé par celles-ci.
					</Text>
					<Text>Période d’indemnisation maximale : 24 mois</Text>
				</View>,
				<View
					key="1-3"
					style={{ display: "flex", flexDirection: "column", gap: 10 }}
				>
					<View>
						<Text>3 premiers jours ouvrés.</Text>
					</View>
					<View>
						<Text style={styles.bold}>FRANCHISES SPECIFIQUES :</Text>
						<Text>
							LEGALE : catastrophes naturelles, cette franchise s’applique aussi
							pour les inondations.
						</Text>
					</View>
				</View>,
			],
		});
	}
	if (props.isPerteValeurChosen) {
		selectedOptionsData.push({
			type: "row",
			data: [
				"Perte de valeur vénale du fonds",
				<View
					key="1-3"
					style={{ display: "flex", flexDirection: "column", gap: 10 }}
				>
					<Text>120% du CA du dernier exercice</Text>
				</View>,
				<View
					key="2-3"
					style={{ display: "flex", flexDirection: "column", gap: 10 }}
				>
					{/* FRANCHISE GÉNÉRALE */}
					<View>
						<Text style={styles.bold}>FRANCHISE GÉNÉRALE : </Text>
						<Text>
							{addSpacingBetweenThousandsToString(props.deductible)} €
						</Text>
					</View>
				</View>,
			],
		});
	}
	if (selectedOptionsData.length === 0) return null;
	return (
		<>
			<Text style={[styles.bold, localStyles.tableHeader]}>
				Assurances des conséquences financières de l’arrêt d’activité
			</Text>
			<ThreeColumnTable
				columns={[
					{ header: "Garanties", width: 15, grow: 1 },
					{
						header: "Limites d’indemnisation par sinistre",
						width: 55,
						grow: 2,
					},
					{ header: "Franchise par sinistre", width: 30, grow: 1 },
				]}
				data={selectedOptionsData}
			/>
		</>
	);
};
export default FinancialInsurances;
