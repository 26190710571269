import type {
	FieldValues,
	Path,
	PathValue,
	UseControllerProps,
} from "react-hook-form";
import { useMemo, useState } from "react";
import { Button } from "@/components/ui/button.tsx";
import { Checkbox } from "@/components/ui/checkbox.tsx";
import {
	FormControl,
	FormField,
	FormItem,
	FormLabel,
	FormMessage,
} from "@/components/ui/form.tsx";
import {
	Popover,
	PopoverContent,
	PopoverTrigger,
} from "@/components/ui/popover.tsx";
import { cn } from "@/utils";
import { useFormContext } from "react-hook-form";
import { BsFillArrowDownCircleFill } from "react-icons/bs";

import {
	Command,
	CommandEmpty,
	CommandGroup,
	CommandInput,
	CommandItem,
	CommandList,
} from "../ui/command.tsx";

export interface OptionType {
	label: string;
	value: string;
	id?: string;
	categoryLabel?: string;
	nafCode?: string;
}

interface SingleSelectComboboxProps<T extends FieldValues, U extends OptionType>
	extends UseControllerProps<T> {
	label: string;
	options: U[];
	onValueChange?: (value: U["value"]) => void;
	className?: string;
	buttonClassName?: string;
	required?: boolean;
	sort?: boolean;
}

export function SingleSelectCombobox<
	T extends FieldValues,
	U extends OptionType,
>({
	label,
	className,
	buttonClassName,
	name,
	options,
	onValueChange,
	required,
	sort,
	...props
}: SingleSelectComboboxProps<T, U>) {
	const { control, setValue } = useFormContext<T>();
	const [open, setOpen] = useState(false);
	const uniqueCategories = Array.from(
		new Set(options.map((option) => option.categoryLabel)),
	);
	//sort options by label for alphabetical order

	useMemo(() => {
		if (sort) options.sort((a, b) => a.label.localeCompare(b.label));
	}, [options, sort]);
	//sort uniqueCategories for alphabetical order
	useMemo(() => {
		uniqueCategories.sort();
	}, [uniqueCategories]);

	const onSelectHandler = (value: string) => {
		setValue(name, value as PathValue<T, Path<T>>);
		setOpen(false);
		onValueChange?.(value);
	};
	return (
		<FormField
			{...props}
			control={control}
			name={name}
			render={({ field }) => (
				<FormItem className={cn("flex w-full flex-col", className)}>
					<FormLabel className="text-sm font-medium text-[#333333]">
						{label} {required && <span className="text-red-500">*</span>}
					</FormLabel>
					<Popover open={open} onOpenChange={setOpen}>
						<PopoverTrigger asChild>
							<FormControl>
								<Button
									variant="outline"
									role="combobox"
									className={cn(
										"w-full justify-between overflow-hidden overflow-ellipsis whitespace-nowrap rounded-md border-gray-300 bg-white p-5 font-normal text-black",
										!field.value && "text-muted-foreground",
										buttonClassName,
									)}
								>
									{field.value &&
									options.find((option) => option.value === field.value)
										? options.find((option) => option.value === field.value)
												?.label
										: `Choisir une option`}
									<BsFillArrowDownCircleFill className="mx-2 size-5 cursor-pointer text-secondary-500" />
								</Button>
							</FormControl>
						</PopoverTrigger>
						<PopoverContent
							align="end"
							side="bottom"
							className="bottom-0 w-[300px] p-0"
						>
							<Command>
								<CommandInput placeholder="Chercher une option..." />
								<CommandList>
									<CommandEmpty>Aucun résultat</CommandEmpty>
									{uniqueCategories.map((sectorId) => (
										<CommandGroup heading={sectorId} key={sectorId}>
											{options
												.filter((option) => option.categoryLabel === sectorId)
												.map((option) => (
													<CommandItem
														value={option.label}
														key={option.value}
														className="flex items-start gap-2"
														onSelect={() => onSelectHandler(option.value)}
													>
														<Checkbox
															className="rounded-md"
															checked={option.value === field.value}
														/>
														{option.label}
													</CommandItem>
												))}
										</CommandGroup>
									))}
								</CommandList>
							</Command>
						</PopoverContent>
					</Popover>
					<FormMessage />
				</FormItem>
			)}
		/>
	);
}
