import type { FormStoreState } from "@/pages/form/stores/formStore.ts";
import type { GuaranteeType } from "@prisma/client";
import { getRelatedGuarantees } from "@/lib/GetRelatedGuarantees";

import type {
	CompanyInputDto,
	DeclarationsInputDto,
	EstablishmentsInputDto,
	PricingResponseDto,
	RelatedActivityGuaranteesDto,
	SubscriberInputDto,
	SubscriptionInputDto,
} from "@repos/mrp-dtos";

export const formatSubscriptionInputData = ({
	partnershipId,
	pricingData,
	formStoreData,
}: {
	partnershipId: string;
	pricingData: PricingResponseDto;
	formStoreData: FormStoreState;
}): SubscriptionInputDto => {
	const companyInfo: CompanyInputDto = {
		siren: formStoreData.sirenPage.company!.siren,
		companyName: formStoreData.sirenPage.company!.companyName,
		nafCode: formStoreData.sirenPage.company!.nafCode,
		creationDate: formStoreData.sirenPage.company!.creationDate,
		businessTypeLabel: formStoreData.sirenPage.company!.legalStatus.label ?? "",
		businessTypeCode: formStoreData.sirenPage.company!.legalStatus.code,
		principalActivityId: formStoreData.activityPage.principalActivity!.id,
		address: {
			streetLabel: formStoreData.establishments.mainEstablishment!.streetLabel,
			postalCode: formStoreData.establishments.mainEstablishment!.postalCode,
			city: formStoreData.establishments.mainEstablishment!.city,
			country: formStoreData.establishments.mainEstablishment!.country,
		},
	};
	const insuredEstablishments: EstablishmentsInputDto =
		formStoreData.establishments.insuredEstablishments!.map((establishment) => {
			const activityOptions = formStoreData.chosenOptions.chosenOptions?.find(
				(option) =>
					establishment.relatedActivityId.includes(option.activityId) &&
					establishment.siret === option.establishmentId,
			);

			return {
				siret: establishment.siret,
				activitiesIds: establishment.relatedActivityId,
				address: {
					streetLabel: establishment.streetLabel,
					postalCode: establishment.postalCode,
					city: establishment.city,
					country: establishment.country,
				},
				turnover: establishment.turnover,
				area: establishment.surfaceArea,
				numberOfEmployees: establishment.numberOfEmployees,
				occupationStatus: establishment.occupationStatus,
				professionalOperatingContent: establishment.operationalContentValue,
				stockPurchasedValueExcludingTax:
					establishment.stockValueExclVatPurchase,
				contractualLimitOfCompensationPerM2:
					establishment.contractualIndemnityLimitPerSqm,
				chosenOptions:
					activityOptions?.chosenOptions.map((option) => ({
						guaranteeValue: option.guaranteeValue,
						guaranteeType: option.guaranteeType as GuaranteeType,
					})) || [],
			};
		});
	//merge declarations with old declarations and dromcom declarations

	const declarations: DeclarationsInputDto =
		formStoreData.declarations.declarationsArray!.concat(
			formStoreData.declarationsDromCom.declarationsArray!,
			formStoreData.oldDeclarations.declarationsArray!,
		);

	const companyLeaderInfo: SubscriberInputDto = {
		civility: formStoreData.quote.civiliteDirigeant!,
		lastName: formStoreData.quote.nomDirigeant!,
		firstName: formStoreData.quote.prenomDirigeant!,
		email: formStoreData.quote.emailDirigeant!,
		phoneNumber: formStoreData.quote.telPortableDirigeant!,
		birthDate: formStoreData.quote.dateNaissanceDirigeant!,
	};
	const totalEstablishmentsTurnover = insuredEstablishments.reduce(
		(acc, establishment) => acc + establishment.turnover,
		0,
	);
	const relatedActivitiesGuarantees: RelatedActivityGuaranteesDto =
		insuredEstablishments.map((establishment) => {
			const establishmentChosenOptions =
				formStoreData.chosenOptions.chosenOptions?.find(
					(option) =>
						establishment.activitiesIds.includes(option.activityId) &&
						establishment.siret === option.establishmentId,
				);
			return getRelatedGuarantees(
				establishmentChosenOptions?.chosenOptions || [],
			);
		});

	const subscriptionData: SubscriptionInputDto = {
		companyInfo,
		partnershipId: partnershipId,
		establishments: insuredEstablishments,
		declarations,
		companyLeaderInfo,
		desiredEffectiveDate: formStoreData.quote.desiredEffectiveDate!,
		splittingOption: formStoreData.quote.splittingOption!,
		paymentMethod: formStoreData.quote.paymentMethod!,
		contractDueDate: formStoreData.quote.contractDueDate!,
		deductible: formStoreData.quote.valeurFranchise!,
		fileFees: formStoreData.quote.fileFees!,
		brokerCommission: formStoreData.quote.commissionCourtier!,
		totalTurnover: totalEstablishmentsTurnover,
		relatedActivitiesGuarantees: relatedActivitiesGuarantees,
		//TODO: add this input field in front end or remove it from the schema
		leaseClause: "NONE",
		//TODO: this field should not be in the schema here, it should be in the establishment schema
		occupationStatus: "Owner",
		//TODO: this field should be an array, to be fixed once the schema is updated
		natureOfClaim: "FIRE",
		numberOfClaimsInLastThreeYears:
			formStoreData.numberOfClaimsPage.numberOfClaims!,
		pricing: pricingData,
		isDromCom: formStoreData.hasDromComQuestions,
		hasConfirmedDeclarations: formStoreData.declarations.certify!,
		//TODO: add this input field in front end or remove it from the schema
		judicialLiquidation: false,
		numberOfTheftClaims: 0,
		//TODO: add this input field in front end or remove it from the schema
		falseStatementTermination: false,
		//TODO: add this input field in front end or remove it from the schema
		nonPaymentTermination: false,
		//TODO: extract this value from the declarations array
		electricInstallationsInLocals: "qualified",
		//TODO: extract this value from the declarations array
		mobileExtinguishersInLocals: "qualified",
	};
	return subscriptionData;
};
