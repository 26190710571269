import successIllustration from "@/assets/successIllustration.png";
import {
	AlertDialog,
	AlertDialogAction,
	AlertDialogContent,
	AlertDialogDescription,
	AlertDialogFooter,
	AlertDialogHeader,
} from "@/components/ui/alert-dialog.tsx";

interface ContactModelProps {
	isOpen: boolean;
	onClose: () => void;
	onConfirm?: () => void;
}

export const SuccessModal = ({
	isOpen,
	onClose,
	onConfirm,
}: ContactModelProps) => {
	return (
		<AlertDialog open={isOpen} onOpenChange={(value) => !value && onClose()}>
			<AlertDialogContent className="max-w-2xl gap-12">
				<AlertDialogHeader className="items-center">
					<img className="size-fit" src={successIllustration} />
					<AlertDialogDescription className="flex items-center gap-4 text-2xl font-semibold text-primary">
						Votre demande a été bien enregistrée
					</AlertDialogDescription>
				</AlertDialogHeader>
				<p className="text-center font-bold text-[#333333]">
					Le devis est actuellement en statut &#34;Demande de validation&#34;
					chez As Solutions. Nous reviendrons vers vous dans les plus brefs
					délais.
				</p>
				<AlertDialogFooter className="flex w-full items-center sm:justify-center">
					<AlertDialogAction
						onClick={onConfirm}
						className="flex items-center justify-center px-8"
					>
						Fermer
					</AlertDialogAction>
				</AlertDialogFooter>
			</AlertDialogContent>
		</AlertDialog>
	);
};
