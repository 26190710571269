import { styles } from "@/components/quotePdf/styles";
import { Text } from "@react-pdf/renderer";

import type { SubscriptionDetailsForQuoteDto } from "@repos/mrp-dtos";

const LegalMentions = ({ ...props }: SubscriptionDetailsForQuoteDto) => {
	return (
		<>
			<Text style={[styles.sectionHeader, styles.mt_10]}>MENTIONS LÉGALES</Text>
			<Text style={[styles.bodyText, styles.bold]}>
				{`Compagnie d'assurance`}
			</Text>
			<Text style={[styles.bodyText, styles.mb_10]}>
				{`Les garanties du contrat sont souscrites auprès de La société ACCELERANT Europe SA, compagnie d'assurance agréée
sous le code 3193 et réglementée par la Banque Nationale de Belgique et l'Autorité des Services et Marchés
Financiers. Numéro d’identification de l’entreprise : 0758.632.842. Siège social : Bastion Tower, Place du Champ de
Mars 5, 1050 Bruxelles, Belgique.`}
			</Text>
			<Text style={[styles.bodyText, styles.bold]}>{`Distributeur`}</Text>
			<Text style={[styles.bodyText, styles.mb_10]}>
				{`As Solutions Société par Actions Simplifiée (SAS) / capital : 2 159 000 € -RCS Paris 908 499 056 - NAF 6622Z - APE
6622Z – courtier d’assurances ORIAS 22000804`}
			</Text>
			<Text style={[styles.bodyText, styles.bold]}>{`Assistance`}</Text>
			<Text style={[styles.bodyText, styles.mb_10]}>
				{`Mutuaide Assistance - Entreprise régie par le code des assurances. Société anonyme au capital de 12.558.240 €.
Siège social : 126 rue de la Piazza - 93160 NOISY-LE-GRAND RCS B 480 118 587 Bobigny`}
			</Text>
			{props.isPJChosen && (
				<>
					<Text style={[styles.bodyText, styles.bold]}>
						{`Protection juridique`}
					</Text>

					<Text style={[styles.bodyText, styles.mb_10]}>
						{`CFDP - 54 cours du Médoc - 33300 Bordeaux - SIREN 958506156 - Code APE 6512Z - SA a conseil d’administration
(S.A.I.) sous le contrôle de l'ACPR (Autorité de Contrôle Prudentiel et de Résolution - 4 place de Budapest, CS 92459,
75436 Paris Cedex 09)`}
					</Text>
				</>
			)}
		</>
	);
};

export default LegalMentions;
