import type { OptionType } from "@/components/inputs/SingleSelectCombobox.tsx";
import type { ActivityPageInputDto } from "@/pages/form/schemas";
import type { FieldValues } from "react-hook-form";
import { type ReactNode } from "react";
import PriceInput from "@/components/inputs/PriceInput.tsx";
import { SingleSelectCombobox } from "@/components/inputs/SingleSelectCombobox.tsx";
import { type UseControllerProps } from "react-hook-form";

interface ActivityInputProps<T extends FieldValues, U extends OptionType> {
	options: U[];
	activityFormFieldName: UseControllerProps<T>["name"];
	activityFormFieldLabel: string;
	turnoverFormFieldName: UseControllerProps<T>["name"];
	turnoverFormFieldLabel: string;
	rightIcon?: ReactNode;
	index?: number;
	onItemSelect: (item: string) => void;
}

export function ActivityInput<U extends OptionType>({
	onItemSelect,
	options,
	activityFormFieldName,
	activityFormFieldLabel,
	turnoverFormFieldName,
	turnoverFormFieldLabel,
	rightIcon,
	index,
}: ActivityInputProps<ActivityPageInputDto, U>) {
	return (
		<div className="mb-4 w-full overflow-hidden rounded-md border border-gray-200">
			<div className="flex items-center justify-between border-b border-[#E4E4E4] bg-gray-50 p-4">
				<h2 className="text-xl font-semibold">
					{index
						? `Activité n° ${String(index + 1).padStart(2, "0")}`
						: activityFormFieldLabel}
				</h2>
				{rightIcon}
			</div>
			<div className="space-y-4 p-4">
				<div className="grid grid-cols-1 gap-4 md:grid-cols-2">
					<SingleSelectCombobox<
						ActivityPageInputDto,
						{
							value: string;
							label: string;
						}
					>
						className="w-full"
						label={"Activité de l’entreprise"}
						options={options}
						name={activityFormFieldName}
						onValueChange={(value) => {
							onItemSelect(value);
						}}
						required
					/>
					<PriceInput
						label={turnoverFormFieldLabel}
						name={turnoverFormFieldName}
						endorment="€"
						required
					/>
				</div>
			</div>
		</div>
	);
}
