import { Card, CardContent } from "@/components/ui/card.tsx";
import { cn } from "@/utils";

import type { NumberOfClaimsInLast3YearsDto } from "@repos/mrp-dtos";

interface NumberOFClaimsCardProps {
	isSelected: boolean;
	numberOfClaimsValue: NumberOfClaimsInLast3YearsDto;
}

export const NumberOFClaimsCard = ({
	isSelected,
	numberOfClaimsValue,
}: NumberOFClaimsCardProps) => {
	let numberValue = 0;
	let numberOfClaimsLabel = "Aucun";
	switch (numberOfClaimsValue) {
		case "ZERO":
			numberValue = 0;
			numberOfClaimsLabel = "Aucun";
			break;
		case "ONE":
			numberValue = 1;
			numberOfClaimsLabel = "Un";
			break;
		case "TWO":
			numberValue = 2;
			numberOfClaimsLabel = "Deux";
			break;
		case "THREE":
			numberValue = 3;
			numberOfClaimsLabel = "Trois";
			break;
		case "MORETHANTHREE":
			numberValue = 4;
			numberOfClaimsLabel = "Quatre ou plus";
			break;
	}
	return (
		<Card
			className={cn(
				"w-[150px] items-center justify-center rounded-lg border-2 xl:w-[200px]",
				isSelected
					? "border-primary bg-[#FFF6F6]"
					: "border-[#E4E4E4] bg-white text-[#333333]",
			)}
		>
			<CardContent className="flex flex-col items-center justify-center p-8 text-[#333333]">
				<div
					className={cn(
						"spacing-8 relative rounded-[200px] border-4 p-8",
						isSelected ? "border-primary" : "border-[#333333]",
					)}
				>
					<p
						className={cn(
							"absolute right-1/2 top-1/2 -translate-y-1/2 translate-x-1/2 transform text-2xl font-medium",
							isSelected ? "text-primary" : "text-[#333333]",
						)}
					>
						{numberValue}
					</p>
				</div>
				<div className="text-center">
					<h1
						className={cn(
							"text-md text-nowrap font-bold leading-6 md:text-3xl md:leading-10 lg:text-lg",
						)}
					>
						{numberOfClaimsLabel}
					</h1>
				</div>
			</CardContent>
		</Card>
	);
};
