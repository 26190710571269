import { useState } from "react";
import { Card, CardContent, CardHeader } from "@/components/ui/card.tsx";
import { Dialog, DialogTrigger } from "@/components/ui/dialog.tsx";
import {
	DisplayDateField,
	DisplayInputField,
} from "@/pages/form/1/components/3/DisplayInputField.tsx";
import { EditMainEstablishmentAddressModal } from "@/pages/form/1/components/3/EditMainEstablishmentAddressModal.tsx";
import { formStore } from "@/pages/form/stores/formStore.ts";
import { addSpacingBetweenThousandsToString } from "@/utils";
import { FaBuilding } from "react-icons/fa6";
import { MdModeEditOutline } from "react-icons/md";

export function MainEstablishmentDetailsCard() {
	const {
		creationDate,
		siren,
		nafCode,
		companyName,
		mainEstablishmentDetails,
		legalStatus,
	} = formStore((state) => ({
		creationDate: state.sirenPage.company?.creationDate,
		siren: state.sirenPage.company?.siren,
		nafCode: state.sirenPage.company?.nafCode,
		companyName: state.sirenPage.company?.companyName,
		mainEstablishmentDetails: state.establishments.mainEstablishment,
		legalStatus: state.sirenPage.company?.legalStatus,
	}));
	const [isDialogOpen, setIsDialogOpen] = useState(false);
	return (
		<Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
			<Card className="flex w-full flex-col gap-4 rounded-2xl border-gray-200 bg-white">
				<CardHeader className="flex flex-row items-center justify-between rounded-2xl rounded-b-none bg-[#023048]">
					<div className="company-infos flex items-center gap-4">
						<div className="flex h-12 w-12 items-center justify-center rounded-full bg-white">
							<FaBuilding className="size-8 fill-[#023048]" />
						</div>
						<div className="flex flex-col gap-1">
							<p className="text-lg font-bold text-white">
								{companyName} |{" "}
								{addSpacingBetweenThousandsToString(siren || "")}
							</p>
							<p className="text-sm text-white">
								{mainEstablishmentDetails?.streetLabel}
								{", "}
								{mainEstablishmentDetails?.postalCode}{" "}
								{mainEstablishmentDetails?.city}
								{" , "}
								{mainEstablishmentDetails?.country}
							</p>
						</div>
					</div>
					<div className="button flex items-center gap-4">
						<DialogTrigger>
							<MdModeEditOutline className="size-6 fill-white" />
						</DialogTrigger>
						<EditMainEstablishmentAddressModal
							closeDialog={() => {
								setIsDialogOpen(false);
							}}
						/>
					</div>
				</CardHeader>
				<CardContent className="grid grid-cols-3 gap-x-6 gap-y-2 px-8">
					<DisplayInputField
						label="Rue"
						value={mainEstablishmentDetails?.streetLabel}
					/>
					<DisplayInputField
						label="Code Postal"
						value={mainEstablishmentDetails?.postalCode}
					/>
					<DisplayInputField
						label="Ville"
						value={mainEstablishmentDetails?.city}
					/>
					<DisplayDateField label="Date de création" value={creationDate} />
					<DisplayInputField label="Code NAF" value={nafCode} />
					<DisplayInputField
						label="Forme juridique"
						value={legalStatus?.label}
					/>
				</CardContent>
			</Card>
		</Dialog>
	);
}
