import type { GuaranteeOptionalType } from "@/constants";
import type { EstablishmentDetailsPageDto } from "@/pages/form/schemas";
import { DAMAGE_TYPES, LIABILITY_TYPES } from "@/constants";
import { z } from "zod";

import { calculatePJGuaranteeValue } from "./CalculatePJ";
import { getGuaranteeTypeName } from "./GuaranteeTypeLabels";

export const CreateSchemasGuaranteesAmount = (
	establishments: EstablishmentDetailsPageDto,
) => {
	return z.object({
		chosenOptions: z.object({
			chosenOptions: z
				.array(
					z
						.object({
							activityId: z.string(),
							establishmentId: z.string(),
							chosenOptions: z.array(
								z.object({
									guaranteeType: z.preprocess(
										(val) => {
											if (typeof val === "number") {
												return DAMAGE_TYPES[val];
											}
											return val;
										},
										z
											.enum(DAMAGE_TYPES)
											.refine((value): value is GuaranteeOptionalType =>
												DAMAGE_TYPES.includes(value),
											),
									),
									guaranteeValue: z.union([
										z.number({
											required_error:
												"Veuillez saisir le montant de la garantie",
										}),
										z.string().transform((val) => {
											return parseFloat(val);
										}),
									]),
								}),
							),
						})
						.superRefine((option, ctx) => {
							option.chosenOptions.forEach((chosenOption, index) => {
								const guaranteeType = chosenOption.guaranteeType;

								if (
									LIABILITY_TYPES.includes(
										guaranteeType as (typeof LIABILITY_TYPES)[number],
									)
								) {
									return;
								}

								const currentActivityIndex = ctx.path[2]; // Index of the current activity

								const maxVolGuarantee = establishments.insuredEstablishments[
									currentActivityIndex!
								]
									? (establishments.insuredEstablishments[currentActivityIndex!]
											.operationalContentValue +
											establishments.insuredEstablishments[
												currentActivityIndex!
											].stockValueExclVatPurchase) *
										0.5
									: Infinity;

								const guaranteeValue =
									typeof chosenOption.guaranteeValue === "string"
										? parseFloat(chosenOption.guaranteeValue)
										: chosenOption.guaranteeValue;

								if (
									guaranteeValue === null ||
									!chosenOption.guaranteeValue ||
									(typeof guaranteeValue === "number" && isNaN(guaranteeValue))
								) {
									ctx.addIssue({
										path: ["chosenOptions", index, "guaranteeValue"],
										code: "custom",
										message: "Veuillez saisir le montant de la garantie",
									});
									return;
								}

								const turnover = establishments[0]?.turnover || 0;
								const pjValue =
									guaranteeType === "PJ"
										? calculatePJGuaranteeValue(turnover)
										: undefined;

								// Define max values for different guarantee types
								const maxValues: Partial<
									Record<GuaranteeOptionalType, number>
								> = {
									DDE: maxVolGuarantee,
									BDG: 50000,
									MEF: 10000,
									BDM: 100000,
									MMT: 5000,
									DEL: 100000,
									EEX: 100000,
									VOL: maxVolGuarantee,
								};

								// Special validation for VOL and DDE
								if (
									(guaranteeType === "VOL" || guaranteeType === "DDE") &&
									typeof guaranteeValue === "number" &&
									guaranteeValue > maxVolGuarantee
								) {
									ctx.addIssue({
										path: ["chosenOptions", index, "guaranteeValue"],
										code: "custom",
										message: `Le montant de garantie ${getGuaranteeTypeName(
											guaranteeType,
										)} ne peut pas dépasser 50% de la valeur du contenu professionnel`,
									});
									return;
								}

								// PJ validation
								if (
									guaranteeType === "PJ" &&
									chosenOption.guaranteeValue !== pjValue
								) {
									ctx.addIssue({
										path: ["chosenOptions", index, "guaranteeValue"],
										code: "custom",
										message: `Le montant de garantie PJ doit être ${pjValue}€ en fonction du chiffre d'affaires.`,
									});
								}

								// Other max value validations
								const max = maxValues[guaranteeType];
								if (
									max &&
									guaranteeType !== "VOL" &&
									guaranteeType !== "DDE" &&
									typeof guaranteeValue === "number" &&
									guaranteeValue > max
								) {
									ctx.addIssue({
										path: ["chosenOptions", index, "guaranteeValue"],
										code: "custom",
										message: `La valeur maximale de ${getGuaranteeTypeName(
											guaranteeType,
										)} est ${max}€.`,
									});
								}

								// Special validations for SFM and EFFONDREMENT
								if (
									guaranteeType === "SFM" &&
									typeof guaranteeValue === "string" &&
									guaranteeValue !== 0
								) {
									ctx.addIssue({
										path: ["chosenOptions", index, "guaranteeValue"],
										code: "custom",
										message:
											"Le montant de garantie SFM doit être 'indice x 6'",
									});
								}

								if (
									guaranteeType === "EFFONDREMENT" &&
									guaranteeValue !== 1500000
								) {
									ctx.addIssue({
										path: ["chosenOptions", index, "guaranteeValue"],
										code: "custom",
										message:
											"Le montant de garantie EFFONDREMENT doit être 1.500.000€",
									});
								}

								// Minimum value validation
								const min = [
									"RCE",
									"RCPRO",
									"PE_INCENDIE",
									"PE_VOL",
									"PVF",
									"VANDALISME",
									"SFM",
								].includes(guaranteeType)
									? 0
									: 1;
								if (
									typeof guaranteeValue === "number" &&
									guaranteeValue < min
								) {
									ctx.addIssue({
										path: ["chosenOptions", index, "guaranteeValue"],
										code: "custom",
										message: `La valeur minimale de ${getGuaranteeTypeName(
											guaranteeType,
										)} est ${min} €.`,
									});
								}
							});
						}),
				)
				.min(1, "Au moins une activité doit être sélectionnée"),
		}),
	});
};
