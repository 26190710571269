import { styles } from "@/components/quotePdf/styles";
import { Image, StyleSheet, Text, View } from "@react-pdf/renderer";

// Sample styles
const localStyles = StyleSheet.create({
	footer: {
		fontFamily: "Roboto",
		fontSize: 8,
		position: "absolute",
		bottom: 0,
		left: 0,
		right: 0,
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-between",
		paddingHorizontal: 15,
		paddingBottom: 5,
	},
	footerTextContainer: {
		display: "flex",
		alignItems: "flex-end",
		justifyContent: "space-between",
		flexDirection: "row",
		paddingHorizontal: 15,
		width: "100%",
		height: "100%",
	},
	footerText: {
		...styles.regular,
		fontSize: 8,
		position: "absolute",
		bottom: 5,
		left: 0,
		right: 0,
		paddingHorizontal: 15,
	},
	pageNumberText: {
		...styles.regular,
		fontSize: 8,
	},
	footerImage: {
		width: 180,
		height: 100,
		objectFit: "fill",
		objectPositionX: "center",
		objectPositionY: "center",
	},
	header: {
		position: "absolute",
		top: 0,
		right: 0,
		width: "100%",
	},
	headerLogoContainer: {
		position: "absolute",
		top: 25,
		right: 1,
		width: "100%",
	},
	headerImage: {
		width: "100%",
		height: 100,
		objectFit: "fill",
		objectPositionX: "center",
		objectPositionY: "center",
	},
	headerLogo: {
		width: 200,
		height: "100%",
		objectFit: "fill",
		objectPositionX: "center",
		objectPositionY: "center",
	},
});

// Create Document Component
export const HeaderFooter = () => (
	<>
		<View style={localStyles.header} fixed>
			<Image src="/images/quote_header3.png" style={localStyles.headerImage} />
		</View>
		<View
			style={localStyles.footer}
			fixed
			render={({ pageNumber }) => (
				<>
					<Text
						style={{
							flex: 6,
						}}
					>
						As Solutions - Société par Actions Simplifiée (SAS) / capital : 2
						159 000€ RCS Paris 908 499 056 NAF 6622Z APE 6622Z – courtier
						d’assurances ORIAS 22000804. Entreprise régie par le Code des
						assurances et soumise au contrôle de l’ACPR 4 Place de Budapest – CS
						92459 – 75436 paris Cedex 09. RC Professionnelle et garantie
						financière conformes aux articles L512-6 et L512-7 du Code des
						assurances
					</Text>
					<Text
						style={{
							flex: 1,
							alignSelf: "flex-end",
							textAlign: "right",
						}}
					>
						P.{pageNumber}
					</Text>
				</>
			)}
		/>
	</>
);
