import type {
	PrimaryEstablishmentEditDto,
	SirenPageDto,
} from "@/pages/form/schemas";
import { AddressInput } from "@/components/inputs/AdressInput.tsx";
import { SelectInput } from "@/components/inputs/SelectInput.tsx";
import { TextInput } from "@/components/inputs/TextInput.tsx";
import { Button } from "@/components/ui/button.tsx";
import {
	DialogContent,
	DialogDescription,
	DialogFooter,
	DialogHeader,
	DialogTitle,
} from "@/components/ui/dialog.tsx";
import { primaryEstablishmentEditSchema } from "@/pages/form/schemas";
import { formStore } from "@/pages/form/stores/formStore.ts";
import { trpc } from "@/utils";
import { zodResolver } from "@hookform/resolvers/zod";
import { FormProvider, useForm } from "react-hook-form";

interface EditMainEstablishmentAddressModalProps {
	closeDialog: () => void;
}

export const EditMainEstablishmentAddressModal = ({
	closeDialog,
}: EditMainEstablishmentAddressModalProps) => {
	const {
		sirenPage,
		setSirenPage,
		setMainEstablishmentDetailedAddress,
		mainEstablishmentDetailedAddress,
		legalStatus,
	} = formStore((state) => ({
		sirenPage: state.sirenPage,
		setSirenPage: state.setSirenPage,
		setMainEstablishmentDetailedAddress:
			state.setMainEstablishmentDetailedAddress,
		mainEstablishmentDetailedAddress: state.establishments.mainEstablishment,

		legalStatus: state.sirenPage.company?.legalStatus,
	}));

	const methods = useForm<PrimaryEstablishmentEditDto>({
		resolver: zodResolver(primaryEstablishmentEditSchema),
		defaultValues: {
			address: mainEstablishmentDetailedAddress,
			nafCode: sirenPage.company?.nafCode || "",
			legalStatus: legalStatus,
		},
	});
	const onSubmit = (data: PrimaryEstablishmentEditDto) => {
		setMainEstablishmentDetailedAddress(data.address);
		setSirenPage({
			...(sirenPage as SirenPageDto),
			company: {
				...(sirenPage.company as SirenPageDto["company"]),
				nafCode: data.nafCode,
				legalStatus: data.legalStatus,
			},
		});
		closeDialog();
	};
	const legalStatusOptionsQuery = trpc.config.listBusinessTypes.useQuery();
	const formattedLegalStatusOptions = legalStatusOptionsQuery.data?.map(
		(businessType) => ({
			value: businessType.code,
			label: businessType.label,
		}),
	);

	return (
		<DialogContent className="max-w-2xl">
			<FormProvider {...methods}>
				<form
					id="main-establishment-details-form"
					onSubmit={(e) => {
						e.preventDefault();
						methods.handleSubmit(onSubmit)();
						e.stopPropagation();
					}}
				>
					<DialogHeader>
						<DialogTitle>Modifier les informations du siège social</DialogTitle>
						<DialogDescription>
							Veuillez remplir les informations nécessaires pour modifier les
							informations du siège social
						</DialogDescription>
					</DialogHeader>
					<div className="grid gap-4 py-4">
						<SelectInput<
							PrimaryEstablishmentEditDto,
							{ value: string; label: string }
						>
							label="Forme juridique"
							name="legalStatus.code"
							options={formattedLegalStatusOptions || []}
							required
							customOnChange={(value: string) => {
								const selectedOption = formattedLegalStatusOptions?.find(
									(option) => option.value === value,
								);
								if (selectedOption) {
									methods.setValue("legalStatus", {
										code: selectedOption.value,
										label: selectedOption.label,
									});
								}
							}}
						/>
						<TextInput<PrimaryEstablishmentEditDto>
							label="Code NAF"
							name="nafCode"
							required
						/>
						<AddressInput<PrimaryEstablishmentEditDto>
							label="Adresse"
							name="address.streetLabel"
							cityProps={{ name: "address.city" }}
							postalCodeProps={{ name: "address.postalCode" }}
							countryProps={{ name: "address.country" }}
							required
						/>
					</div>
					<DialogFooter>
						<Button
							type="submit"
							id="main-establishment-details-form"
							form="main-establishment-details-form"
						>
							Enregistrer
						</Button>
					</DialogFooter>
				</form>
			</FormProvider>
		</DialogContent>
	);
};
