import type {
	EstablishmentSelectInputDTO,
	EstablishmentSelectPageDto,
} from "@/pages/form/schemas";
import { useCallback, useEffect, useState } from "react";
import PlusButtonIcon from "@/assets/PlusButtonIcon.svg";
import { Button } from "@/components/ui/button.tsx";
import {
	Command,
	CommandEmpty,
	CommandGroup,
	CommandInput,
	CommandItem,
	CommandList,
} from "@/components/ui/command";
import { Dialog, DialogTrigger } from "@/components/ui/dialog.tsx";
import {
	FormControl,
	FormField,
	FormItem,
	FormLabel,
	FormMessage,
} from "@/components/ui/form";
import {
	Popover,
	PopoverContent,
	PopoverTrigger,
} from "@/components/ui/popover";
import { MAX_ESTABLISHMENTS } from "@/config";
import { AddSecondaryEstablishmentModalContent } from "@/pages/form/1/components/3/AddSecondaryEstablishmentModalContent.tsx";
import { formStore } from "@/pages/form/stores/formStore.ts";
import {
	addSpacingBetweenThousandsToString,
	cn,
	formatDetailedAddress,
} from "@/utils";
import { Check } from "lucide-react";
import { useFormContext, useWatch } from "react-hook-form";
import { FaBuilding } from "react-icons/fa6";

import type { MatchingEtablissementDto } from "@repos/mrp-dtos";

interface CustomAddressInputCompanyProps {
	label: string;
	placeholder?: string;
	disabled?: boolean;
	required?: boolean;
	defaultValue?: string;
	onItemSelect?: (value: EstablishmentSelectInputDTO) => void;
	items?: MatchingEtablissementDto[];
	maxSelections?: number;
}

export function EstablishmentMultiSelect({
	label,
	placeholder,
	disabled,
	required,
	defaultValue,
	items,
	maxSelections,
}: CustomAddressInputCompanyProps) {
	const [inputValue, setInputValue] = useState<string>(defaultValue || "");
	const [isPopoverOpen, setIsPopoverOpen] = useState(false);
	const [
		isAddSecondaryEstablishmentModalOpen,
		setIsAddSecondaryEstablishmentModalOpen,
	] = useState(false);
	const {
		appendInsuredEstablishments,
		removeInsuredEstablishments,
		establishments,
	} = formStore((state) => ({
		appendInsuredEstablishments: state.appendInsuredEstablishments,
		removeInsuredEstablishments: state.removeInsuredEstablishments,
		establishments: state.establishments,
	}));
	const handleInputChange = useCallback((search: string) => {
		setInputValue(search);
	}, []);
	const { control, setValue } = useFormContext<EstablishmentSelectPageDto>();
	const insuredEstablishments = useWatch({
		name: "insuredEstablishments",
		control,
	});

	const toggleOption = (
		selectedEstablishments: EstablishmentSelectInputDTO[],
		option: EstablishmentSelectInputDTO,
	) => {
		const hasValue = selectedEstablishments.some(
			(v) => v.siret === option.siret,
		);
		let newSelectedValues: EstablishmentSelectInputDTO[] = [
			...selectedEstablishments,
		];
		if (hasValue) {
			removeInsuredEstablishments(option.siret);
			newSelectedValues = selectedEstablishments.filter(
				(v) => v.siret !== option.siret,
			);
		} else if (maxSelections && selectedEstablishments.length < maxSelections) {
			newSelectedValues = [...selectedEstablishments, option];
			appendInsuredEstablishments(option);
		}
		setValue("insuredEstablishments", newSelectedValues);
	};
	const handleAddSecondaryEstablishmentButtonDisabled =
		insuredEstablishments.length >= MAX_ESTABLISHMENTS;

	useEffect(() => {
		if (establishments.insuredEstablishments?.length) {
			setValue("insuredEstablishments", establishments.insuredEstablishments);
		}
	}, [establishments.insuredEstablishments, setValue]);
	return (
		<>
			{establishments.insuredEstablishments?.map((e) => (
				<div
					key={e.siret}
					className="flex items-center justify-start gap-3 rounded-lg border border-[#E4E4E4] bg-[#F8F5F5] px-4 py-2"
				>
					<div className="flex h-12 w-12 items-center justify-center rounded-full bg-[#2178B2]">
						<FaBuilding className="size-8 fill-[#fff]" />
					</div>
					<div className="flex flex-col">
						<p>Établissement | {e.siret}</p>
						<span>{e.streetLabel}</span>
					</div>
				</div>
			))}
			<FormField
				control={control}
				name={"insuredEstablishments"}
				render={({ field }) => {
					const selectedValues = field.value;
					return (
						<FormItem className={cn("flex w-full flex-col")}>
							<FormLabel className="font-medium text-[#333333]">
								<span>{label}</span>{" "}
								{required && <span className="text-red-500">*</span>}
							</FormLabel>
							<Popover open={isPopoverOpen} onOpenChange={setIsPopoverOpen}>
								<PopoverTrigger asChild>
									<FormControl>
										<Button
											role="combobox"
											disabled={disabled}
											className={cn(
												"h-12 w-full items-center justify-between gap-2 overflow-hidden overflow-ellipsis whitespace-nowrap rounded-md border-2 border-primary !p-0 !pl-5 " +
													"border-gray-300 bg-white p-5 font-normal text-black hover:bg-gray-100 disabled:opacity-50",
												!inputValue && "text-muted-foreground",
												disabled && "cursor-not-allowed opacity-50",
											)}
											onClick={() => {
												setIsPopoverOpen((prev) => !prev);
											}}
										>
											{`${insuredEstablishments[0] ? insuredEstablishments[0]?.city + " | " + addSpacingBetweenThousandsToString(insuredEstablishments[0].siret) : placeholder}`}
											<p className="!m-0 mb-2 flex h-12 items-center justify-start gap-3 rounded-lg bg-primary px-5 text-white">
												<img src={PlusButtonIcon} className="size-4" />
												Ajouter un établissement
											</p>
										</Button>
									</FormControl>
								</PopoverTrigger>
								<PopoverContent
									className="w-[100%] p-0 lg:min-w-[450px]"
									align="start"
								>
									<Command
										className={cn(
											"w-full justify-between overflow-hidden overflow-ellipsis whitespace-nowrap rounded-2xl border " +
												"border-gray-300 bg-white p-3 font-normal text-black disabled:opacity-50",
											!inputValue && "text-muted-foreground",
											disabled && "cursor-not-allowed opacity-50",
										)}
									>
										<CommandInput
											placeholder={placeholder}
											onValueChange={handleInputChange}
											role="combobox"
											className="border-b-0"
											value={inputValue || ""}
										/>
										<CommandList>
											<CommandEmpty>Aucune adresse trouvée.</CommandEmpty>
											<CommandGroup>
												{items?.map((option, index) => (
													<CommandItem
														value={option.siret}
														key={index}
														onSelect={() => {
															toggleOption(selectedValues, {
																siret: option.siret,
																streetLabel: `${option.detailedAddress.streetLabel}`,
																postalCode:
																	option.detailedAddress.postalCode || "",
																city: option.detailedAddress.city || "",
																country: "France",
															});
														}}
														className="font-normal"
													>
														<Check
															className={cn(
																"mr-2 h-4 w-4",
																selectedValues.some(
																	(v) => v.siret === option.siret,
																)
																	? "opacity-100"
																	: "opacity-0",
															)}
														/>
														{formatDetailedAddress(option.detailedAddress)} |{" "}
														{addSpacingBetweenThousandsToString(option.siret)}
													</CommandItem>
												))}
											</CommandGroup>
											<CommandGroup>
												{/*The following items are not selectable, they are just for extra padding so that if the list has only 1 item
										the "i cant find my company" button does not overlap the item.
										*/}
												<CommandItem />
												<CommandItem />
												<CommandItem />

												<CommandItem
													className={cn(
														"border-t border-t-gray-300 font-medium text-[#598EAD] data-[selected=true]:bg-white data-[selected=true]:bg-opacity-100 data-[selected=true]:text-[#598EAD]",
														items &&
															items.length > 0 &&
															"fixed bottom-0 left-0 right-0 z-[80] border bg-white underline hover:bg-white",
													)}
												>
													<Dialog
														open={isAddSecondaryEstablishmentModalOpen}
														onOpenChange={(v) => {
															setIsAddSecondaryEstablishmentModalOpen(v);
														}}
													>
														<DialogTrigger
															asChild
															disabled={
																handleAddSecondaryEstablishmentButtonDisabled
															}
															className="p-0"
														>
															<Button
																className="w-full justify-start p-0 hover:bg-transparent"
																variant="ghost"
															>
																Vous ne trouvez pas l’établissement ? Ajoutez-le
																manuellement
															</Button>
														</DialogTrigger>
														<AddSecondaryEstablishmentModalContent
															closeDialog={() => {
																setIsAddSecondaryEstablishmentModalOpen(false);
															}}
														/>
													</Dialog>
												</CommandItem>
											</CommandGroup>
										</CommandList>
									</Command>
								</PopoverContent>
							</Popover>
							<FormMessage />
						</FormItem>
					);
				}}
			/>
		</>
	);
}
