import { styles } from "@/components/quotePdf/styles";
import { Text, View } from "@react-pdf/renderer";

import type { ActivityDto } from "@repos/mrp-dtos";

const ActivityDetail = ({ activities }: { activities: ActivityDto[] }) => (
	<View style={{ display: "flex", flexDirection: "column", gap: 10 }}>
		{activities.map((activity, index) => (
			<View key={index}>
				<Text style={[styles.bodyText, styles.max1Line]}>
					<Text style={[styles.bodyText, styles.bold]}>
						- Activité {index === 0 ? "principale" : index + 1} :
					</Text>{" "}
					{activity.fieldLabel}
				</Text>
				<Text style={[styles.bodyText, styles.max1Line, { paddingLeft: 15 }]}>
					o {activity.nafCodeLabel}
				</Text>
				{/*TODO: this was here before i added typesafety, what's this @kais/baha*/}
				{/*{activity.precisionClause && (*/}
				{/*	<Text style={[styles.bodyText, styles.max1Line, { paddingLeft: 15 }]}>*/}
				{/*		o Précision{" "}*/}
				{/*		{index === 0 ? "activité principale" : `activité ${index + 1}`} :{" "}*/}
				{/*		{activity.precisionClause}*/}
				{/*	</Text>*/}
				{/*)}*/}
			</View>
		))}
	</View>
);

export default ActivityDetail;
