import { Label } from "@/components/ui/label.tsx";
import { format } from "date-fns";

interface DisplayInputFieldProps {
	label: string;
	value: string | undefined;
}

export const DisplayInputField = ({ label, value }: DisplayInputFieldProps) => {
	return (
		<div className="flex w-full flex-col">
			<Label className="text-sm font-normal text-[#595959]">{label}</Label>
			<span className="text-lg font-medium text-[#333333]">{value}</span>
		</div>
	);
};

interface DisplayDateFieldProps {
	label: string;
	value: Date | undefined;
}

export const DisplayDateField = ({ label, value }: DisplayDateFieldProps) => {
	return (
		<div className="flex w-full flex-col">
			<Label className="text-sm font-normal text-[#595959]">{label}</Label>
			<span className="text-lg font-medium text-[#333333]">
				{value && format(value, "dd/MM/yyyy")}
			</span>
		</div>
	);
};
