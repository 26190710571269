import { useState } from "react";
import { Button } from "@/components/ui/button";
import {
	Dialog,
	DialogClose,
	DialogContent,
	DialogDescription,
	DialogFooter,
	DialogHeader,
	DialogTitle,
	DialogTrigger,
} from "@/components/ui/dialog";
import { formStore } from "@/pages/form/stores/formStore";
import { cn, trpc } from "@/utils";
import { Pencil, X } from "lucide-react";

import type { ApiOutputs } from "@repos/mrp-api";

type MainActivitySectorProps = {
	// TODO / REWORK: This should be a generic type
	onSectorChange?: (sector: ApiOutputs["sectors"]["getSectors"][0]) => void;
};
export const MainActivitySectorCard = ({
	onSectorChange,
}: MainActivitySectorProps) => {
	const [isOpen, setIsOpen] = useState(false);
	const {
		activityPage,
		setActivityPage,
		setMainSectorActivity,
		setMainActivity,
	} = formStore((state) => ({
		activityPage: state.activityPage,
		setActivityPage: state.setActivityPage,
		setMainSectorActivity: state.setMainSectorActivity,
		setMainActivity: state.setMainActivity,
	}));

	const sector = activityPage.sector;

	const utils = trpc.useUtils();

	const { data: sectors } = trpc.sectors.getSectors.useQuery();

	const [selectedSector, setSelectedSector] = useState(sector?.id ?? "");

	const handleSectorChange = (sectorId: string) => {
		setSelectedSector(sectorId);
	};

	const handleConfirm = () => {
		if (selectedSector && sectors) {
			const newSector = sectors.find((s) => s.id === selectedSector);

			setMainSectorActivity(newSector);

			onSectorChange?.(newSector!);
			setActivityPage({
				sector: newSector!,
				principalActivity: {
					id: "",
					turnover: 0,
					label: "",
					code: "",
				},
				secondaryActivities: [],
			});

			setMainActivity(undefined);

			utils.activities.getActivitiesBySector.invalidate({
				sectorId: newSector?.id,
			});
		}
		setIsOpen(false);
	};

	return (
		<Dialog open={isOpen} onOpenChange={setIsOpen}>
			<div className="mb-4 w-full overflow-hidden rounded-lg border border-gray-200">
				<div className="flex items-center justify-between bg-gray-50 p-4">
					<div className="flex gap-2">
						<div className="size-12 rounded-full bg-[#4EB1B7] p-2">
							<img
								src={activityPage.sector?.iconUrl}
								className="mx-auto size-7"
								alt="Sector icon"
							/>
						</div>
						<div className="flex flex-col">
							<p className="text-sm text-[#595959]">Secteur d&apos;activité</p>
							<p className="text-lg font-semibold text-[#333]">
								{activityPage.sector?.label}
							</p>
						</div>
					</div>
					<DialogTrigger asChild>
						<Button variant="ghost" size="icon">
							<Pencil className="size-4" />
						</Button>
					</DialogTrigger>
				</div>
			</div>

			<DialogContent className="sm:max-w-xl">
				<DialogHeader className="flex flex-row items-start justify-between">
					<div className="flex flex-col">
						<DialogTitle className="text-xl font-semibold text-primary">
							Modifier le secteur d&apos;activité
						</DialogTitle>
						<DialogDescription className="text-base text-[#595959]">
							Veuillez sélectionner le nouveau secteur d&apos;activité
						</DialogDescription>
					</div>
					<DialogClose>
						<X className="size-6" />
					</DialogClose>
				</DialogHeader>

				<div className="py-12">
					<div className="mx-auto w-full overflow-hidden bg-white">
						<div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
							{sectors?.map((sector) => (
								<label
									key={sector.id}
									className={cn(
										"flex cursor-pointer items-center justify-between rounded-lg p-4 transition-colors",
										selectedSector === sector.id
											? "bg-primary text-white"
											: "border border-gray-200 bg-white text-gray-800 hover:bg-gray-50",
									)}
								>
									<input
										type="radio"
										name="sector"
										value={sector.id}
										checked={selectedSector === sector.id}
										onChange={() => handleSectorChange(sector.id)}
										className="sr-only"
									/>
									<div className="flex items-center gap-2">
										<img
											src={
												selectedSector === sector.id
													? sector.selectedIconUrl
													: sector.iconUrl
											}
											alt={`${sector.label} icon`}
										/>
										<span
											className={cn(
												"font-medium",
												selectedSector === sector.id && "text-white",
											)}
										>
											{sector.label}
										</span>
									</div>
									{selectedSector === sector.id && (
										<svg
											xmlns="http://www.w3.org/2000/svg"
											width="22"
											height="22"
											viewBox="0 0 22 22"
											fill="none"
										>
											<circle cx="11" cy="11" r="10.5" stroke="white" />
											<path
												d="M9.46 12.98L7.0675 10.5875C6.86583 10.3858 6.61833 10.285 6.325 10.285C6.03167 10.285 5.775 10.395 5.555 10.615C5.35333 10.8167 5.2525 11.0733 5.2525 11.385C5.2525 11.6967 5.35333 11.9533 5.555 12.155L8.69 15.29C8.89167 15.4917 9.14833 15.5925 9.46 15.5925C9.77167 15.5925 10.0283 15.4917 10.23 15.29L16.4725 9.0475C16.6742 8.84583 16.775 8.59833 16.775 8.305C16.775 8.01167 16.665 7.755 16.445 7.535C16.2433 7.33333 15.9867 7.2325 15.675 7.2325C15.3633 7.2325 15.1067 7.33333 14.905 7.535L9.46 12.98ZM11 22C9.47833 22 8.04833 21.7111 6.71 21.1332C5.37167 20.5561 4.2075 19.7725 3.2175 18.7825C2.2275 17.7925 1.44393 16.6283 0.8668 15.29C0.288933 13.9517 0 12.5217 0 11C0 9.47833 0.288933 8.04833 0.8668 6.71C1.44393 5.37167 2.2275 4.2075 3.2175 3.2175C4.2075 2.2275 5.37167 1.44357 6.71 0.8657C8.04833 0.288567 9.47833 0 11 0C12.5217 0 13.9517 0.288567 15.29 0.8657C16.6283 1.44357 17.7925 2.2275 18.7825 3.2175C19.7725 4.2075 20.5561 5.37167 21.1332 6.71C21.7111 8.04833 22 9.47833 22 11C22 12.5217 21.7111 13.9517 21.1332 15.29C20.5561 16.6283 19.7725 17.7925 18.7825 18.7825C17.7925 19.7725 16.6283 20.5561 15.29 21.1332C13.9517 21.7111 12.5217 22 11 22Z"
												fill="white"
											/>
										</svg>
									)}
								</label>
							))}
						</div>
					</div>
				</div>

				<DialogFooter className="flex justify-end">
					<Button onClick={handleConfirm}>Confirmer</Button>
				</DialogFooter>
			</DialogContent>
		</Dialog>
	);
};
