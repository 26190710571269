import { useMemo } from "react";
import DevisPdfViewer from "@/pages/devis/DevisPdfViewer";
import { formStore } from "@/pages/form/stores/formStore";
import { getRoute, privateRoutes } from "@/routes/formRoutes.tsx";
import * as Sentry from "@sentry/react";
import { BrowserRouter, Navigate, useRoutes } from "react-router-dom";

const useSentryRoutes = Sentry.wrapUseRoutes(useRoutes);

const Routes = () => {
	const { currentStep, currentSubStep } = formStore((state) => ({
		currentStep: state.currentStep,
		currentSubStep: state.currentSubStep,
	}));
	const currRoute = useMemo(
		() => getRoute(currentStep, currentSubStep),
		[currentStep, currentSubStep],
	);
	const routes = useSentryRoutes([
		...privateRoutes,
		{ path: "/formulaire", element: <Navigate to={currRoute} replace /> },
		{
			path: "/devis-pdf",
			element: <DevisPdfViewer />,
		},
		{ path: "*", element: <Navigate to={currRoute} replace /> },
	]);
	return routes;
};

export const AppRoutes = () => {
	return (
		<BrowserRouter>
			<Routes />
		</BrowserRouter>
	);
};
