import { MAX_ESTABLISHMENTS, MAX_SECONDARY_ACTIVITIES } from "@/config";
import { z } from "zod";

import {
	activityInputSchema,
	birthdayLeaderSchema,
	brokerCommissionSchema,
	citySchema,
	civilityLeaderSchema,
	companyCreationDateSchema,
	companyNameSchema,
	contractDueDateSchema,
	contractualIndemnityLimitPerSqmSchema,
	countrySchema,
	deductibleSchema,
	detailedAddressSchema,
	effectiveDateSchema,
	emailSchema,
	establishmentOccupationStatusSchema,
	establishmentTurnoverSchema,
	fileFeesSchema,
	firstNameLeaderSchema,
	landlinePhoneNumberSchema,
	lastNameLeaderSchema,
	matchingEtablissementSchema,
	nafCodeSchema,
	natureOfClaimSchema,
	numberOfClaimsInLast3YearsSchema,
	numberOfEmployeesSchema,
	paymentMethodSchema,
	phoneNumberSchema,
	postalCodeSchema,
	PricingResponseSchema,
	professionalContentValueSchema,
	sectorIdSchema,
	sectorLabelSchema,
	sectorUrlSchema,
	sirenSchema,
	siretSchema,
	splitOptionSchema,
	stockValueExclVatPurchaseSchema,
	streetLabelSchema,
	surfaceAreaSchema,
	transformedLegalStatusSchema,
} from "@repos/mrp-dtos";

export const sirenPageSchema = z
	.object({
		company: z.object({
			siren: sirenSchema,
			companyName: companyNameSchema,
			creationDate: companyCreationDateSchema,
			nafCode: nafCodeSchema,
			legalStatus: transformedLegalStatusSchema,
			address: detailedAddressSchema.nullish(),
		}),
		companyUnderCreation: z.boolean().optional().default(false),
		mainEstablishmentAddress: detailedAddressSchema.nullish(),
		mainEstablishment: matchingEtablissementSchema.nullish(),
	})
	.refine(
		(data) => {
			if (data.companyUnderCreation === false) {
				return data.company.siren !== undefined && data.company.siren !== "";
			}
			return true;
		},
		{
			message: "Veuillez saisir un numéro SIREN.",
		},
	);
export type SirenPageDto = z.infer<typeof sirenPageSchema>;

export const numberOfClaimsPageSchema = z.object({
	numberOfClaims: numberOfClaimsInLast3YearsSchema,
});

export type NumberOfClaimsPageDto = z.infer<typeof numberOfClaimsPageSchema>;

export const activitySectorSchema = z.object({
	id: sectorIdSchema,
	label: sectorLabelSchema,
	iconUrl: sectorUrlSchema,
	selectedIconUrl: sectorUrlSchema,
});
export type ActivitySectorDto = z.infer<typeof activitySectorSchema>;

export const activityPageInputSchema = z
	.object({
		sector: activitySectorSchema,
		principalActivity: activityInputSchema,
		secondaryActivities: z
			.array(activityInputSchema)
			.max(MAX_SECONDARY_ACTIVITIES),
	})
	.refine(
		(data) => {
			// Check if the principal activity is not in the secondary activities
			const principalActivity = data.principalActivity;
			const secondaryActivities = data.secondaryActivities;
			return !secondaryActivities.some(
				(activity) => activity.id === principalActivity.id,
			);
		},
		{
			path: ["principalActivity.id"],
			message:
				"L'activité principale ne peut pas être une activité secondaire.",
		},
	)
	.refine(
		(data) => {
			// Check if the secondary activities are not duplicated
			const secondaryActivities = data.secondaryActivities;
			const codes = secondaryActivities.map((activity) => activity.id);
			return codes.length === new Set(codes).size;
		},

		{
			path: ["secondaryActivities.0.id"],
			message: "Les activités secondaires ne peuvent pas être en double.",
		},
	)
	.refine(
		(data) => {
			//sum of the turnover of the principal activity and the secondary activities should not exceed 9 000 000
			const principalActivity = data.principalActivity.turnover;
			const secondaryActivities = data.secondaryActivities.reduce(
				(acc, activity) => acc + activity.turnover,
				0,
			);
			return principalActivity + secondaryActivities <= 9000000;
		},
		{
			path: ["principalActivity"],
			message: "Le chiffre d'affaires total ne peut pas dépasser 9 000 000 €.",
		},
	);

export type ActivityPageInputDto = z.infer<typeof activityPageInputSchema>;

export const editSecondaryEstablishmentSchema = z.object({
	siret: siretSchema,
	address: detailedAddressSchema,
});

export type EditSecondaryEstablishmentDTO = z.infer<
	typeof editSecondaryEstablishmentSchema
>;
export const primaryEstablishmentEditSchema = z.object({
	nafCode: nafCodeSchema,
	legalStatus: transformedLegalStatusSchema,
	address: detailedAddressSchema,
});
export type PrimaryEstablishmentEditDto = z.infer<
	typeof primaryEstablishmentEditSchema
>;
export const establishmentSelectInputSchema = z.object({
	siret: siretSchema,
	streetLabel: streetLabelSchema,
	postalCode: postalCodeSchema,
	city: citySchema,
	country: countrySchema,
});
export type EstablishmentSelectInputDTO = z.infer<
	typeof establishmentSelectInputSchema
>;
export const baseEstablishmentSchema = establishmentSelectInputSchema.extend({
	order: z.number(),
	relatedActivityId: z.string({
		required_error: "Veuillez sélectionner une activité.",
	}),
	occupationStatus: establishmentOccupationStatusSchema,
	numberOfEmployees: numberOfEmployeesSchema,
	turnover: establishmentTurnoverSchema,
	surfaceArea: surfaceAreaSchema,
	operationalContentValue: professionalContentValueSchema,
	stockValueExclVatPurchase: stockValueExclVatPurchaseSchema,
	contractualIndemnityLimitPerSqm: contractualIndemnityLimitPerSqmSchema,
});

export type EstablishmentDto = z.infer<typeof newBaseEstablishmentSchema>;

export const newBaseEstablishmentSchema = establishmentSelectInputSchema.extend(
	{
		order: z.number(),
		relatedActivityId: z
			.array(z.string(), { required_error: "Veuillez choisir une activité." })
			.min(1, { message: "Veuillez choisir au moins une activité." }),
		occupationStatus: establishmentOccupationStatusSchema,
		numberOfEmployees: numberOfEmployeesSchema,
		turnover: establishmentTurnoverSchema,
		surfaceArea: surfaceAreaSchema,
		operationalContentValue: professionalContentValueSchema,
		stockValueExclVatPurchase: stockValueExclVatPurchaseSchema,
		contractualIndemnityLimitPerSqm: contractualIndemnityLimitPerSqmSchema,
		hightestActivityId: z.string().optional(),
	},
);
export type NewEstablishmentDto = z.infer<typeof newBaseEstablishmentSchema>;

export const mainEstablishmentSchema = baseEstablishmentSchema.extend({});

export const newMainEstablishmentSchema = newBaseEstablishmentSchema.extend({});

export const insuredEstablishmentsSchema = z.array(newBaseEstablishmentSchema);
export type InsuredEstablishmentsDto = z.infer<
	typeof insuredEstablishmentsSchema
>;
export const establishmentDetailsPageSchema = z.object(
	{
		mainEstablishment: newBaseEstablishmentSchema,
		insuredEstablishments: insuredEstablishmentsSchema,
		totalInsuredEstablishmentsTurnover: establishmentTurnoverSchema.max(
			1000000000,
			"Le chiffre d'affaires total ne peut pas dépasser 10 000 000  €.",
		),
	},
	{
		required_error: "Veuillez ajouter au moins un établissement.",
		invalid_type_error: "Veuillez ajouter au moins un établissement.",
	},
);
export type EstablishmentDetailsPageDto = z.infer<
	typeof establishmentDetailsPageSchema
>;

export const establishmentSelectPageSchema = z.object({
	insuredEstablishments: z
		.array(establishmentSelectInputSchema)
		.min(1, {
			message: "Veuillez sélectionner au moins un établissement.",
		})
		.max(MAX_ESTABLISHMENTS, {
			message: `Vous ne pouvez pas sélectionner plus de ${MAX_ESTABLISHMENTS} établissements.`,
		}),
});
export type EstablishmentSelectPageDto = z.infer<
	typeof establishmentSelectPageSchema
>;

export const claimsPageSchema = z.object({
	claimsArray: z.array(
		z.object({
			natureOfClaim: natureOfClaimSchema,
			establishmentLinkedToClaim: siretSchema,
		}),
	),
});

export type ClaimPageDto = z.infer<typeof claimsPageSchema>;
export const quoteSchema = z.object({
	desiredEffectiveDate: effectiveDateSchema,
	splittingOption: splitOptionSchema,
	paymentMethod: paymentMethodSchema,
	contractDueDate: contractDueDateSchema,
	valeurFranchise: deductibleSchema,
	fileFees: fileFeesSchema,
	commissionCourtier: brokerCommissionSchema,
	civiliteDirigeant: civilityLeaderSchema,
	nomDirigeant: lastNameLeaderSchema,
	prenomDirigeant: firstNameLeaderSchema,
	emailDirigeant: emailSchema,
	dateNaissanceDirigeant: birthdayLeaderSchema,
	telPortableDirigeant: phoneNumberSchema,
	telFixeDirigeant: landlinePhoneNumberSchema.optional(),
	pricingData: PricingResponseSchema,
});
export type QuoteDetailsDto = z.infer<typeof quoteSchema>;
