import { styles } from "@/components/quotePdf/styles";
import { addSpacingBetweenThousandsToNumber } from "@/utils";
import { Text, View } from "@react-pdf/renderer";

import type { SubscriptionDetailsForQuoteDto } from "@repos/mrp-dtos";

const RiskDetail = ({ ...props }: SubscriptionDetailsForQuoteDto) => {
	return (
		<View style={{ display: "flex", flexDirection: "column", gap: 10 }}>
			{props.insuredEstablishments.map((establishment, index) => (
				<View key={index}>
					<Text style={[styles.bodyText, styles.max1Line, styles.mt_10]}>
						<Text style={[styles.bodyText, styles.bold]}>
							{index === 0 ? "Risque Principal :" : `Local n°${index + 1} :`}
						</Text>
					</Text>
					<Text style={[styles.bodyText, styles.max1Line]}>
						<Text style={[styles.bodyText, styles.bold]}>
							• Adresse du risque :
						</Text>{" "}
						{establishment.address.fullAddress} -{" "}
						{establishment.address.postalCode} {establishment.address.city}
					</Text>
					<Text style={[styles.bodyText, styles.max1Line]}>
						<Text style={[styles.bodyText, styles.bold]}>• Qualité :</Text> Vous
						êtes {props.companyInfo.legalRepresentativeTitle} des locaux
						professionnels.
					</Text>
					<Text style={[styles.bodyText, styles.max1Line]}>
						<Text style={[styles.bodyText, styles.bold]}>
							• Surface des locaux :
						</Text>{" "}
						{addSpacingBetweenThousandsToNumber(establishment.officesArea)} m²
					</Text>
					<Text style={[styles.bodyText, { paddingLeft: 15 }]}>
						(Total pour chaque bâtiment de l’ensemble des superficies de
						plancher – épaisseur des murs comprise – des sous-sols, du
						rez-de-chaussée et de chacun des autres niveaux, ou à défaut la
						surface figurant au bail) mètres carrés.
					</Text>
					<Text style={[styles.bodyText, styles.max1Line]}>
						<Text style={[styles.bodyText, styles.bold]}>
							• Valeur du mètre carré :
						</Text>{" "}
						{addSpacingBetweenThousandsToNumber(establishment.valuePerM2)} €/m²
					</Text>
					<Text style={[styles.bodyText, styles.max1Line]}>
						<Text style={[styles.bodyText, styles.bold]}>
							• Limite contractuelle d’indemnité sur le bâtiment :
						</Text>{" "}
						{addSpacingBetweenThousandsToNumber(
							establishment.officesArea * establishment.valuePerM2,
						)}{" "}
						€
					</Text>
					<Text style={[styles.bodyText, styles.max1Line]}>
						<Text style={[styles.bodyText, styles.bold]}>
							• Contenu professionnel à assurer (valeur des biens à assurer) :
						</Text>{" "}
						{addSpacingBetweenThousandsToNumber(
							establishment.professionalContent,
						)}{" "}
						€
					</Text>
					<Text style={[styles.bodyText, styles.max1Line]}>
						<Text style={[styles.bodyText, styles.bold]}>
							• Contenu du stock à assurer :
						</Text>{" "}
						{addSpacingBetweenThousandsToNumber(
							establishment.insuredStockContent,
						)}{" "}
						€
					</Text>
					<Text style={[styles.bodyText, styles.max1Line]}>
						<Text style={[styles.bodyText, styles.bold]}>
							• Capital total du contenu à protéger :
						</Text>{" "}
						{addSpacingBetweenThousandsToNumber(
							establishment.professionalContent +
								establishment.insuredStockContent,
						)}{" "}
						€
					</Text>
					<Text style={[styles.bodyText, styles.max1Line]}>
						<Text style={[styles.bodyText, styles.bold]}>
							• Capital total à protéger :
						</Text>{" "}
						{addSpacingBetweenThousandsToNumber(
							establishment.officesArea * establishment.valuePerM2 +
								establishment.professionalContent +
								establishment.insuredStockContent,
						)}{" "}
						€
					</Text>
				</View>
			))}
		</View>
	);
};

export default RiskDetail;
