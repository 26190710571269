import { FormStepWrapper } from "@/components/FormStepWrapper.tsx";
import Layout from "@/layouts/Layout.tsx";
import { PrivateRoute } from "@/lib/PrivateRoute.tsx";
import { SirenInputPage } from "@/pages/form/1/pages/1/SirenInputPage.tsx";
import { NumberOfClaimsPage } from "@/pages/form/1/pages/2/NumberOfClaimsPage.tsx";
import { ActivityPage } from "@/pages/form/1/pages/3/ActivityPage";
import { EstablishmentSelectPage } from "@/pages/form/1/pages/4/EstablishmentSelectPage.tsx";
import { EstablishmentsDetailsPage } from "@/pages/form/1/pages/5/EstablishmentsDetailsPage";
import { ClaimsPage } from "@/pages/form/1/pages/6/ClaimsPage";
import { DromComDeclarationsPage } from "@/pages/form/1/pages/7/DromComDeclarationsPage";
import { OldDeclarationsPage } from "@/pages/form/2/pages/1/OldDeclarationsPage";
import { DeclarationsPage } from "@/pages/form/2/pages/2/DeclarationsPage";
import { BasicGuarantees } from "@/pages/form/3/pages/1/BasicGuarantees";
import { OptionalGuarantees } from "@/pages/form/3/pages/2/OptionalGuarantees";
import { GuaranteeAmountsPage } from "@/pages/form/4/pages/1/GuaranteeAmountsPage";
import { QuotesPage } from "@/pages/form/5/pages/1/QuotesPage";
import { OfferSummaryPage } from "@/pages/form/5/pages/2/OfferSummaryPage";
import { SendingQuotesEndPage } from "@/pages/form/5/pages/3/SendingQuotesEndPage";
import { SendingQuotesPage } from "@/pages/form/5/pages/4/SendingQuotesPage";

export enum FormRoutes {
	SirenInputPage = "SirenInputPage",
	NumberOfSinistresPage = "NumberOfSinistresPage",
	ActivityPage = "ActivityPage",
	EstablishmentsSelectPage = "EstablishmentsSelectPage",
	EtablissementsDetailsPage = "EtablissementsDetailsPage",
	ClaimsPage = "ClaimsPage",
	DromComDeclarationsPage = "DromComDeclarationsPage",
	OldDeclarationsPage = "OldDeclarationsPage",
	BasicGuarantees = "BasicGuarantees",
	OptionalGuarantees = "OptionalGuarantees",
	DeclarationsPage = "DeclarationsPage",
	GuaranteeAmountsPage = "GuaranteeAmountsPage",
	SendingQuotesPage = "SendingQuotesPage",
	SendingQuotesEndPage = "SendingQuotesEndPage",
	OfferSummaryPage = "OfferSummaryPage",
	QuotesPage = "QuotesPage",
}

export const getRoute = (step: number, substep: number) => {
	return `/formulaire/${
		Object.values(formRoutes).find(
			(route) => route.step === step && route.subStep === substep,
		)?.path || ""
	}`;
};

export const formRoutes = {
	[FormRoutes.SirenInputPage]: {
		path: "informations-entreprise/siren",
		element: (
			<PrivateRoute>
				<FormStepWrapper stepKey={FormRoutes.SirenInputPage}>
					<SirenInputPage />
				</FormStepWrapper>
			</PrivateRoute>
		),
		step: 1,
		subStep: 1,
		title: "Informations de l'entreprise",
		description:
			"Veuillez saisir la raison sociale, l’activité et les établissements de l’entreprise",
	},
	[FormRoutes.NumberOfSinistresPage]: {
		path: "informations-entreprise/nombre-sinistres",
		element: (
			<PrivateRoute>
				<FormStepWrapper stepKey={FormRoutes.NumberOfSinistresPage}>
					<NumberOfClaimsPage />
				</FormStepWrapper>
			</PrivateRoute>
		),
		step: 1,
		subStep: 2,
		title: "Informations de l'entreprise",
		description:
			"Veuillez saisir la raison sociale, l’activité et les établissements de l’entreprise",
	},
	[FormRoutes.ActivityPage]: {
		path: "informations-entreprise/page-activite",
		element: (
			<PrivateRoute>
				<FormStepWrapper stepKey={FormRoutes.ActivityPage}>
					<ActivityPage />
				</FormStepWrapper>
			</PrivateRoute>
		),
		step: 1,
		subStep: 3,
		title: "Informations de l'entreprise",
		description:
			"Veuillez saisir la raison sociale, l’activité et les établissements de l’entreprise",
	},

	[FormRoutes.EstablishmentsSelectPage]: {
		path: "informations-entreprise/selection-etablissements",
		element: (
			<PrivateRoute>
				<FormStepWrapper stepKey={FormRoutes.EstablishmentsSelectPage}>
					<EstablishmentSelectPage />
				</FormStepWrapper>
			</PrivateRoute>
		),
		step: 1,
		subStep: 4,
		title: "Informations de l' entreprise",
		description:
			"Veuillez saisir la raison sociale, l’activité et les établissements de l’entreprise",
	},
	[FormRoutes.EtablissementsDetailsPage]: {
		path: "informations-entreprise/details-etablissements",
		element: (
			<PrivateRoute>
				<FormStepWrapper stepKey={FormRoutes.EtablissementsDetailsPage}>
					<EstablishmentsDetailsPage />
				</FormStepWrapper>
			</PrivateRoute>
		),
		step: 1,
		subStep: 5,
		title: "Informations de l' entreprise",
		description:
			"Veuillez saisir la raison sociale, l’activité et les établissements de l’entreprise",
	},
	[FormRoutes.ClaimsPage]: {
		path: "informations-entreprise/page-sinistres",
		element: (
			<PrivateRoute>
				<FormStepWrapper stepKey={FormRoutes.ClaimsPage}>
					<ClaimsPage />
				</FormStepWrapper>
			</PrivateRoute>
		),
		step: 1,
		subStep: 6,
		title: "Informations de l'entreprise",
		description:
			"Veuillez saisir la raison sociale, l’activité et les établissements de l’entreprise",
	},
	[FormRoutes.DromComDeclarationsPage]: {
		path: "informations-entreprise/page-dromcom",
		element: (
			<PrivateRoute>
				<FormStepWrapper stepKey={FormRoutes.DromComDeclarationsPage}>
					<DromComDeclarationsPage />
				</FormStepWrapper>
			</PrivateRoute>
		),
		step: 1,
		subStep: 7,
		title: "Informations de l'entreprise",
		description:
			"Veuillez saisir la raison sociale, l’activité et les établissements de l’entreprise",
	},
	[FormRoutes.OldDeclarationsPage]: {
		path: "declarations/page-anciennes-declarations",
		element: (
			<PrivateRoute>
				<FormStepWrapper stepKey={FormRoutes.OldDeclarationsPage}>
					<OldDeclarationsPage />
				</FormStepWrapper>
			</PrivateRoute>
		),
		step: 2,
		subStep: 1,
		title: "Déclarations",
		description:
			"Veuillez répondre aux questions suivantes par oui ou non. Vos réponses sont cruciales pour avancer",
	},
	[FormRoutes.DeclarationsPage]: {
		path: "declarations/page-declarations",
		element: (
			<PrivateRoute>
				<FormStepWrapper stepKey={FormRoutes.DeclarationsPage}>
					<DeclarationsPage />
				</FormStepWrapper>
			</PrivateRoute>
		),
		step: 2,
		subStep: 2,
		title: "Déclarations",
		description:
			"Veuillez répondre aux questions suivantes par oui ou non.Vos réponses sont cruciales pour avancer",
	},
	[FormRoutes.BasicGuarantees]: {
		path: "garanties/page-garanties-base",
		element: (
			<PrivateRoute>
				<FormStepWrapper stepKey={FormRoutes.BasicGuarantees}>
					<BasicGuarantees />
				</FormStepWrapper>
			</PrivateRoute>
		),
		step: 3,
		subStep: 1,
		title: "Garanties",
		description:
			"Veuillez sélectionner les garanties optionnelles pour votre simulation. Cela nous aidera à affiner vos résultats",
	},
	[FormRoutes.OptionalGuarantees]: {
		path: "garanties/page-garanties-optionnel",
		element: (
			<PrivateRoute>
				<FormStepWrapper stepKey={FormRoutes.OptionalGuarantees}>
					<OptionalGuarantees />
				</FormStepWrapper>
			</PrivateRoute>
		),
		step: 3,
		subStep: 2,
		title: "Garanties",
		description:
			"Veuillez sélectionner les garanties optionnelles pour votre simulation. Cela nous aidera à affiner vos résultats",
	},
	[FormRoutes.GuaranteeAmountsPage]: {
		path: "garanties/montant-des-garanties",
		element: (
			<PrivateRoute>
				<FormStepWrapper stepKey={FormRoutes.GuaranteeAmountsPage}>
					<GuaranteeAmountsPage />
				</FormStepWrapper>
			</PrivateRoute>
		),
		step: 4,
		subStep: 1,
		title: "Montants des garanties",
		description:
			"Veuillez saisir les montants des garanties pour avoir un préavis sur la Prime",
	},
	[FormRoutes.QuotesPage]: {
		path: "devis/devis",
		element: (
			<PrivateRoute>
				<FormStepWrapper stepKey={FormRoutes.QuotesPage}>
					<QuotesPage />
				</FormStepWrapper>
			</PrivateRoute>
		),
		step: 5,
		subStep: 1,
		title: "Devis",
		description:
			"Veuillez saisir les informations du dirigeant et la date d'effet du contrat pour obtenir un devis",
	},
	[FormRoutes.OfferSummaryPage]: {
		path: "devis/resume-offre",
		element: (
			<PrivateRoute>
				<FormStepWrapper stepKey={FormRoutes.OfferSummaryPage}>
					<OfferSummaryPage />
				</FormStepWrapper>
			</PrivateRoute>
		),
		step: 5,
		subStep: 2,
		title: "Devis",
		description:
			"Veuillez saisir les informations du dirigeant et la date d'effet du contrat pour obtenir un devis",
	},
	[FormRoutes.SendingQuotesPage]: {
		path: "devis/envoi-devis",
		element: (
			<PrivateRoute>
				<FormStepWrapper stepKey={FormRoutes.SendingQuotesPage}>
					<SendingQuotesPage />
				</FormStepWrapper>
			</PrivateRoute>
		),
		step: 5,
		subStep: 3,
		title: "Devis",
		description:
			"Veuillez saisir les informations du dirigeant et la date d'effet du contrat pour obtenir un devis",
	},
	[FormRoutes.SendingQuotesEndPage]: {
		path: "devis/envoi-devis-finale",
		element: (
			<PrivateRoute>
				<FormStepWrapper stepKey={FormRoutes.SendingQuotesEndPage}>
					<SendingQuotesEndPage />
				</FormStepWrapper>
			</PrivateRoute>
		),
		step: 5,
		subStep: 4,
		title: "Devis",
		description:
			"Veuillez saisir les informations du dirigeant et la date d'effet du contrat pour obtenir un devis",
	},
};

export const privateRoutes = [
	{
		path: "/formulaire",
		element: <Layout />,
		children: Object.values(formRoutes),
	},
];
