import type { SirenPageDto } from "@/pages/form/schemas";
import { useState } from "react";
import {
	Command,
	CommandEmpty,
	CommandGroup,
	CommandItem,
	CommandList,
} from "@/components/ui/command.tsx";
import { Dialog, DialogTrigger } from "@/components/ui/dialog.tsx";
import { Input } from "@/components/ui/input.tsx";
import {
	Popover,
	PopoverAnchor,
	PopoverContent,
} from "@/components/ui/popover.tsx";
import { Spinner } from "@/components/ui/Spinner.tsx";
import { ManualEntrepriseInputModalContent } from "@/pages/form/1/components/1/ManualEntrepriseInputModalContent.tsx";
import { cn } from "@/utils";
import { Command as CommandPrimitive } from "cmdk";
import { Check } from "lucide-react";
import { useFormContext } from "react-hook-form";

type Props<
	T extends {
		siren: string;
		completeName: string;
		headquarters: {
			address: string;
		};
	},
> = {
	onSelectedValueChange: (value: T | undefined) => void;
	searchValue: string;
	onSearchValueChange: (value: string) => void;
	items: T[];
	isLoading?: boolean;
	emptyMessage?: string;
	placeholder?: string;
};

export function EntrepriseAutoComplete<
	T extends {
		siren: string;
		completeName: string;
		headquarters: {
			address: string;
		};
	},
>({
	onSelectedValueChange,
	searchValue,
	onSearchValueChange,
	items,
	isLoading,
	emptyMessage = "Aucun résultat",
	placeholder = "Recherchez l’entreprise par raison sociale / numéro SIREN",
}: Props<T>) {
	const [open, setOpen] = useState(false);
	const [selectedValueSiren, setSelectedValueSiren] = useState<
		string | undefined
	>(undefined);

	const reset = () => {
		onSelectedValueChange(undefined);
		setSelectedValueSiren(undefined);
		onSearchValueChange("");
	};

	const onSelectItem = (sirenValue: string) => {
		if (sirenValue === selectedValueSiren) {
			reset();
		} else {
			const selectedItem = items.find((item) => item.siren === sirenValue);
			if (!selectedItem) {
				reset();
			} else {
				setSelectedValueSiren(sirenValue);
				onSelectedValueChange(
					items.find((item) => item.siren === sirenValue)! ?? items[0],
				);
			}
		}
		setOpen(false);
	};
	const { setValue } = useFormContext<SirenPageDto>();
	return (
		<div className="flex items-center">
			<Popover open={open} onOpenChange={setOpen}>
				<Command shouldFilter={false}>
					<PopoverAnchor asChild>
						<CommandPrimitive.Input
							asChild
							value={searchValue}
							onValueChange={onSearchValueChange}
							onKeyDown={(e) => setOpen(e.key !== "Escape")}
							onMouseDown={() => setOpen((open) => !!searchValue || !open)}
							onFocus={() => setOpen(true)}
							className={cn(
								"w-full justify-between overflow-hidden overflow-ellipsis whitespace-nowrap rounded-md border-gray-300 bg-white p-5 font-normal text-black",
								!searchValue && "text-muted-foreground",
							)}
						>
							<Input placeholder={placeholder} />
						</CommandPrimitive.Input>
					</PopoverAnchor>
					{!open && <CommandList aria-hidden="true" className="hidden" />}
					<PopoverContent
						asChild
						onOpenAutoFocus={(e) => e.preventDefault()}
						onInteractOutside={(e) => {
							if (
								e.target instanceof Element &&
								e.target.hasAttribute("cmdk-input")
							) {
								e.preventDefault();
							}
						}}
						className="w-[--radix-popover-trigger-width] p-0"
					>
						<CommandList className="relative">
							{isLoading && (
								<CommandPrimitive.Loading>
									<Spinner />
								</CommandPrimitive.Loading>
							)}
							<CommandGroup>
								{!isLoading &&
									items.map((option) => (
										<CommandItem
											key={option.siren}
											value={option.siren}
											onMouseDown={(e) => e.preventDefault()}
											onSelect={onSelectItem}
											className="rounded-none border-b border-b-gray-300 outline-none"
										>
											<Check
												className={cn(
													"mr-2 h-4 w-4",
													selectedValueSiren === option.siren
														? "opacity-100"
														: "opacity-0",
												)}
											/>
											<div className="flex flex-col">
												<span className="font-medium text-[#333333]">
													{option.completeName} | {option.siren}
												</span>
												<span className="text-sm text-[#808080]">
													{option.headquarters.address}
												</span>
											</div>
										</CommandItem>
									))}
							</CommandGroup>
							{!isLoading ? (
								<CommandEmpty>{emptyMessage ?? "Aucun résultat"}</CommandEmpty>
							) : null}
							<CommandGroup>
								{/*The following items are not selectable, they are just for extra padding so that if the list has only 1 item
								the "i cant find my company" button does not overlap the item.
								*/}
								{!isLoading && items.length === 1 && (
									<>
										<CommandItem />
										<CommandItem />
										<CommandItem />
									</>
								)}
								<Dialog
									onOpenChange={(v) => {
										setValue("companyUnderCreation", v);
									}}
								>
									<CommandItem
										className={cn(
											"border-t border-t-gray-300 p-4 font-medium text-[#598EAD] data-[selected=true]:bg-white data-[selected=true]:bg-opacity-100 data-[selected=true]:text-[#598EAD]",
											items.length > 0 &&
												!isLoading &&
												"fixed bottom-0 left-0 right-0 z-[80] border bg-white underline hover:bg-white",
										)}
									>
										<DialogTrigger asChild>
											<span className="w-full">
												Je ne trouve pas l’entreprise : saisie manuelle
											</span>
										</DialogTrigger>
									</CommandItem>

									<ManualEntrepriseInputModalContent
										sirenRequired={true}
										headerTitle="Ajouter les Informations de l’entreprise"
									/>
								</Dialog>
							</CommandGroup>
						</CommandList>
					</PopoverContent>
				</Command>
			</Popover>
		</div>
	);
}
