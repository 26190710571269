import { z } from "zod";

import {
	sectorIdSchema,
	sectorLabelSchema,
	sectorUrlSchema,
} from "../primitives";

export const sectorInfoSchema = z.object({
	id: sectorIdSchema,
	label: sectorLabelSchema,
	iconUrl: sectorUrlSchema,
	selectedIconUrl: sectorUrlSchema,
});

export type SectorInfoDto = z.infer<typeof sectorInfoSchema>;

export const getSectorsResponseSchema = z.array(sectorInfoSchema);

export type GetSectorsResponseDto = z.infer<typeof getSectorsResponseSchema>;

export const getSectorByIdSchema = z.object({
	id: z.string(),
});
