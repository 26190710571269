import {
	LIABILITY_TYPES,
	LOSES_TYPES,
	PROPERTY_DAMAGE_AMOUNT_TYPES,
	PROPERTY_DAMAGE_TYPES,
} from "@/constants";

interface GuaranteeOption {
	guaranteeType: string;
	guaranteeValue: number;
}

export const categorizeGuarantees = (guarantees: GuaranteeOption[]) => {
	const propertyDamage: GuaranteeOption[] = guarantees.filter((item) =>
		PROPERTY_DAMAGE_TYPES.includes(item.guaranteeType),
	);
	const propertyDamageAmount: GuaranteeOption[] = guarantees.filter((item) =>
		PROPERTY_DAMAGE_AMOUNT_TYPES.includes(item.guaranteeType),
	);
	const liability: GuaranteeOption[] = guarantees.filter((item) =>
		LIABILITY_TYPES.includes(item.guaranteeType),
	);
	const loses: GuaranteeOption[] = guarantees.filter((item) =>
		LOSES_TYPES.includes(item.guaranteeType),
	);
	return { propertyDamage, liability, loses, propertyDamageAmount };
};
