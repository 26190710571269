"use client";

import { useEffect } from "react";
import { Button } from "@/components/ui/button";
import { useResetStores } from "@/stores/useResetStores";
import { captureException } from "@sentry/react";
import { AlertCircle } from "lucide-react";

export default function FallbackErrorPage({
	error,
}: {
	error: Error & { digest?: string };
}) {
	const resetAllStores = useResetStores();

	useEffect(() => {
		// eslint-disable-next-line turbo/no-undeclared-env-vars
		if (import.meta.env.MODE !== "development") {
			console.error(error);
		}
		// Capture the error with Sentry
		captureException(error);
	}, [error]);

	return (
		<div className="flex min-h-screen flex-col items-center justify-center bg-background text-foreground">
			<div className="max-w-md text-center">
				<AlertCircle className="mx-auto h-16 w-16 text-destructive" />
				<h1 className="mt-6 text-3xl font-bold">
					Une erreur s&apos;est produite !
				</h1>
				<p className="mt-4 text-muted-foreground">
					Nous nous excusons pour le désagrément. Une erreur inattendue
					s&apos;est produite.
				</p>
				<div className="mt-8 flex justify-center">
					<Button
						onClick={() => {
							resetAllStores();
							window.location.replace(
								"/formulaire/informations-entreprise/siren?encrypt=" +
									(window.location.search.split("encrypt=")[1] || ""),
							);
						}}
					>
						Réessayer
					</Button>
				</div>
			</div>
		</div>
	);
}
