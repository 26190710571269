import { z } from "zod";

import { emailSchema, firstNameSchema, lastNameSchema } from "../primitives";

export const QuoteEmailInputSchema = z.object({
	email: emailSchema,
	subscriptionId: z.string(),
	firstName: firstNameSchema,
	lastName: lastNameSchema,
});

export type QuoteEmailInputDto = z.infer<typeof QuoteEmailInputSchema>;
