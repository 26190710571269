import * as z from "zod";

export const CreateUserSchema = z.object({
	email: z.string().email(),
	name: z.string().optional(),
	image: z.string().optional(),
	emailVerified: z.date().optional(),
});

export const GetUserByIdSchema = z.object({
	id: z.string().min(1),
});

export type GetUserByIdDto = z.infer<typeof GetUserByIdSchema>;
export type CreateUserDto = z.infer<typeof CreateUserSchema>;
