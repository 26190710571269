import ActivityDetail from "@/components/quotePdf/ActivityDetail.tsx";
import EstablishmentInfo from "@/components/quotePdf/EstablishmentInfo.tsx";
import { HeaderFooter } from "@/components/quotePdf/HeaderFooter.tsx";
import { styles } from "@/components/quotePdf/styles.ts";
import { addSpacingBetweenThousandsToNumber } from "@/utils";
import { Page, Text, View } from "@react-pdf/renderer";

import type {
	ActivitiesDto,
	SubscriptionDetailsForQuoteDto,
} from "@repos/mrp-dtos";

export const InsuredEstablishmentsAndActivitiesPdfPage = ({
	...props
}: SubscriptionDetailsForQuoteDto) => {
	//flat map all activities and remove duplicates
	const flatActivities: ActivitiesDto = props.insuredEstablishments.flatMap(
		(establishment) => establishment.activities,
	);
	const activities = flatActivities.filter(
		(activity, index, self) =>
			index === self.findIndex((t) => t.id === activity.id),
	);

	const numberOfEmployeesInCompany = props.insuredEstablishments.reduce(
		(acc, establishment) =>
			acc + establishment.numberOfEmployeesInEstablishment,
		0,
	);
	return (
		<Page size="A4" style={styles.page}>
			<HeaderFooter />
			<View style={styles.container}>
				<Text style={styles.sectionHeader}>ETABLISSEMENT(S) ASSURÉ(S)</Text>
				{props.insuredEstablishments.map((establishment, index) => (
					<View key={index}>
						<Text
							style={[
								styles.bodyText,
								styles.medium,
								{ textDecoration: "underline" },
							]}
						>
							Etablissement n°{index + 1} :
						</Text>
						<EstablishmentInfo {...establishment} />
					</View>
				))}
				<Text style={[styles.sectionHeader, styles.mt_10]} break>
					ACTIVITÉ(S) À ASSURER
				</Text>
				<Text style={[styles.bodyText, styles.bold]}>
					• Les conditions particulières concerne l’assurance d’une ou des
					activité (s) :
				</Text>
				<ActivityDetail activities={activities} />
				<Text style={[styles.bodyText, styles.max1Line, styles.mt_20]}>
					<Text style={[styles.bodyText, styles.bold]}>
						• Chiffre d’affaires H.T de la dernière année fiscale :
					</Text>{" "}
					{addSpacingBetweenThousandsToNumber(props.totalTurnoverWithoutTaxes)}{" "}
					€.
				</Text>
				<Text style={[styles.bodyText, styles.max1Line]}>
					<Text style={[styles.bodyText, styles.bold]}>
						• Effectif de la société :
					</Text>{" "}
					{addSpacingBetweenThousandsToNumber(numberOfEmployeesInCompany)}{" "}
					personnes.
				</Text>
			</View>
		</Page>
	);
};
