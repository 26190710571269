import { z } from "zod";

import {
	companyCreationDateSchema,
	companyNameSchema,
	detailedAddressSchema,
	nafCodeSchema,
	postalCodeSchema,
	sectorIdSchema,
	sectorLabelSchema,
	sirenSchema,
	siretSchema,
	transformedLegalStatusSchema,
} from "../primitives";

export const matchingEtablissementSchema = z.object({
	siret: siretSchema,
	siren: sirenSchema,
	isHeadquarters: z.boolean(),
	detailedAddress: detailedAddressSchema,

	nic: z.string().nullish(),
	mainActivity: z.string().nullish(),
	address: z.string().nullish(),
	creationDate: z.coerce.date().nullish(),
	startActivityDate: z.coerce.date().nullish(),
	closureDate: z.coerce.date().nullish(),
	commercialName: z.string().nullish(),
	diffusionStatus: z.string().nullish(),
});
export type MatchingEtablissementDto = z.infer<
	typeof matchingEtablissementSchema
>;

export const CompanyInfoSchema = z.object({
	siren: sirenSchema,
	completeName: companyNameSchema,
	legalName: z.string().nullable(),
	establichmentsCount: z.number().optional(),
	openEstablishmentsCount: z.number().optional(),
	headquarters: z.object({
		address: z.string(),
		detailedAddress: detailedAddressSchema.default({
			streetLabel: "",
			postalCode: "",
			city: "",
			country: "",
		}),
		postalCode: postalCodeSchema.nullish(),
		commune: z.string().nullish(),
		siret: z.string(),
	}),
	mainActivity: z
		.object({
			code: nafCodeSchema,
			label: z.string(),
		})
		.default({
			code: "",
			label: "",
		}),
	companyCategory: z.string().nullable(),
	creationDate: companyCreationDateSchema,
	administrativeStatus: z.string(),
	legalStatus: transformedLegalStatusSchema.default({
		code: "",
		label: "",
	}),
	mainActivitySection: z.string(),
	diffusionStatus: z.string(),
	sectorId: sectorIdSchema.optional(),
	sectorLabel: sectorLabelSchema.optional(),
});

export type CompanyInfoDto = z.infer<typeof CompanyInfoSchema>;

export const matchingEstablishmentArraySchema = z.array(
	matchingEtablissementSchema,
);
export const CompaniesInfoSchema = z.array(CompanyInfoSchema);

export type CompaniesInfoDto = z.infer<typeof CompaniesInfoSchema>;

export const GetCompanyInputSchema = z.object({
	siren: sirenSchema,
});

export const GetCompaniesInputSchema = z.object({
	name: z.string(),
});
export const GetMatchingEstablishmentsBySirenOrSiretInputSchema = z.object({
	sirenOrSiret: z.string(),
});
// export const EstablishmentSelectInputSchema = z.object({
// 	siret: siretSchema,
// 	streetLabel: streetLabelSchema,
// 	postalCode: postalCodeSchema,
// 	city: citySchema,
// 	country: countrySchema,
// });
// export const baseEstablishmentSchema = EstablishmentSelectInputSchema.extend({
// 	order: z.number(),
// 	name: establishmentNameSchema,
// 	occupationStatus: establishmentOccupationStatusSchema,
// 	numberOfEmployees: numberOfEmployeesSchema,
// 	turnover: establishmentTurnoverSchema,
// 	surfaceArea: surfaceAreaSchema,
// 	status: z.string(),
// });
// export const CompanyInfoAndActivitiesSchema = z.object({
// 	siren: sirenSchema,
// 	nafCode: nafCodeSchema,
// 	creationDate: companyCreationDateSchema,
// 	sectorId: sectorIdSchema,
// 	principalActivity: activityInputSchema,
// 	secondaryActivities: z.array(activityInputSchema),
// 	liquidationOrRecovery: liquidationOrRecoverySchema,
// 	establishments: z.array(baseEstablishmentSchema),
// });
// export type CompanyInfoAndActivitiesDto = z.infer<
// 	typeof CompanyInfoAndActivitiesSchema
// >;

export const CompanyInputSchema = z.object({
	siren: sirenSchema,
	nafCode: nafCodeSchema,
	creationDate: companyCreationDateSchema,
	companyName: z.string(),
	businessTypeCode: z.string(),
	businessTypeLabel: z.string(),
	principalActivityId: z.string(),
	address: detailedAddressSchema,
});
export type CompanyInputDto = z.infer<typeof CompanyInputSchema>;
