import ErrorIllustration from "@/assets/ErrorIllustration.png";
import EmailIcon from "@/assets/MailIcon.svg";
import {
	AlertDialog,
	AlertDialogAction,
	AlertDialogCancel,
	AlertDialogContent,
	AlertDialogDescription,
	AlertDialogFooter,
	AlertDialogHeader,
} from "@/components/ui/alert-dialog.tsx";

interface ContactModelProps {
	isOpen: boolean;
	onClose: () => void;
	description: string;
	onConfirm?: () => void;
}

export const ContactModal = ({
	isOpen,
	onClose,
	description,
	onConfirm,
}: ContactModelProps) => {
	return (
		<AlertDialog open={isOpen} onOpenChange={(value) => !value && onClose()}>
			<AlertDialogContent className="max-w-2xl">
				<AlertDialogHeader className="items-center">
					<img className="w-64" src={ErrorIllustration} />
					<AlertDialogDescription className="flex items-center gap-4 text-2xl font-semibold text-primary">
						Nous sommes désolés
					</AlertDialogDescription>
				</AlertDialogHeader>
				<p className="text-center font-bold text-[#333333]">{description}</p>
				{/* <PhoneInput
					label="Téléphone"
					name="phone"
					required
					placeholder="Veuillez entrer le numéro de contact"
				/> */}
				<div className="flex flex-row items-center justify-center gap-1">
					<img src={EmailIcon} />
					<a
						href="mailto:contact@as-solutions.fr"
						className="text-black underline"
					>
						contact@as-solutions.fr
					</a>
				</div>
				<AlertDialogFooter className="self-start sm:justify-between">
					<AlertDialogCancel className="px-8">Annuler</AlertDialogCancel>
					<AlertDialogAction onClick={onConfirm} className="px-8">
						Envoyer
					</AlertDialogAction>
				</AlertDialogFooter>
			</AlertDialogContent>
		</AlertDialog>
	);
};
