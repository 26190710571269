import Declarations from "@/components/quotePdf/Declarations.tsx";
import { HeaderFooter } from "@/components/quotePdf/HeaderFooter.tsx";
import { styles } from "@/components/quotePdf/styles.ts";
import { Page, View } from "@react-pdf/renderer";

import type { SubscriptionDetailsForQuoteDto } from "@repos/mrp-dtos";

export const DeclarationsPdfPage = ({
	...props
}: SubscriptionDetailsForQuoteDto) => {
	return (
		<Page size="A4" style={styles.page}>
			<HeaderFooter />
			<View style={styles.container}>
				<Declarations {...props} />
			</View>
		</Page>
	);
};
