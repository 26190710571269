import { useCallback } from "react";
import { formStore, initialState } from "@/pages/form/stores/formStore";

export const useClearForm = () => {
	const clearForm = useCallback(() => {
		formStore.setState(initialState);

		if (typeof window !== "undefined") {
			localStorage.removeItem("form-store");
		}
	}, []);

	const clearSpecificSection = useCallback(
		(section: keyof typeof initialState) => {
			formStore.setState((state) => ({
				...state,
				[section]: initialState[section],
			}));
		},
		[],
	);

	return {
		clearForm,
		clearSpecificSection,
	};
};
