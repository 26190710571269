import type { Style } from "@react-pdf/types";
import type { FC, ReactNode } from "react";
import { StyleSheet, Text, View } from "@react-pdf/renderer"; // Define the types for columns and data

// Define the types for columns and data
interface ColumnConfig {
	header: string | ReactNode;
	width: number;
	grow: number;
	style?: Style;
}

export type CellContent = ReactNode | string;

export type RowContent =
	| {
			type: "row";
			data: CellContent[];
			style?: Style;
	  }
	| {
			type: "section";
			data: CellContent;
			style?: Style;
	  }
	| null;
export type TableData = RowContent[];

export interface ThreeColumnTableProps {
	columns: ColumnConfig[];
	data: TableData;
}

// Define styles for the table and its cells
const styles = StyleSheet.create({
	table: {
		display: "flex",
		flexDirection: "column",
		width: "100%",
		borderWidth: 1,
		borderColor: "#000",
	},
	row: {
		display: "flex",
		flexDirection: "row",
		width: "100%",
	},
	cell: {
		padding: 5,
		borderRightWidth: 1,
		borderBottomWidth: 1,
		borderColor: "#000",
		fontSize: 10,
	},
	sectionCell: {
		padding: 5,
		borderBottomWidth: 1,
		borderColor: "#000",
		fontSize: 10,
		textAlign: "center",
	},
});

// ThreeColumnTable Component
const ThreeColumnTable: FC<ThreeColumnTableProps> = ({ columns, data }) => {
	return (
		<View style={styles.table}>
			{/* Render header */}
			<View style={styles.row}>
				{columns.map((col, index) => (
					<View
						key={index}
						style={[
							styles.cell,
							{ flexGrow: col.grow, width: `${col.width}%` },
							col.style || {},
						]}
					>
						{typeof col.header === "string" ? (
							<Text>{col.header}</Text>
						) : (
							col.header
						)}
					</View>
				))}
			</View>
			{/* Render rows and sections */}
			{data.map((rowContent, rowIndex) => {
				if (!rowContent) {
					return null;
				}
				// Render a section that spans all columns
				if (rowContent.type === "section") {
					return (
						<View key={rowIndex} style={[styles.row, rowContent.style || {}]}>
							<View
								style={[styles.sectionCell, { flexGrow: 1, width: "100%" }]}
							>
								{typeof rowContent.data === "string" ? (
									<Text>{rowContent.data}</Text>
								) : (
									rowContent.data
								)}
							</View>
						</View>
					);
				}
				// Render a regular row with cells
				if (rowContent.type === "row") {
					const row = rowContent.data.slice(0, columns.length);
					// if (row.length !== columns.length) {
					// 	console.warn(
					// 		`Row ${rowIndex + 1} has a length of ${row.length}, which does not match the number of columns (${columns.length}). This row will be skipped.`,
					// 	);
					// 	// return null; // Skip rows with mismatched lengths
					// }
					return (
						<View key={rowIndex} style={styles.row}>
							{row.map((cellContent, cellIndex) => (
								<View
									key={cellIndex}
									style={[
										styles.cell,
										{
											flexGrow:
												columns.length > cellIndex
													? columns[cellIndex]!.grow
													: 0,
											width:
												columns.length > cellIndex
													? `${columns[cellIndex]!.width}%`
													: "0",
										},
									]}
								>
									{typeof cellContent === "string" ? (
										<Text>{cellContent}</Text>
									) : (
										cellContent
									)}
								</View>
							))}
						</View>
					);
				}
				return null;
			})}
		</View>
	);
};

export default ThreeColumnTable;
